// TODO: create a joi valiadtion for branding keys later.
import PropTypes from 'prop-types';
import { get } from 'lodash';
import DebouncedTextInput from './Common/DebouncedTextInput';
import DropDown from './Common/DropDown';
import ToggleWithCondition from './Common/ToggleWithCondition';
import SetComponentValidations from './Common/SetValidation';
import SetDropDownOptions from './Common/SetDropDownOptions';
import NumberInput from './Common/NumberInput';
import DropDownWithCustomInput from './Common/DropDownWithCustomInput';
import UploadImage from './Common/UploadImage';
import ColorPicker from '../Branding/ColorPicker/ColorPicker';
import { getdefaultUIValue } from '../../containers/FormModule/helper';
import ModuleInputs from './Common/ModuleInputs';
import Toggle from './Common/Toggle';
import DateRangeValidator from './Common/DateRangeValidator';
import JsonEditor from './Common/JsonEditor';
import ListItemEditor from './Common/ListItemEditor';

function FormModuleGenericEditor(props) {
  const {
    component,
    handleComponentPropertyChange,
    sectionBrandingKeys,
    nextStepOptions,
    customUiConfig,
    moduleId,
    handleDropdownOptionsChange,
    supportedFonts,
    currentUiConfig,
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
    selectedWorkflowId,
    preDefinedValues,
    moduleBuilderProperties,
    handleListDataChange,
  } = props;

  const getDefaultValue = (
    brandingKey,
    currComponent,
    currModuleId,
    currCustomUiConfig,
    isUIProperty,
  ) => {
    if (!isUIProperty) {
      const { workflowKey } = brandingKey;
      const defaultValue = get(currComponent, workflowKey, brandingKey.default);
      return defaultValue;
    }
    const { uiKey, position = '' } = brandingKey;
    const compId = position ? get(currComponent, `${position}.id`, currComponent.id) : currComponent.id;
    const path = `${currModuleId}.${compId}.${uiKey}`;
    return get(currCustomUiConfig, path) === undefined
      ? getdefaultUIValue(currentUiConfig, brandingKey.default, currComponent?.subType || '')
      : get(currCustomUiConfig, path);
  };

  const getDefaultValueForNextStep = (
    brandingKey,
    currComponent,
  ) => {
    const { workflowKey } = brandingKey;
    const defaultValue = get(currComponent, workflowKey, brandingKey.default);
    const isPresentInNextStep = nextStepOptions.find((option) => option.key === defaultValue);
    return isPresentInNextStep ? defaultValue : null;
  };

  const getDropdownOptions = (brandingKey) => {
    if (brandingKey.uiKey === 'font') {
      return supportedFonts.map(({ text, value }) => ({
        key: value,
        name: text,
      }));
    }
    return brandingKey.values;
  };

  return (
    <div className="edit-properties-div__property">
      {sectionBrandingKeys.map((brandingKey) => {
        const isUIProperty = !!brandingKey.uiKey;
        const key = brandingKey.uiKey || brandingKey.workflowKey;
        const position = brandingKey.position || '';
        const identifier = `${moduleId}.${component.id}.${key}.${position}`;
        switch (brandingKey.type) {
          case 'text':
            return (
              <DebouncedTextInput
                label={brandingKey.name}
                onChange={(value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                defaultValue={getDefaultValue(
                  brandingKey,
                  component,
                  moduleId,
                  customUiConfig,
                  isUIProperty,
                )}
                readOnly={brandingKey.readOnly}
                key={identifier}
                placeholder={brandingKey.placeholder}
              />
            );
          case 'moduleOutputs':
            return (
              <ModuleInputs
                label={brandingKey.name}
                onChange={(value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                defaultValue={getDefaultValue(
                  brandingKey,
                  component,
                  moduleId,
                  customUiConfig,
                  isUIProperty,
                )}
                key={identifier}
                placeholder={brandingKey.placeholder}
                workflowInputs={workflowInputs}
                conditionalVariables={conditionalVariables}
                moduleOutputs={moduleOutputs}
              />
            );
          case 'dropdown':
            return (
              <DropDown
                label={brandingKey.name}
                options={getDropdownOptions(brandingKey)}
                onChange={(value) => {
                  handleComponentPropertyChange(
                    value,
                    key,
                    component,
                    isUIProperty,
                    position,
                  );
                }}
                defaultValue={getDefaultValue(
                  brandingKey,
                  component,
                  moduleId,
                  customUiConfig,
                  isUIProperty,
                )}
                key={identifier}
                placeholder={brandingKey.placeholder}
              />
            );
          case 'nextStep':
            return (
              <DropDown
                label={brandingKey.name}
                options={nextStepOptions}
                onChange={(value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                defaultValue={getDefaultValueForNextStep(
                  brandingKey,
                  component,
                )}
                key={identifier}
                placeholder={brandingKey.placeholder}
              />
            );
          case 'toggleWithCondition':
            return (
              <ToggleWithCondition
                label={brandingKey.name}
                onChange={(value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                defaultValue={getDefaultValue(
                  brandingKey,
                  component,
                  moduleId,
                  customUiConfig,
                  isUIProperty,
                )}
                key={identifier}
                workflowInputs={workflowInputs}
                conditionalVariables={conditionalVariables}
                moduleOutputs={moduleOutputs}
                preDefinedValues={preDefinedValues}
                moduleBuilderProperties={moduleBuilderProperties}
              />
            );
          case 'validationInput':
            return (
              <SetComponentValidations
                validationConfig={brandingKey}
                onChange={(value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                defaultValue={getDefaultValue(
                  brandingKey,
                  component,
                  moduleId,
                  customUiConfig,
                  isUIProperty,
                )}
                key={identifier}
                workflowInputs={workflowInputs}
                conditionalVariables={conditionalVariables}
                moduleOutputs={moduleOutputs}
                preDefinedValues={preDefinedValues}
              />
            );
          case 'dropdownOptionsInput':
            return (
              <SetDropDownOptions
                label={brandingKey.name}
                onChange={(options) => handleDropdownOptionsChange(
                  options,
                  component,
                  position,
                )}
                defaultItems={component.items}
                defaultLabels={component.labels}
                key={identifier}
                workflowInputs={workflowInputs}
                conditionalVariables={conditionalVariables}
                moduleOutputs={moduleOutputs}
              />
            );
          case 'numberScroller':
            return (
              <NumberInput
                label={brandingKey.name}
                defaultValue={getDefaultValue(
                  brandingKey,
                  component,
                  moduleId,
                  customUiConfig,
                  isUIProperty,
                )}
                placeholder={brandingKey.placeholder}
                onChange={(value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                min={brandingKey.values?.find((k) => k.name === 'min')?.key}
                max={brandingKey.values?.find((k) => k.name === 'max')?.key}
                key={identifier}
              />
            );
          case 'dropdownWithCustomInteger':
            return (
              <DropDownWithCustomInput
                label={brandingKey.name}
                defaultDataArray={component.data}
                placeholder={brandingKey.placeholder}
                onChange={(value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                options={brandingKey.values}
                key={identifier}
              />
            );
          case 'colorPicker':
            return (
              <ColorPicker
                name={brandingKey.name}
                color={getDefaultValue(
                  brandingKey,
                  component,
                  moduleId,
                  customUiConfig,
                  isUIProperty,
                )}
                placeholder={brandingKey.placeholder}
                onChange={(_, value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                key={identifier}
              />
            );
          case 'toggle':
            return (
              <Toggle
                label={brandingKey.name}
                onChange={(value) => {
                  const returnValue = value ? 'yes' : 'no';
                  return handleComponentPropertyChange(
                    returnValue,
                    key,
                    component,
                    isUIProperty,
                    position,
                  );
                }}
                value={getDefaultValue(brandingKey, component, moduleId, customUiConfig, isUIProperty) !== 'no'}
                key={identifier}
              />
            );
          case 'jsonObject':
            return (
              <JsonEditor
                label={brandingKey.name}
                onChange={(value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                defaultValue={getDefaultValue(
                  brandingKey,
                  component,
                  moduleId,
                  customUiConfig,
                  isUIProperty,
                )}
                rootName={brandingKey.workflowKey}
                key={identifier}
              />
            );
          case 'validationInputRuleOnly':
            return (
              <SetComponentValidations
                validationConfig={brandingKey}
                onChange={(value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                defaultValue={getDefaultValue(
                  brandingKey,
                  component,
                  moduleId,
                  customUiConfig,
                  isUIProperty,
                )}
                key={identifier}
                hideRegexTab
                preDefinedValues={preDefinedValues}
              />
            );
          case 'numberScrollerInteger':
            return (
              <NumberInput
                label={brandingKey.name}
                defaultValue={getDefaultValue(
                  brandingKey,
                  component,
                  moduleId,
                  customUiConfig,
                  isUIProperty,
                )}
                placeholder={brandingKey.placeholder}
                onChange={(value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                min={brandingKey.values?.find((k) => k.name === 'min')?.key}
                max={brandingKey.values?.find((k) => k.name === 'max')?.key}
                key={identifier}
                returnInteger
              />
            );
          case 'dateRangeValidator':
            return (
              <DateRangeValidator
                label={brandingKey.name}
                onChange={(value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                defaultValue={getDefaultValue(
                  brandingKey,
                  component,
                  moduleId,
                  customUiConfig,
                  isUIProperty,
                )}
                key={identifier}
              />
            );
          case 'imagePicker':
            return (
              <UploadImage
                label={brandingKey.name}
                onChange={(value) => handleComponentPropertyChange(
                  value,
                  key,
                  component,
                  isUIProperty,
                  position,
                )}
                defaultValue={getDefaultValue(
                  brandingKey,
                  component,
                  moduleId,
                  customUiConfig,
                  isUIProperty,
                )}
                selectedWorkflowId={selectedWorkflowId}
                key={identifier}
              />
            );
          case 'listItemEditor':
            return (
              <ListItemEditor
                label={brandingKey.name}
                onChange={(newDataArray) => handleListDataChange(
                  newDataArray,
                  component,
                  position,
                )}
                defaultDataArray={component.data}
                workflowInputs={workflowInputs}
                conditionalVariables={conditionalVariables}
                moduleOutputs={moduleOutputs}
                key={identifier}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
}

FormModuleGenericEditor.propTypes = {
  component: PropTypes.object.isRequired,
  handleComponentPropertyChange: PropTypes.func.isRequired,
  sectionBrandingKeys: PropTypes.array.isRequired,
  nextStepOptions: PropTypes.array.isRequired,
  customUiConfig: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  handleDropdownOptionsChange: PropTypes.func.isRequired,
  supportedFonts: PropTypes.object.isRequired,
  currentUiConfig: PropTypes.object.isRequired,
  workflowInputs: PropTypes.array.isRequired,
  conditionalVariables: PropTypes.array.isRequired,
  moduleOutputs: PropTypes.array.isRequired,
  selectedWorkflowId: PropTypes.string.isRequired,
  preDefinedValues: PropTypes.array.isRequired,
  moduleBuilderProperties: PropTypes.oneOfType([null, PropTypes.array]).isRequired,
  handleListDataChange: PropTypes.func.isRequired,
};

export default FormModuleGenericEditor;
