import PropTypes from 'prop-types';
import { useEffect } from 'react';
import '../../../containers/Container.scss';
import upbtn from '../../../assests/icons/upbtn.svg';

function ModuleNavigator({
  highlightedNodes, selectedNodeId, onNavigateModule,
}) {
  const defaultUpNodeIndex = highlightedNodes.length - 1;
  const defaultDownNodeIndex = 0;

  const handleUpClick = () => {
    if (selectedNodeId === undefined) {
      onNavigateModule(highlightedNodes[defaultUpNodeIndex]);
    } else {
      const selectedIndex = highlightedNodes.findIndex((node) => node.id === selectedNodeId);

      if (selectedIndex === -1 || selectedIndex === 0) {
        onNavigateModule(highlightedNodes[defaultUpNodeIndex]);
      } else {
        onNavigateModule(highlightedNodes[selectedIndex - 1]);
      }
    }
  };

  const handleDownClick = () => {
    if (selectedNodeId === undefined) {
      onNavigateModule(highlightedNodes[defaultDownNodeIndex]);
    } else {
      const selectedIndex = highlightedNodes.findIndex((node) => node.id === selectedNodeId);

      if (selectedIndex === -1 || selectedIndex === highlightedNodes.length - 1) {
        onNavigateModule(highlightedNodes[defaultDownNodeIndex]);
      } else {
        onNavigateModule(highlightedNodes[selectedIndex + 1]);
      }
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      handleDownClick();
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleEnter);
    return () => {
      window.removeEventListener('keydown', handleEnter);
    };
  }, [selectedNodeId, highlightedNodes]);

  return (
    <>
      <div className="more-options">
        <button type="button" className="view_workflow__upbtn" onClick={handleUpClick}>
          <img src={upbtn} alt="up" />
        </button>
        <button type="button" className="view_workflow__downbtn" onClick={handleDownClick}>
          <img src={upbtn} alt="down" />
        </button>
      </div>
    </>
  );
}

ModuleNavigator.propTypes = {
  highlightedNodes: PropTypes.array,
  selectedNodeId: PropTypes.string,
  onNavigateModule: PropTypes.func.isRequired,
};

ModuleNavigator.defaultProps = {
  highlightedNodes: [],
  selectedNodeId: undefined,
};

export default ModuleNavigator;
