import { endStateKeys, eventHandlers } from '../../components/constants';
import {
  getDefaultComponent,
  refreshComponentIds,
  removePropertiesRecursivelyFromComponent,
} from '../../components/FormModule/utils';
import {
  getAllNextSteps, getComponentFromPath,
  getFormComponents, performOpOnWorkflow,
  getModuleFromId, saveDataToClipboard,
  createReplacementMap,
} from './helper';
import HVError, { errorCodes, errorMessages } from '../../utils/error';
import { checkAndUpdateNextStepsInFormComponents } from '../../compilerDecompiler/utils/replaceNextStepId';

export const dragComponent = (workflow, moduleId, fromPathArray, toPathArray, rootPath) => {
  if (fromPathArray === toPathArray) return workflow;
  const selectedModule = getModuleFromId(workflow, moduleId);
  const formComponents = getFormComponents(selectedModule, rootPath);
  const draggedComponent = getComponentFromPath(formComponents, fromPathArray);
  let editedWorkflow = performOpOnWorkflow('delete', workflow, moduleId, fromPathArray, rootPath);
  editedWorkflow = performOpOnWorkflow(
    'insert',
    editedWorkflow,
    moduleId,
    toPathArray,
    rootPath,
    draggedComponent,
  );
  return { editedWorkflow, success: true };
};

export const pasteInDynamicForm = (workflow, moduleId, updatedComponent, rootPath) => {
  const updatedWorkflow = performOpOnWorkflow(
    'add',
    workflow,
    moduleId,
    [],
    rootPath,
    updatedComponent,
  );
  return { updatedWorkflow, success: true };
};

// TODO: Add error handling here for the opertions
export const addComponent = (workflow, moduleId, defaultConfig, pathArray, rootPath) => {
  const selectedModule = getModuleFromId(workflow, moduleId);
  const componentToBeAdded = getDefaultComponent(defaultConfig, selectedModule);
  const editedWorkflow = performOpOnWorkflow(
    'add',
    workflow,
    moduleId,
    pathArray,
    rootPath,
    componentToBeAdded,
  );
  return { editedWorkflow, success: true };
};

export const updateComponent = (
  workflow,
  moduleId,
  newComponent,
  pathArray,
  rootPath,
) => {
  const editedWorkflow = performOpOnWorkflow('update', workflow, moduleId, pathArray, rootPath, newComponent);
  return { editedWorkflow, success: true };
};
const collectComponentIds = (component) => {
  let ids = [];
  // Add the component's ID to the ids object
  if (component.id) {
    ids.push(component.id);
  }

  // Recursively collect IDs from subComponents if they exist
  if (component.subComponents?.length) {
    component.subComponents.forEach((subComponent) => {
      ids = ids.concat(collectComponentIds(subComponent));
    });
  }

  return ids;
};
export const extractMatchingConfigEntries = (uiConfig, moduleId, collectedIds) => {
  const matchingEntries = {};
  if (uiConfig[moduleId]) {
    const moduleConfig = uiConfig[moduleId];
    collectedIds.forEach((id) => {
      if (moduleConfig[id]) {
        matchingEntries[id] = moduleConfig[id];
      }
    });
  }

  return matchingEntries;
};

export const copyComponentDataToClipboard = async (
  component,
  moduleId,
  uiConfig,
) => {
  try {
    const clonnedComponent = { ...component };
    const propertiesToRemove = ['enabled', 'visible', 'required', 'value', 'validation'];
    let componentWithoutProperties =
    removePropertiesRecursivelyFromComponent(clonnedComponent, propertiesToRemove);

    const nextSteps = getAllNextSteps([componentWithoutProperties], eventHandlers, false);
    const replacementMap = createReplacementMap(nextSteps, 'nextStepId', 'approve');
    [componentWithoutProperties] = checkAndUpdateNextStepsInFormComponents(
      [componentWithoutProperties],
      replacementMap,
    );
    const collectedIds = collectComponentIds(componentWithoutProperties);
    const uiConfigData = extractMatchingConfigEntries(uiConfig, moduleId, collectedIds);
    const dataToCopyToClipboard = {
      copiedComponent: componentWithoutProperties,
      uiConfigData,
    };
    await saveDataToClipboard('formComponent', dataToCopyToClipboard);
    return { success: true };
  } catch (error) {
    throw new HVError({
      code: errorCodes.copyToClipboard,
      message: errorMessages.copyToClipboard,
      originalError: error,
    });
  }
};

export const copyComponent = (workflow, moduleId, pathArray, rootPath, formComponentsConfig) => {
  const selectedModule = getModuleFromId(workflow, moduleId);
  const formComponents = getFormComponents(selectedModule, rootPath);
  const copiedComponent = getComponentFromPath(formComponents, pathArray);
  const { component: updatedComponent, originalToClonedComponentIdMap } = refreshComponentIds(
    copiedComponent,
    selectedModule,
    formComponentsConfig,
  );
  const editedWorkflow = performOpOnWorkflow(
    'insert',
    workflow,
    moduleId,
    pathArray,
    rootPath,
    updatedComponent,
  );

  // All the nextSteps coming out from the component should be goto except end states.
  const nextSteps = getAllNextSteps([updatedComponent], eventHandlers, false);
  const moduleRef = getModuleFromId(editedWorkflow, moduleId);
  const existingNextNodeType = moduleRef.next_node_type || {};
  const newNextNodeTypes = nextSteps
    .filter(({ nextStepId }) => !endStateKeys.includes(nextStepId))
    .map(({ componentId, nextStepEvent }) => `${componentId}.${nextStepEvent}.nextStep`)
    .reduce((acc, curr) => Object.assign(acc, { [curr]: 'goto' }), {});
  moduleRef.next_node_type = {
    ...existingNextNodeType,
    ...newNextNodeTypes,
  };
  return { workflow: editedWorkflow, originalToClonedComponentIdMap, success: true };
};

export const deleteComponent = (workflow, moduleId, pathArray, rootPath) => {
  const updatedWorkflow = performOpOnWorkflow(
    'delete',
    workflow,
    moduleId,
    pathArray,
    rootPath,
  );
  return { workflow: updatedWorkflow, success: true };
};
