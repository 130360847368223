import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  selectSelectedNode, selectSelectedWorkflow, selectVersionedModules, selectOrderOfNodes,
} from '../../../../reducers/workflow';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import { workflowOperationsObj } from '../../../../workflowOperations';
import { formComponentList as formComponentsConfig } from '../../../../constants/dynamicFormComponents';
import {
  getConditionalVariables,
  getWorkflowInputVariables,
  getPredefinedValues,
  getModuleOutputs,
} from '../InputsToModule/utils';
import { fetchCurrentValueFromWorkflow, getSelectedModule } from '../InputsToModule/utils/updateWorkflow';
import ToggleWithCondition from '../../../FormModule/Common/ToggleWithCondition';
import './ConditionalToggle.scss';

function ConditionalToggle({ description, isDisabled, infoButton }) {
  const {
    workflowKey, text, default: defaultValue,
  } = description;

  const selectedNode = useSelector(selectSelectedNode);
  const {
    id: selectedNodeId,
    nodeType: selectedNodeType,
    version: selectedNodeVersion = 'v1',
  } = selectedNode || {};
  const versionedModules = useSelector(selectVersionedModules);
  const selectedModuleConfig = versionedModules?.[selectedNodeType]?.[selectedNodeVersion]?.config;
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const workflowInputs = getWorkflowInputVariables(selectedWorkflow);
  const conditionalVariables = getConditionalVariables(selectedWorkflow);
  const preDefinedValues = getPredefinedValues(selectedWorkflow, formComponentsConfig);
  const orderOfNodes = useSelector(selectOrderOfNodes);
  const moduleOutputs = getModuleOutputs(
    orderOfNodes,
    selectedNodeId,
    selectedWorkflow,
    formComponentsConfig,
    versionedModules,
    true,
  );
  const moduleBuilderProperties = selectedWorkflow?.properties?.moduleBuilder?.builderProperties
    || {};

  const getDefaultToggleValue = (workflow, moduleId, key) => {
    const module = getSelectedModule(workflow, moduleId);
    const currentValue = fetchCurrentValueFromWorkflow(module, key);
    const ToggleValue = currentValue || defaultValue;
    return ToggleValue;
  };
  const toggleValue = getDefaultToggleValue(selectedWorkflow, selectedNodeId, workflowKey);
  const updateValueInWorkflow = (newValue) => {
    updateWorkflowInState({}, true, {
      operation: workflowOperationsObj.SET_MODULE_PROPERTY,
      actionData: {
        targetNodeId: selectedNodeId,
        workflowKey,
        value: newValue,
        moduleConfig: selectedModuleConfig,
      },
    });
  };
  const UpdateValue = (value) => {
    if (value !== '' && value !== undefined && value !== null) {
      updateValueInWorkflow(value);
    }
  };

  return (
    <>
      <div className="description-toggle__label">
        <ToggleWithCondition
          label={text}
          onChange={UpdateValue}
          defaultValue={toggleValue}
          workflowInputs={workflowInputs}
          conditionalVariables={conditionalVariables}
          moduleOutputs={moduleOutputs}
          preDefinedValues={preDefinedValues}
          moduleBuilderProperties={moduleBuilderProperties}
          isDisabled={isDisabled}
          infoButton={infoButton}
        />
      </div>
    </>
  );
}

ConditionalToggle.propTypes = {
  description: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool,
  infoButton: PropTypes.object,
};

ConditionalToggle.defaultProps = {
  isDisabled: false,
  infoButton: null,
};

export default ConditionalToggle;
