/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import { cloneDeep } from 'lodash';
import { selectVersionedModules, selectSelectedWorkflow } from '../reducers/workflow';
import updateGotoTagsInWorkflow from '../utils/updateGotoTags';
import updateModuleNamesInWorkflow from '../utils/updateModuleNamesInWorkflow';
import store, { getStateValue } from '../store';
import {
  isNextStepPresentInDynamicForm,
  isNextStepOfNodeNotPointingToItSelf,
  isDeletedModulesOrConditionsPresentInWorkflow,
  anyEndStateReachable,
} from '../validations/workflowValidation';
import removeUnvisitedNodesAndConditions from './utils';
import { operateOnWorkflow, supportedWorkflowOperations } from '.';
import { clubCssInFormV2, setDomIdsInFormV2 } from './operations/preOperations';
import { checkNodesGettingDeletedFromDynamicForm } from './warnings/form';
import { selectCustomUIConfig, selectDefaultUIConfig, updateCustomUiConfig } from '../reducers/editBranding';

const preProcessor = (workflow, preOperations) => preOperations.reduce((accumulator, currFn, index) => {
  const updatedWorkflow = currFn(accumulator);
  return updatedWorkflow;
}, workflow);

const validate = (workflow, validators) => {
  let isValid = true;
  let code = null;
  const validationResults = {};
  let result = null;
  validators.forEach(({ code: errorCode, fn: validator }) => {
    result = validator(workflow);
    if (result.length > 0) {
      isValid = false;
      validationResults[errorCode] = (validationResults[errorCode] || []).concat(result);
    } else {
      isValid = isValid && result;
    }
    if (!code && !isValid) code = errorCode;
  });
  return { isValid, code, validationResults: validationResults[code] };
};

const updateModuleNames = (workflow) => {
  const versionedModules = selectVersionedModules(getStateValue());
  return updateModuleNamesInWorkflow(workflow, versionedModules);
};

export const validateCountryModule = (workflow) => {
  const state = store.getState();
  const { countryIdNameMapping } = state.workflow;
  const isPresent = workflow.modules?.[0].type === 'countries';
  const isNotDuplicate =
    (workflow.modules?.filter((module) => module.type === 'countries') || []).length === 1;
  const { countriesSupported } = workflow.modules?.[0].properties || {};
  const allowedCountries = countriesSupported?.filter((country) => Boolean(countryIdNameMapping[country]));
  const notEmpty = allowedCountries?.length >= 1 || 0;
  const allCountriesSupported = allowedCountries?.length === countriesSupported?.length;
  return isPresent && isNotDuplicate && notEmpty && allCountriesSupported;
};

export const validateWorkflow = (workflow) => {
  const validators = [
    { code: 'noEndStateReachable', fn: anyEndStateReachable },
    { code: 'nextStepNotPresentinDynamaicForm', fn: isNextStepPresentInDynamicForm },
    { code: 'nextStepOfNodePointingToSelf', fn: isNextStepOfNodeNotPointingToItSelf },
    { code: 'undefinedConditionOrModule', fn: isDeletedModulesOrConditionsPresentInWorkflow },
    // Commenting the below check because its not consistent as we are allowing terminal nodes
    // as next step from other modules
    // { code: 'noTerminalsInDynamicForm', fn: terminalNotAllowedInDynamicForm },
    { code: 'countryModuleNotCorrect', fn: validateCountryModule },
  ];
  return validate(workflow, validators);
};

const getFirstWarning = (oldWorkflow, updatedWorkflow, warnings, sourceType) => {
  if (!oldWorkflow?.modules?.length) return { showWarning: false, message: '' };
  let flagged = false;
  let warningMessage = '';
  warnings.some((warningFn) => {
    const { showWarning, message } = warningFn(oldWorkflow, updatedWorkflow, sourceType);
    if (showWarning) {
      flagged = true;
      warningMessage = message;
      return true;
    }
    return false;
  });
  return { showWarning: flagged, message: warningMessage };
};

const getUiConfig = (customUiConfig, defaultUiConfig) => (
  customUiConfig && Object.keys(customUiConfig).length > 0 ?
    customUiConfig :
    defaultUiConfig
);

export const updateWorkflowInState = (updatedInputWorkflow, isEdited = true, action = { }) => {
  let workflow = updatedInputWorkflow;
  let extraData = {};
  let uiConfig = null;
  const { sourceType = null } = action || {};
  const oldWorkflow = selectSelectedWorkflow(getStateValue());
  const oldUiConfig = getUiConfig(
    selectCustomUIConfig(getStateValue()),
    selectDefaultUIConfig(getStateValue()),
  );
  if (supportedWorkflowOperations.includes(action?.operation)) {
    const {
      workflow: updatedWorkflow, uiConfig: updatedUiConfig, success, extraData: data = {},
    } = operateOnWorkflow(oldWorkflow, action, oldUiConfig);
    extraData = data;
    if (!success) return { success: false, extraData };
    workflow = updatedWorkflow;
    uiConfig = updatedUiConfig;
  }

  const preOperations = [updateModuleNames, updateGotoTagsInWorkflow, removeUnvisitedNodesAndConditions, setDomIdsInFormV2, clubCssInFormV2];
  const warnings = [checkNodesGettingDeletedFromDynamicForm];
  const operatingWorkflow = cloneDeep(workflow);
  const processedWorkflow = preProcessor(operatingWorkflow, preOperations);
  const { isValid: isValidOperation, code, validationResults } = validateWorkflow(processedWorkflow);
  const { showWarning, message } = getFirstWarning(oldWorkflow, processedWorkflow, warnings, sourceType);
  if (isValidOperation) {
    // eslint-disable-next-line no-restricted-globals
    if (!showWarning || confirm(message)) {
      store.dispatch({
        type: 'workflow/updateSelectedWorkflow',
        payload: { workflow: processedWorkflow, isEdited },
      });
      if (uiConfig) store.dispatch(updateCustomUiConfig({ uiConfig }));
      return { success: true, extraData };
    }
  } else {
    let alertMessage = `Operation leading to invalid workflow\n${code || 'Something went wrong'}\nTerminated !!!\n`;
    if (validationResults) {
      alertMessage += `\n${validationResults.join('\n')}`;
    }
    alert(alertMessage);
  }
  return { success: false, extraData };
};
