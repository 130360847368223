import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import generateUniqueID from '../../../../utils/generateUniqueId';

import SimpleObject from './SimpleObject';
import { isValidTypedData, isValidUnTypedData } from './validate';

function SimpleObjects({
  onObjectChange,
  dataArray,
  nameLabel,
  pathLabel,
  typeLabel,
  addBtnLabel,
  typed,
  menuOptions,
}) {
  const onChange = (currentData) => {
    const finalData = currentData.length === 0 ? null : currentData;
    onObjectChange(finalData);
  };

  const onKeyChange = (newKey, id, currentData) => {
    const newDataList = cloneDeep(currentData || []);
    const index = currentData.findIndex(({ id: currentId }) => currentId === id);
    if (index !== -1) newDataList[index].key = newKey;
    onChange(newDataList);
  };

  const onValueChange = (value, id, currentData) => {
    const newDataList = cloneDeep(currentData || []);
    const index = currentData.findIndex(({ id: currentId }) => currentId === id);
    if (index !== -1) newDataList[index].value = value;
    onChange(newDataList);
  };

  const addNewKey = (currentData) => {
    const defaultValue = '';
    const newDataList = cloneDeep(currentData || []);
    newDataList.push({ id: generateUniqueID(), key: '', value: defaultValue });
    onChange(newDataList);
  };

  const deleteKey = (id, currentData) => {
    const newDataList = cloneDeep(currentData || []);
    const index = currentData.findIndex(({ id: currentId }) => currentId === id);
    if (index === -1) return;
    newDataList.splice(index, 1);
    onChange(newDataList);
  };

  const isInputDataValid = (currentData) => {
    const data = currentData || [];
    if (typed) return isValidTypedData(data);
    return isValidUnTypedData(data);
  };

  const isKeyValid = (currentKey, currentData) => {
    if (currentKey === '') return false;
    const similarKeys = (currentData || []).filter(({ key }) => key === currentKey);
    return similarKeys.length === 1;
  };

  return (
    <div className="workflowOutput__bottomContent_body">
      { isInputDataValid(dataArray) ?
        (dataArray || []).map(({ key, value, id }) => (
          <SimpleObject
            key={id}
            keyName={key}
            nameLabel={nameLabel}
            pathLabel={pathLabel}
            typeLabel={typeLabel}
            value={value}
            onKeyChange={(newValue) => { onKeyChange(newValue, id, dataArray); }}
            onValueChange={(newValue) => { onValueChange(newValue, id, dataArray); }}
            onDelete={() => { deleteKey(id, dataArray); }}
            typed={typed}
            isKeyNameValid={isKeyValid(key, dataArray)}
            errorText={isKeyValid(key, dataArray) ? '' : 'invalid key'}
            menuOptions={menuOptions}
          />
        )) : <p>Opps! Workflow has invalid data</p>}
      {
        isInputDataValid(dataArray) ? <button type="button" onClick={() => { addNewKey(dataArray); }} className="workflowInput__bottomContent_button">{addBtnLabel}</button> : null
      }
    </div>
  );
}

SimpleObjects.defaultProps = {
  typeLabel: 'Type',
  dataArray: null,
};

SimpleObjects.propTypes = {
  dataArray: PropTypes.array,
  onObjectChange: PropTypes.func.isRequired,
  nameLabel: PropTypes.string.isRequired,
  pathLabel: PropTypes.string.isRequired,
  typeLabel: PropTypes.string,
  addBtnLabel: PropTypes.string.isRequired,
  typed: PropTypes.bool.isRequired,
  menuOptions: PropTypes.object.isRequired,
};

export default SimpleObjects;
