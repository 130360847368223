import PropTypes from 'prop-types';
import PropertiesTooltip from '../Tooltip/Tooltip';

function SelectedCountriesTable(props) {
  const {
    options,
    countriesSelected,
    togglePopup,
    isDisabled,
    infoButton,
  } = props;

  const { titleText, selectButtonText } = options;

  return (
    <div className="document-select">
      <div className="document-select__title">
        {titleText}
        <PropertiesTooltip infoButton={infoButton} />
      </div>
      {countriesSelected.length > 0 ? (
        <div className="countries-table">
          <div className="configuration-heading">
            {' '}
          </div>
          <table>
            <tbody>
              {countriesSelected.map((item) => (
                <tr key={`${item.name}_table`}>
                  <td className="flag-column">
                    {' '}
                    <img className="countries-table__countries-flag" src={`https://hv-central-config.s3.ap-south-1.amazonaws.com/gsdk-country-doc-list/flags/${item.id}.png`} alt="" />
                  </td>
                  <td>
                    {item.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : ''}
      <button
        type="button"
        className="document-select__btn"
        onClick={togglePopup}
        disabled={isDisabled}
      >
        {selectButtonText}
      </button>
    </div>
  );
}

SelectedCountriesTable.propTypes = {
  options: PropTypes.object.isRequired,
  countriesSelected: PropTypes.array.isRequired,
  togglePopup: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  infoButton: PropTypes.object,
};

SelectedCountriesTable.defaultProps = {
  isDisabled: false,
  infoButton: null,
};

export default SelectedCountriesTable;
