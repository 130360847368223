import { cloneDeep, merge } from 'lodash';
import getWorkflowById from '../actions/getWorkflowById';
import decompile from '../compilerDecompiler/decompiler';
import { convertOldNextNodeTypeKeysToNew } from '../containers/workflowOperations';
import useApiHooks from './useApiHooks';
import HVError, { errorCodes, errorMessages } from './error';
import { validateAndUpdateModulesSubtypes } from './PreWorkflowOperations';

const useFetchWorkflow = (currentAppId, workflowConfig, versionedModules) => {
  const { fetchModuleByVersion } = useApiHooks();

  const fetchWorkflow = async (id) => {
    try {
      let workflow = workflowConfig;
      if (!workflowConfig || Object.keys(workflowConfig).length === 0) {
        const rawWorkflow = await getWorkflowById(id, currentAppId);
        const transformedWorkflow = convertOldNextNodeTypeKeysToNew(rawWorkflow);

        // Get the versions of module configs that will be needed for decompilation
        const requiredModuleVersions = {};
        rawWorkflow.modules.forEach((module) => {
          if (module.superModuleId) {
            requiredModuleVersions[module.superModuleId] = {
              moduleType: module.superModuleType,
              moduleVersion: rawWorkflow?.properties?.builder?.superModuleMetaData?.[module.superModuleId]?.version || 'v1',
            };
          } else {
            requiredModuleVersions[module.id] = {
              moduleType: module.subType,
              moduleVersion: module.version || 'v1',
            };
          }
        });

        // Fetch the required module config versions, if not available in versionedModules
        const fetchModuleConfigPromises =
          Object.values(requiredModuleVersions).map(({ moduleType, moduleVersion }) => {
            if (!versionedModules[moduleType]) {
              /* eslint-disable no-console */
              console.error(`Error fetching module for subType ${moduleType} Please change it to a valid subType, for api it can ge genericApi. It can be one of the module names present in the s3`);
              throw new HVError({
                code: errorCodes.unknownSubType,
                message: `${errorMessages.unknownSubType} : ${moduleType}`,
              });
            }
            if (!versionedModules[moduleType][moduleVersion]) {
              return fetchModuleByVersion(moduleType, moduleVersion);
            }
            return null;
          })
            .filter((promise) => promise !== null);

        const requiredModuleConfigs = await Promise.all(fetchModuleConfigPromises);
        const newVersionedModules = {};
        requiredModuleConfigs.forEach(({ module }) => {
          const { version = 'v1', config, uiConfig } = module;
          newVersionedModules[config.subType] = {
            ...newVersionedModules[config.subType],
            [version]: { config, uiConfig },
          };
        });
        const updatedVersionedModules = cloneDeep(versionedModules);
        merge(updatedVersionedModules, newVersionedModules);
        // lowLevelWorkflow, moduleConfigs, libraries
        // Decompile the workflow here
        workflow = decompile(transformedWorkflow, updatedVersionedModules);
        const enableAtDecompilation = true;
        const { workflow: finalWorkflow } =
        validateAndUpdateModulesSubtypes(workflow, versionedModules, enableAtDecompilation);
        return {
          workflow: finalWorkflow,
          rawWorkflow: transformedWorkflow,
          modules: updatedVersionedModules,
        };
      }
      // updateWorkflowInState(workflow, false);
      // TODO: Understand, Why do we need it?
    } catch (err) {
      throw new HVError({
        code: err?.code || errorCodes.somethingWentWrong,
        message: err?.message || errorMessages.errorFetchingWorkflow,
        originalError: err instanceof HVError ? err.originalError : err,
      });
    }
    return null;
  };

  return fetchWorkflow;
};

export default useFetchWorkflow;
