/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ModuleNode from './ModuleNode';
import { selectVersionedModules } from '../reducers/workflow';

export default function SideBar({ onSubmitButtonClick }) {
  const versionedModules = useSelector(selectVersionedModules);

  const onDragStart = (event, data) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify(data));
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = 'move';
  };
  return (
    <aside>
      <div className="description">You can drag these nodes to the pane on the left.</div>
      {Object.entries(versionedModules).map(([moduleName, moduleDetails]) => {
        const { latestVersion = 'v1' } = moduleDetails;
        const { uiConfig } = moduleDetails[latestVersion].config;
        return (
          <div
            className="dndnode"
            key={moduleName}
            onDragStart={(event) => onDragStart(event, { nodeType: moduleName })}
            draggable
          >
            <ModuleNode
              heading={uiConfig.node.heading}
              subHeading={uiConfig.node.subHeading}
              type={uiConfig.node.nodeType}
            />
          </div>
        );
      })}
      <button type="submit" onClick={onSubmitButtonClick}>Submit</button>
    </aside>
  );
}

SideBar.propTypes = {
  onSubmitButtonClick: PropTypes.func.isRequired,
};
