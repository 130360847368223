/* eslint-disable react/require-default-props */
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import useDebounce from '../../../../utils/useDebounce';
import './IntTextInput.scss';

function IntTextInput({ label, onChange, defaultValue }) {
  const [value, setValue] = useState(defaultValue);
  const prevValue = useRef(defaultValue || '');
  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    if (prevValue.current !== debouncedValue) onChange(debouncedValue);
    prevValue.current = debouncedValue;
  }, [debouncedValue]);

  const onValueChange = (e) => {
    const updatedValue = e.target.value;
    setValue(updatedValue);
  };

  return (
    <div className="edit-properties-div__property">
      <Grid container alignItems="center" rowSpacing={1}>
        <Grid item xs={4}>{label}</Grid>
        <Grid item xs={8} justifyContent="flex-end">
          <input
            type="number"
            className="text-input-div__text-input"
            value={value}
            onChange={onValueChange}
          />
        </Grid>
      </Grid>
    </div>
  );
}
export default IntTextInput;

IntTextInput.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
};
