import { cloneDeep } from 'lodash';
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import generateUniqueID from '../../../../utils/generateUniqueId';
import ConditionalVariablesDrawer from './ConditionalVariablesDrawer';
import addBtn from '../../../../assests/icons/addBtn.svg';
import editBtn from '../../../../assests/icons/editIcon.svg';
import deleteBtn from '../../../../assests/icons/deleteRuleIcon.svg';
import './ConditionalVariable.scss';
import {
  addConditionalVariableLog,
  deleteConditionalVariableLog,
  editConditionalVariableLog,
  updateConditionalVariableLog,
} from '../../../../logger/logHighLevelWorkflowUpdates';

function DisplayConditionalVariables(props) {
  const {
    conditionalVariables,
    saveConditionalVariables,
    canDeleteConditionalVariable,
    dropDownOptions,
    getConditionalVariableDeps,
    setConditionalVariableLogs,
    preDefinedValues,
  } = props;

  const [editConditionalVariable, setEditConditionalVariable] = useState(null);

  const childMap = useMemo(
    () => Object.entries(conditionalVariables)
      .reduce((mapSoFar, [id, value]) => {
        // some existing conditional variables donot contain parent.
        const { parent = id } = value;

        if (parent && parent === id) {
          // it is an absolute parent, so create a child array for it
          const individualChildArray = [];

          let hasValidNextChild = true;
          let currentElement = conditionalVariables[parent];
          let currId = parent;

          while (hasValidNextChild) {
            individualChildArray.push({ ...currentElement, id: currId });

            if (!currentElement.if_false.startsWith('conditionalVariables.')) {
              hasValidNextChild = false;
            } else {
              const [, ...next] = currentElement.if_false.split('.');
              currId = next.join('.');
              currentElement = conditionalVariables[currId];
              hasValidNextChild = currentElement && currentElement.parent === id;
            }
          }

          return {
            ...mapSoFar,
            [id]: individualChildArray,
          };
        }
        return mapSoFar;
      }, {}),

    [conditionalVariables],
  );

  const updateConditionalVariables = (newChildMap) => {
    const updatedConditionalVariables = Object.entries(newChildMap || {})
      .reduce((objSoFar, [, childArray]) => {
        const condVars = childArray.reduce((condVarsSoFar, condVar) => {
          const { id, ...value } = condVar;

          return {
            ...condVarsSoFar,
            [id]: value,
          };
        }, {});
        return {
          ...objSoFar,
          ...condVars,
        };
      }, {});
    saveConditionalVariables(updatedConditionalVariables);
  };

  const onEditBtnClick = (condVar) => {
    const log = editConditionalVariableLog({ id: condVar[0].id });
    setConditionalVariableLogs(log);
    setEditConditionalVariable(condVar);
  };

  const onDeleteConditionalVariable = (key) => {
    // check for dependencies of conditional variable
    const isDependent = canDeleteConditionalVariable(key);
    if (isDependent) return;
    // if no dependencies modify the child map and call the update function with the modified map
    const modifiedChildMap = cloneDeep(childMap);
    delete modifiedChildMap[key];
    const log = deleteConditionalVariableLog({ id: key });
    setConditionalVariableLogs(log);
    updateConditionalVariables(modifiedChildMap);
  };

  const onAddBtnClick = () => {
    const id = generateUniqueID();
    const condVarId = `condVar_${id}`;
    const newCondVar = {
      if_false: "'NONE_NONE'",
      if_true: "'NONE_NONE'",
      parent: condVarId,
      rule: "'NONE_NONE' == 'NONE_NONE'",
      id: condVarId,
    };
    const log = addConditionalVariableLog({ id: newCondVar.id });
    setConditionalVariableLogs(log);
    setEditConditionalVariable([newCondVar]);
  };

  const setShowDrawer = () => {
    setEditConditionalVariable(null);
  };

  const handleChildArrayUpdate = (newChildArray) => {
    const modifiedChildMap = cloneDeep(childMap);
    const key = newChildArray[0].id;
    modifiedChildMap[key] = newChildArray;
    const log = updateConditionalVariableLog({ id: key });
    setConditionalVariableLogs(log);
    updateConditionalVariables(modifiedChildMap);
  };

  const displayConditionalVarUsages = (conVar) => {
    const deps = getConditionalVariableDeps(conVar);
    const isDependent = Boolean(Object.values(deps || {}).reduce(
      (depsNumSoFar, currDeps) => depsNumSoFar + currDeps.length,
      0,
    ));
    if (!isDependent) {
      return <span className="condVar__table_var__values"> - </span>;
    }
    return (
      <div className="condVar__table_var__values">
        {Object.entries(deps).map(([, value]) => value.map((val) => (
          <div key={val} className="condVar__table_var__values__item">
            {val}
          </div>
        )))}
      </div>
    );
  };

  const checkIfChildArrayHasConVar =
    (id, childArray) => !!childArray.find((child) => child.id === id);

  const getPossibleOutputValues = (childArray = []) => {
    const outputs = childArray.reduce((arr, child) => {
      const currval = [];
      const { id, if_true: trueVal, if_false: falseVal } = child;
      const [, ...trueValCondVarId] = trueVal.split('.');
      const [, ...falseValCondVarId] = falseVal.split('.');

      if (trueVal && trueVal !== "'NONE_NONE'" && !checkIfChildArrayHasConVar(trueValCondVarId.join('.'), childArray)) {
        currval.push({
          name: trueVal,
          id: `${id}_trueVal_${trueVal}`,
        });
      }
      if (falseVal && falseVal !== "'NONE_NONE'" && !checkIfChildArrayHasConVar(falseValCondVarId.join('.'), childArray)) {
        currval.push({
          name: falseVal,
          id: `${id}_falseVal_${falseVal}`,
        });
      }
      return [
        ...arr,
        ...currval,
      ];
    }, []);
    if (!outputs.length) return <span className="condVar__table_var__values"> - </span>;
    return outputs.map((val) => (
      <div key={val.id} className="condVar__table_var__values__item">
        {val.name}
      </div>
    ));
  };

  return (
    <>
      <div className="workflowCondVars__topContent">
        <div className="workflowCondVars__topContent_heading">Conditional Variables</div>
        <div className="workflowCondVars__topContent_subheading">
          Variables whose final value are determined by conditions/rules
        </div>
      </div>
      <div className="workflowCondVars__bottomContent">
        {
          Object.keys(childMap || {}).length !== 0
            && (
              <div className="condVar__table">
                <div className="condVar__table__header">
                  <div className="condVar__table__cell">
                    Variable
                  </div>
                  <div className="condVar__table__cell">
                    Used In
                  </div>
                  <div className="condVar__table__cell">
                    Possible Output Values
                  </div>
                </div>
                {
            Object.entries(childMap).map(([key, value], index) => (
              <div key={key} className={`condVar__table__row ${index % 2 !== 0 ? 'condVar__table__row_even' : ''}`}>
                <div className="condVar__table__cell">
                  <span className="condVar__table_var__name">{value[0].name || key}</span>
                </div>
                <div className="condVar__table__cell">
                  {displayConditionalVarUsages(key)}
                </div>
                <div className="condVar__table__cell">
                  <div className="condVar__table_var__values">

                    {getPossibleOutputValues(value)}
                  </div>
                </div>
                <div className="condVar__table__modifyBtn_container">
                  <button
                    type="button"
                    onClick={() => onEditBtnClick(value)}
                    className="condVar__table__modifyBtn"
                  >
                    <img src={editBtn} alt="edit" />
                  </button>
                  <button type="button" onClick={() => onDeleteConditionalVariable(key)} className="condVar__table__modifyBtn" id={key}>
                    <img src={deleteBtn} alt="delete" />
                  </button>
                </div>
              </div>
            ))
          }
              </div>
            )
        }
        <button
          type="button"
          onClick={() => onAddBtnClick()}
          className="workflowCondVars__bottomContent_addBtn"
        >
          <img src={addBtn} alt="add" />
          New Conditional Variable
        </button>
        {
        editConditionalVariable ? (
          <ConditionalVariablesDrawer
            setShowDrawer={setShowDrawer}
            childArray={editConditionalVariable}
            handleChildArrayUpdate={handleChildArrayUpdate}
            dropDownOptions={dropDownOptions}
            preDefinedValues={preDefinedValues}
          />
        )
          : null
      }
      </div>
    </>
  );
}

DisplayConditionalVariables.propTypes = {
  conditionalVariables: PropTypes.object.isRequired,
  saveConditionalVariables: PropTypes.func.isRequired,
  canDeleteConditionalVariable: PropTypes.func.isRequired,
  dropDownOptions: PropTypes.array.isRequired,
  getConditionalVariableDeps: PropTypes.func.isRequired,
  setConditionalVariableLogs: PropTypes.func.isRequired,
  preDefinedValues: PropTypes.array,
};

DisplayConditionalVariables.defaultProps = {
  preDefinedValues: [],
};

export default DisplayConditionalVariables;
