import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import closeButton from '../../../../assests/icons/close.svg';

function Header({
  titleText, saveBtnText, onSave, onClose, isButtonDisabled,
}) {
  return (
    <div className="workflow_configuration_modal__header">
      <span>
        {titleText || 'Configure'}
      </span>
      <Tooltip
        title={
          isButtonDisabled && (
            <span className="workflow_configuration_modal__tooltip">
              <h3>Warning</h3>
              The save button will be disabled if the fields are left empty.
              Please fill in the fields before saving.
            </span>
          )
        }
        arrow
      >
        <button
          type="button"
          className={isButtonDisabled ? 'workflow_configuration_modal__header_button_disabled' : 'workflow_configuration_modal__header_button'}
          onClick={onSave}
          disabled={isButtonDisabled}
        >
          {saveBtnText || 'Save'}
        </button>
      </Tooltip>
      <button
        type="button"
        className="closeModalButton"
        onClick={onClose}
      >
        <img
          src={closeButton}
          alt="close"
        />
      </button>
    </div>
  );
}

Header.propTypes = {
  titleText: PropTypes.string.isRequired,
  saveBtnText: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isButtonDisabled: PropTypes.bool,
};

Header.defaultProps = {
  isButtonDisabled: false,
};

export default Header;
