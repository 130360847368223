import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import {
  findModulesAndConditions, getDefaultComponent, shouldDisplayProperty, updateComponentProperties,
} from './utils';
import FormModuleGenericEditor from './FormModuleGenericEditor';
import CopyIcon from '../../assests/icons/contentCopyIcon.svg';
import DropDown from './Common/DropDown';
import downIcon from '../../assests/icons/dropDownIcon.svg';
import sideIcon from '../../assests/icons/sideIcon.svg';
import { endStates } from '../constants';

function FormModuleEditProperties(props) {
  const {
    selectedModule,
    selectedComponent,
    selectedComponentConfig,
    formComponentsConfig,
    orderOfNodes,
    customUiConfig,
    handleOnComponentChange,
    onFormPropertyChange,
    supportedFonts,
    currentUiConfig,
    addNewFontURL,
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
    selectedWorkflowId,
    preDefinedValues,
    canUpdateType,
    canUpdateSubType,
    moduleBuilderProperties,
  } = props;
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const [copyAlert, setCopyAlert] = useState(false);
  const timerRef = useRef();

  const { subType: componentSubType } = selectedComponent;
  const selectedComponentType = selectedComponent?.type === 'list' ? selectedComponent?.itemsGenerator?.subComponents[0]?.type : selectedComponent?.type;

  const [componentSectionsObj, subTypeDropDown] = useMemo(() => {
    const fieldConfig = selectedComponentConfig;
    const { brandingKeys = [], subType = null } = fieldConfig || {};
    const componentSections = brandingKeys.reduce((sectionsSoFar, currentBrandingKey) => {
      const isValidProperty = shouldDisplayProperty(currentBrandingKey, componentSubType);
      return {
        ...sectionsSoFar,
        ...(isValidProperty && {
          [currentBrandingKey.section.key]: {
            title: currentBrandingKey.section.name,
            fields: [
              ...(sectionsSoFar[currentBrandingKey.section.key]?.fields || []),
              currentBrandingKey,
            ],
          },
        }),
      };
    }, {});
    return [componentSections, subType];
  }, [selectedComponent, selectedComponentConfig]);

  useEffect(() => {
    setOpenDropdowns(
      (prevOpenDropdowns) => (prevOpenDropdowns.length === 0
        ? [Object.keys(componentSectionsObj)[0]] : prevOpenDropdowns),
    );
  }, [componentSectionsObj]);

  const setAlert = () => {
    clearTimeout(timerRef.current);
    setCopyAlert(true);
    timerRef.current = setTimeout(() => {
      setCopyAlert(false);
    }, 5000);
  };

  const handleFieldSelection = (newType, module, formConfig) => {
    const defaultConfig = formConfig.find((component) => component.type === newType);
    const componentToBeAdded = getDefaultComponent(defaultConfig, module);
    handleOnComponentChange(componentToBeAdded);
  };

  const handleSubtypeSelection = (newSubtype, module, formConfig) => {
    const defaultConfig = formConfig.find((component) => component.type === selectedComponentType);
    const componentToBeAdded = getDefaultComponent(defaultConfig, module, newSubtype);
    handleOnComponentChange(componentToBeAdded);
  };

  const getFieldOptions = (formConfig) => formConfig.map(
    (component) => ({ key: component.type, name: component.name }),
  );

  const getNextStepOptions = (allNodes) => {
    const nodes = findModulesAndConditions(allNodes);
    const optionsForNextStepSelection = nodes.map((node) => ({
      key: node.id,
      name: node.name || node.id,
    }));
    Object.keys(endStates).forEach((endState) => {
      optionsForNextStepSelection.push({
        key: endState,
        name: endStates[endState]?.uiConfig?.node?.heading || endState,
      });
    });
    return optionsForNextStepSelection;
  };

  const handleComponentPropertyChange = (
    value,
    key,
    componentId,
    isUIProperty,
    moduleId,
  ) => {
    if (isUIProperty && key === 'font') addNewFontURL(value);
    else {
      onFormPropertyChange({
        key,
        value,
        moduleId,
        componentId,
        isUIProperty,
      });
    }
  };

  const handleOptionsChange = (options, currentComponent) => {
    const updatedItems = [];
    const updatedLabels = {};
    options.forEach((option) => {
      updatedItems.push(option.value);
      updatedLabels[option.value] = option.key;
    });

    let { editedComponent } = updateComponentProperties(
      updatedItems,
      'items',
      currentComponent,
      formComponentsConfig,
      null,
    );
    ({ editedComponent } = updateComponentProperties(
      updatedLabels,
      'labels',
      editedComponent,
      formComponentsConfig,
      null,
    ));
    handleOnComponentChange(editedComponent);
  };

  const handleListDataChange = (dataArray, currentComponent) => {
    const updatedValue = dataArray[0];
    let { editedComponent } = updateComponentProperties(
      dataArray,
      'data',
      currentComponent,
      formComponentsConfig,
      null,
    );
    ({ editedComponent } = updateComponentProperties(
      updatedValue,
      'value',
      editedComponent,
      formComponentsConfig,
      null,
    ));
    handleOnComponentChange(editedComponent);
  };

  const isDropdownOpen = (name) => (openDropdowns || []).find((dropdown) => dropdown === name);

  const onDropdownClick = (name) => {
    if (isDropdownOpen(name)) {
      setOpenDropdowns((currentOpenDropdowns) => currentOpenDropdowns
        .filter((dropdown) => dropdown !== name));
    } else {
      setOpenDropdowns((currentOpenDropdowns) => [...currentOpenDropdowns, name]);
    }
  };

  return (
    <div className="edit-properties-div">
      <div className="edit-properties-div__property">
        <Grid container alignItems="center" className="moduleId">
          <Grid item xs={4}>Module Id</Grid>
          <Grid item xs={8}>
            <div className="edit-properties-div__text-container">
              <input
                type="text"
                className="edit-properties-div__text-input"
                value={selectedModule?.id}
                readOnly
              />
              <button
                type="button"
                className="edit-properties-div__text-copy"
              >
                <CopyToClipboard
                  text={selectedModule?.id}
                  onCopy={setAlert}
                >
                  <img src={CopyIcon} alt="Copy" />
                </CopyToClipboard>
              </button>
            </div>
            {copyAlert && (
              <div className="edit-properties-div__alert">
                formId copied!
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      {selectedComponentType && canUpdateType ? (
        <div className="edit-properties-div__property">
          <DropDown
            label="Type"
            options={getFieldOptions(formComponentsConfig)}
            onChange={(newType) => {
              handleFieldSelection(newType, selectedModule, formComponentsConfig);
            }}
            defaultValue={selectedComponentType}
          />
        </div>
      ) : null }
      {selectedComponentType && subTypeDropDown && canUpdateSubType ? (
        <div className="edit-properties-div__property">
          <DropDown
            onChange={(value) => handleSubtypeSelection(
              value,
              selectedModule,
              formComponentsConfig,
            )}
            defaultValue={
              selectedComponent[subTypeDropDown.workflowKey] || subTypeDropDown.defaultValue
            }
            label={subTypeDropDown.name}
            options={subTypeDropDown.values}
          />
        </div>
      ) : null }
      {
        selectedComponentType &&
        Object.entries(componentSectionsObj || {}).map(([sectionKey, sectionBrandingKeys]) => (
          <div className="edit-properties-div__section" key={sectionKey}>
            <button type="button" className="edit-properties-div__title" onClick={() => onDropdownClick(sectionKey)}>
              {sectionBrandingKeys.title}
              {isDropdownOpen(sectionKey)
                ? <img src={downIcon} alt="down" />
                : <img src={sideIcon} alt="side" />}
            </button>
            {isDropdownOpen(sectionKey) && selectedComponentType && sectionBrandingKeys && (
              <FormModuleGenericEditor
                component={selectedComponent}
                handleComponentPropertyChange={
                  (value, key, currComponent, isUIProperty, position = '') => {
                    const componentId = position ? get(currComponent, `${position}.id`, currComponent.id) : currComponent.id;
                    handleComponentPropertyChange(
                      value,
                      key,
                      componentId,
                      isUIProperty,
                      selectedModule?.id,
                    );
                  }
                }
                sectionBrandingKeys={sectionBrandingKeys.fields}
                nextStepOptions={getNextStepOptions(orderOfNodes)}
                customUiConfig={customUiConfig}
                moduleId={selectedModule?.id}
                handleDropdownOptionsChange={handleOptionsChange}
                supportedFonts={supportedFonts}
                currentUiConfig={currentUiConfig}
                workflowInputs={workflowInputs}
                conditionalVariables={conditionalVariables}
                moduleOutputs={moduleOutputs}
                selectedWorkflowId={selectedWorkflowId}
                preDefinedValues={preDefinedValues}
                moduleBuilderProperties={moduleBuilderProperties}
                handleListDataChange={handleListDataChange}
              />
            )}
          </div>
        ))
      }
    </div>
  );
}

export default FormModuleEditProperties;

FormModuleEditProperties.propTypes = {
  selectedComponent: PropTypes.object.isRequired,
  selectedComponentConfig: PropTypes.object.isRequired,
  selectedModule: PropTypes.object.isRequired,
  formComponentsConfig: PropTypes.array.isRequired,
  orderOfNodes: PropTypes.array.isRequired,
  customUiConfig: PropTypes.object.isRequired,
  handleOnComponentChange: PropTypes.func.isRequired,
  onFormPropertyChange: PropTypes.func.isRequired,
  supportedFonts: PropTypes.object.isRequired,
  currentUiConfig: PropTypes.object.isRequired,
  addNewFontURL: PropTypes.func.isRequired,
  workflowInputs: PropTypes.array.isRequired,
  conditionalVariables: PropTypes.array.isRequired,
  moduleOutputs: PropTypes.array.isRequired,
  selectedWorkflowId: PropTypes.string.isRequired,
  preDefinedValues: PropTypes.array.isRequired,
  canUpdateType: PropTypes.bool.isRequired,
  canUpdateSubType: PropTypes.bool.isRequired,
  moduleBuilderProperties: PropTypes.array.isRequired,
};
