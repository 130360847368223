import PropTypes from 'prop-types';
import Toggle from './Toggle';
import ShowConditionDrawer from './ShowConditionDrawer';
import { createNestedMenuData } from '../../ViewWorkflow/v2/NestedDropdown/utils';

function ToggleWithCondition(props) {
  const {
    label,
    onChange,
    defaultValue,
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
    preDefinedValues,
    moduleBuilderProperties,
    infoButton,
  } = props;
  const toggleValue = defaultValue !== 'no';
  const handleOnChange = (isOn) => {
    const value = isOn ? 'yes' : 'no';
    onChange(value);
  };

  const dropDownOptions = createNestedMenuData(
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
    moduleBuilderProperties,
  ).items;

  return (
    <div className="edit-settings-div__toggle-condition">
      <div className="edit-settings-div__toggle-container">
        <Toggle
          label={label}
          value={toggleValue}
          onChange={handleOnChange}
          infoButton={infoButton}
        />
      </div>
      {toggleValue && defaultValue !== undefined && (
        <ShowConditionDrawer
          defaultValue={defaultValue}
          onChange={(value) => onChange(value)}
          dropDownOptions={dropDownOptions}
          preDefinedValues={preDefinedValues}
        />
      )}
    </div>
  );
}

ToggleWithCondition.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  workflowInputs: PropTypes.array.isRequired,
  conditionalVariables: PropTypes.array.isRequired,
  moduleOutputs: PropTypes.array.isRequired,
  preDefinedValues: PropTypes.array.isRequired,
  moduleBuilderProperties: PropTypes.oneOfType([null, PropTypes.array]).isRequired,
  infoButton: PropTypes.object,
};

ToggleWithCondition.defaultProps = {
  infoButton: null,
};

export default ToggleWithCondition;
