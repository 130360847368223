import { useState, useEffect, useMemo } from 'react';
import { PermissionWrapper } from 'storybook-ui-components';
import axios from 'axios';
import { useSelector } from 'react-redux';
import useShowErrorAlert from '../utils/customHooks';
import AlertCode from '../constants/alertCodes';
import WorkflowTable from './WorkflowList/WorkflowTable';
import './Workflow.scss';
import useGetUserPermissions from '../Permissions/hooks';
import getPermission from '../Permissions/mapping';
import { closeExistingSDK } from './utils';
import ImportWorkflow from './ImportWorkflow/importWorkflow';
import CreateWorkflow from './ImportWorkflow/CreateWorkflow';
import SearchBox from './Common/SearchBox';
import UpdateModal from './ImportWorkflow/UpdateModal';
import { selectModuleBuilderMode } from '../reducers/moduleBuilder';

function WorkflowList() {
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const moduleBuilderMode = useSelector(selectModuleBuilderMode);
  const [workflows, setWorkflows] = useState([]);
  const showErrorAlert = useShowErrorAlert();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isEditWorkflow, setIsEditWorkflow] = useState(false);
  const [updateWorkflowId, setUpdateWorkflowId] = useState('');
  const [updateWorkflowRowData, setUpdateWorkflowRowData] = useState({});
  const [initialData, setInitialData] = useState({});

  const handleEditWorkflow = (workflowId) => {
    setUpdateWorkflowId(workflowId);
    const updateWorkflowRequired = workflows.find((Workflows) => Workflows.id === workflowId);
    setUpdateWorkflowRowData(updateWorkflowRequired);
    const { name, description } = updateWorkflowRequired;
    setInitialData({ name, description });
    setIsEditWorkflow(true);
  };

  const handleWorkflowUpdated = (updatedWorkflow) => {
    const index = workflows.findIndex((workflow) => workflow.id === updatedWorkflow.id);

    if (index !== -1) {
      const newWorkflows = [...workflows];
      newWorkflows[index] = updatedWorkflow;
      setWorkflows(newWorkflows);
    }
  };

  const displayedWorkflows = useMemo(
    () => workflows.filter(
      (workflow) => workflow.name?.toLowerCase().includes(searchValue.toLowerCase())
        || workflow.id?.toLowerCase() === searchValue.toLowerCase(),
    ),
    [searchValue, workflows],
  );

  const fetchWorkflows = async () => {
    setIsLoading(true);
    try {
      const dataRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/workflow`,
        headers: { appId: currentAppId },
      });
      const resultWorkflows = Object.entries(dataRes.data?.result?.workflows || {})
        .map(([id, value]) => (
          {
            id,
            ...value,
          }
        ));
      resultWorkflows.sort(
        (curr, n) => new Date(n.updatedAt).getTime() - new Date(curr.updatedAt).getTime(),
      );
      setWorkflows(resultWorkflows);
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.fetchWorkflows });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchWorkflows();
    closeExistingSDK();
  }, []);

  return (
    <div id="main__container__body__generic">
      <div id="workflow_list" className="workflow_list">
        <div className="workflow_list__heading">
          <div className="workflow_list__heading_left">
            Workflows
            <div className="workflow_list__searchBox">
              <SearchBox onSearch={(val) => setSearchValue(val)} value={searchValue} placeHolder="Search for a workflow" />
            </div>
          </div>
          <div className="workflow_list__heading_right">
            <div className="import_workflow__bar">
              <ImportWorkflow
                currentAppId={currentAppId}
                moduleBuilderMode={moduleBuilderMode}
              />
              <CreateWorkflow
                currentAppId={currentAppId}
                moduleBuilderMode={moduleBuilderMode}
              />
            </div>
          </div>

        </div>
        <WorkflowTable
          workflows={displayedWorkflows}
          isLoading={isLoading}
          onEditIconClick={handleEditWorkflow}
        />
        {
        isEditWorkflow ? (
          <UpdateModal
            updateModalHandler={setIsEditWorkflow}
            currentAppId={currentAppId}
            updateWorkflowId={updateWorkflowId}
            handleWorkflowUpdated={handleWorkflowUpdated}
            initialData={initialData}
            updateWorkflowRowData={updateWorkflowRowData}
            toUpdateInViewWorkflowPage={false}
          />
        ) : null
      }
      </div>
    </div>
  );
}

export default PermissionWrapper(WorkflowList, useGetUserPermissions, getPermission('workflowListPage'));
