import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fetchCurrentValueFromWorkflow, getSelectedModule } from '../InputsToModule/utils/updateWorkflow';
import { selectSelectedNode, selectSelectedWorkflow, selectVersionedModules } from '../../../../reducers/workflow';
import { convertDurationToMillis, convertDurationInNewUnits } from '../../../../utils/helper';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import { workflowOperationsObj as operations } from '../../../../workflowOperations';
import DurationInput from './DurationInput';
import './Duration.scss';

function Duration({ element, isDisabled, infoButton }) {
  const { workflowKey, default: defaultValue, unit } = element;

  const selectedNode = useSelector(selectSelectedNode);
  const {
    id: selectedNodeId,
    nodeType: selectedNodeType,
    version: selectedNodeVersion = 'v1',
  } = selectedNode || {};
  const versionedModules = useSelector(selectVersionedModules);
  const selectedModuleConfig = versionedModules?.[selectedNodeType]?.[selectedNodeVersion]?.config;

  const selectedWorkflow = useSelector(selectSelectedWorkflow);

  const getDurationValue = (workflow, moduleId, key, unitOfStorage) => {
    const selectedModule = getSelectedModule(workflow, moduleId);
    const currentValue = fetchCurrentValueFromWorkflow(
      selectedModule,
      key,
    );
    const isValueDefinedInWorkflow = typeof currentValue === 'number';
    const fallbackValue = typeof defaultValue === 'number' ? defaultValue : 0;
    const value = isValueDefinedInWorkflow ? currentValue : fallbackValue;
    const finalValueInMillis = convertDurationToMillis(value, unitOfStorage);
    return finalValueInMillis;
  };

  const [durationInMillis, setDuration] = useState(
    getDurationValue(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
      unit,
    ),
  );

  const updateValueInWorkflow = (duration) => {
    updateWorkflowInState({}, true, {
      operation: operations.SET_MODULE_PROPERTY,
      actionData: {
        targetNodeId: selectedNodeId,
        workflowKey,
        value: duration,
        moduleConfig: selectedModuleConfig,
      },
    });
  };

  useEffect(() => {
    const currentDurationValue = getDurationValue(
      selectedWorkflow,
      selectedNodeId,
      workflowKey,
      selectedModuleConfig,
      unit,
    );
    setDuration(currentDurationValue);
  }, [selectedNodeId, JSON.stringify(selectedWorkflow)]);

  useEffect(() => {
    const durationInUnits = convertDurationInNewUnits(durationInMillis, unit);
    updateValueInWorkflow(durationInUnits);
  }, [durationInMillis]);

  return (
    <DurationInput
      isDisabled={isDisabled}
      title={element.title}
      currentDurationInMillis={durationInMillis}
      onDurationChange={setDuration}
      unitsInWorkflow={unit}
      infoButton={infoButton}
    />
  );
}

Duration.propTypes = {
  element: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  infoButton: PropTypes.object,
};

Duration.defaultProps = {
  infoButton: null,
};

export default Duration;
