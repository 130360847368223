import PropTypes from 'prop-types';
import RuleGenerator from '../RuleGenerator/RuleGenerator';
import operatorsAllowed from './utils/operatorsAllowed';
import DropDown from '../DropDown';

function ConditionalVariableUnit(props) {
  const {
    conVar,
    rootConditionalVarName,
    onConVarChange,
    dropDownOptions: existingDropdownOptions,
    setIsValidRuleEntered,
    preDefinedValues,
  } = props;

  const {
    rule,
    if_true: ifTrue,
    id,
    parent = '',
  } = conVar;

  const dropDownOptions = existingDropdownOptions.map((val) => {
    if (val.id === 'conditionalVariables') {
      const filteredOptions = (val.items)
        .filter((option) => option.value !== `conditionalVariables.${id}` && option.value !== `conditionalVariables.${parent}`);
      return {
        label: val.label,
        items: filteredOptions,
      };
    }
    return val;
  });

  const onTrueValueChange = (val, currConVar) => {
    const { value = '' } = val || {};
    const trueVal = !value ? "'NONE_NONE'" : value;
    const newChild = {
      ...currConVar,
      if_true: trueVal,
    };
    onConVarChange(newChild);
  };
  const leftSideDropdownElements = dropDownOptions?.filter((item) => item.label !== 'Predefined Values');
  const rightSideDropdownElements = dropDownOptions;

  const onRuleChange = ({ rule: newRule, isRuleValid }, currConVar) => {
    setIsValidRuleEntered(isRuleValid);
    if (isRuleValid) {
      const newChild = {
        ...currConVar,
        rule: newRule,
      };
      onConVarChange(newChild);
    }
  };

  return (
    <>
      <RuleGenerator
        rule={rule}
        onRuleChange={(newRule) => onRuleChange(newRule, conVar)}
        leftSideDomain={leftSideDropdownElements}
        rightSideDomain={rightSideDropdownElements}
        operatorsAllowed={operatorsAllowed}
        preDefinedValues={preDefinedValues}
      />
      <div className="conditional-var_value">
        <span className="is_true"> is True </span>
        <span className="then"> then, </span>
        <span className="conditionalVar__name__value">
          {rootConditionalVarName || 'conditionalVariable'}
        </span>
        <span className="then"> is, </span>
        <div className="conditional-var-value-dropdown">
          <DropDown
            items={{
              items: dropDownOptions,
              label: 'Select a value',
            }}
            allowCustomInput
            noSelectLabel="Select a value"
            onChange={(val) => onTrueValueChange(val, conVar)}
            defaultValue={ifTrue === "'NONE_NONE'" ? null : ifTrue}
            textBoxPlaceholder="value"
            maxLength={30}
          />
        </div>
      </div>
    </>
  );
}

ConditionalVariableUnit.propTypes = {
  conVar: PropTypes.object.isRequired,
  rootConditionalVarName: PropTypes.string,
  onConVarChange: PropTypes.func.isRequired,
  dropDownOptions: PropTypes.array.isRequired,
  setIsValidRuleEntered: PropTypes.func.isRequired,
  preDefinedValues: PropTypes.array,
};

ConditionalVariableUnit.defaultProps = {
  rootConditionalVarName: '',
  preDefinedValues: [],
};

export default ConditionalVariableUnit;
