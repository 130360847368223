import { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import conditionDeleteIcon from '../../assests/icons/conditionDeleteIcon.svg';
import DropDown from './v2/DropDown';

function WorkflowInput({
  sdkInputs,
  setVariableName,
  setType,
  addNewVariable,
  deleteVariable,
}) {
  const [activeTab, setActiveTab] = useState(1);
  const [emptyVariableError, setEmptyVariableError] = useState('');
  const [conflictVariableNameError, setConflictVariableNameError] = useState('');
  const [hasSpaceError, setHasSpaceError] = useState('');

  const getDatatypes = () => [
    {
      label: 'String',
      value: 'string',
      displayLabel: 'String',
    },
    {
      label: 'Image',
      value: 'image',
      displayLabel: 'Image',

    },
    {
      label: 'Boolean',
      value: 'boolean',
      displayLabel: 'Boolean',

    },
  ];

  const handleClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const getErrorMessage = (id) => {
    if (emptyVariableError === id) return 'Variable name cannot be empty!';
    if (hasSpaceError === id) return 'Spaces are not allowed!';
    if (conflictVariableNameError === id) return 'variable already exists!';
    return '';
  };

  const onVariableNameChange = (id, variable) => {
    if (variable === '') {
      setEmptyVariableError(id);
    } else if (variable.includes(' ')) {
      setHasSpaceError(id);
    } else {
      setEmptyVariableError('');
      setHasSpaceError('');
      if (sdkInputs.filter((input) => input.variable === variable).length) {
        setConflictVariableNameError(id);
      } else {
        setConflictVariableNameError('');
      }
    }
    setVariableName(id, variable);
  };

  const onClickDelete = (id) => {
    deleteVariable(id);
  };
  const displayForm = () => (
    <div className="workflowInput__bottomContent_body">
      {
        sdkInputs.map(({ variable, type, id }) => (
          <div key={id} className="workflow_input_field">
            <TextField
              label="variable name"
              variant="outlined"
              value={variable}
              onChange={(event) => onVariableNameChange(id, event.target.value)}
              error={
                emptyVariableError === id
                || conflictVariableNameError === id
                || hasSpaceError === id
              }
              helperText={getErrorMessage(id)}
              size="small"
              className="workflow_input_field_textfield"
            />
            <DropDown
              allowCustomInput={false}
              noSelectLabel="Please select"
              textBoxPlaceholder="Type"
              defaultValue={type || 'string'}
              onChange={(data) => setType(id, data.value)}
              items={{
                label: 'Type',
                items: getDatatypes(),
              }}
              maxLength={25}
            />
            <button type="button" onClick={() => onClickDelete(id)} className="workflow_input_field_deleteButton">
              <img
                src={conditionDeleteIcon}
                alt="delete"
              />
            </button>
          </div>
        ))
      }
      <button type="button" onClick={addNewVariable} className="workflowInput__bottomContent_button">Add Input Variables</button>
    </div>
  );
  return (
    <>
      <div className="workflowInput__topContent">
        <div className="workflowInput__topContent_heading">Workflow Inputs</div>
        <div className="workflowInput__topContent_subheading">If you want to pass data to the workflow, please pass it as inputs here</div>
      </div>
      <div className="workflowInput__bottomContent">
        <div className="options-ribbon__configurations">
          <button
            type="button"
            className={`options-ribbon__configurations__tab ${activeTab === 1 ? 'options-ribbon__active' : ''}`}
            onClickCapture={() => handleClick(1)}
          >
            Configure
          </button>
          <button
            type="button"
            className={`options-ribbon__configurations__tab ${activeTab === 2 ? 'options-ribbon__active' : ''}`}
            onClickCapture={() => handleClick(2)}
          >
            Schema Preview
          </button>
        </div>
        {
            activeTab === 2
              ? <span>Work in progress</span>
              : displayForm()
          }
      </div>
    </>
  );
}

WorkflowInput.propTypes = {
  setVariableName: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  addNewVariable: PropTypes.func.isRequired,
  sdkInputs: PropTypes.object.isRequired,
  deleteVariable: PropTypes.func.isRequired,
  // page: PropTypes.string.isRequired,
};
export default WorkflowInput;
