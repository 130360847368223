import PropTypes from 'prop-types';
import SearchBox from '../../Common/SearchBox';
import '../../../containers/Container.scss';

function Search({
  searchValue,
  handleSearchChange,
  highlightedNodes,
  selectedNodeId,
  selectedNodeName,
  handleNavigateModule,
}) {
  const renderSearchResults = true;
  return (
    <>
      <div className="view_workflow__searchBox">
        <SearchBox
          onSearch={(val) => handleSearchChange(val)}
          value={searchValue}
          placeHolder="Search for module within workflow"
          highlightedNodes={highlightedNodes}
          selectedNodeId={selectedNodeId}
          selectedNodeName={selectedNodeName}
          handleNavigateModule={handleNavigateModule}
          renderSearchResults={renderSearchResults}
        />
      </div>
    </>
  );
}

Search.propTypes = {
  searchValue: PropTypes.string.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  highlightedNodes: PropTypes.array.isRequired,
  selectedNodeId: PropTypes.string,
  selectedNodeName: PropTypes.string,
  handleNavigateModule: PropTypes.func.isRequired,
};

Search.defaultProps = {
  selectedNodeId: '',
  selectedNodeName: '',
};

export default Search;
