import { findFormModulePredefinedVariables } from './findFormModuleOutputs';
import { findPreviousModulesWithNames } from './findPreviousModules';
import getOutputsOfModule from './getModuleOutputs';

export const defaultInputs = ['appId', 'appKey', 'accessToken', 'transactionId'];

export const getConditionalVariables = (workflow) => Object.entries(
  workflow?.conditionalVariables || {},
).filter(([id, conVar]) => conVar.parent === id)
  .map(([id, conVar]) => {
    const name = conVar.name || id;
    return {
      id,
      name,
    };
  });

export const getModuleProperties = (workflow) => {
  const { moduleBuilder } = workflow?.properties || {};
  return moduleBuilder?.builderProperties || null;
};

export const getWorkflowInputVariables = (workflow) => {
  const inputs = Object.keys(workflow?.properties?.inputsRequired || {}).map((inputName) => {
    const name = inputName;
    return {
      id: name,
      name,
    };
  });

  defaultInputs.forEach((input) => {
    inputs.push({ id: input, name: input });
  });
  return inputs;
};

export const getAllModuleOutputs = (workflow, formComponentList, versionedModuleConfigs) => {
  const modules = workflow.modules || [];
  const moduleOutputs = [];
  modules.forEach((module) => {
    const { id, name } = module;
    const outputVariables = getOutputsOfModule(
      id,
      formComponentList,
      workflow?.modules || [],
      versionedModuleConfigs,
    );
    moduleOutputs.push({
      id,
      name,
      variables: outputVariables,
    });
  });
  return moduleOutputs;
};

export const getModuleOutputs = (
  orderOfNodes,
  selectedNodeId,
  workflow,
  formComponentList,
  versionedModuleConfigs,
  includeSelectedModuleOutputs = false,
) => {
  const indexOfCurrentModule = orderOfNodes.findIndex((module) => module.id === selectedNodeId);
  const previousModules = findPreviousModulesWithNames(
    orderOfNodes,
    indexOfCurrentModule,
    workflow,
  );
  const previousModulesIds = previousModules.map(({ id }) => id);

  // Add form module to previousIds if form module is the selected node.
  const isFormModuleSelected =
    workflow.modules.find(({ id }) => id === selectedNodeId)?.type === 'dynamicForm';

  if (!previousModulesIds.includes(selectedNodeId)
    && (isFormModuleSelected || includeSelectedModuleOutputs)) {
    previousModulesIds.push(selectedNodeId);
  }

  const allModuleOutputs = getAllModuleOutputs(workflow, formComponentList, versionedModuleConfigs);
  const moduleOutputs = allModuleOutputs.filter(({ id }) => previousModulesIds.includes(id));
  return moduleOutputs;
};

export const getAllVariableIds = (workflow, formComponentList, versionedModuleConfigs) => {
  const conditionalVariables = getConditionalVariables(workflow).map(
    ({ id }) => `conditionalVariables.${id}`,
  );
  const workflowInputs = getWorkflowInputVariables(workflow).map(({ id }) => `inputs.${id}`);
  const moduleOutputs = getAllModuleOutputs(workflow, formComponentList, versionedModuleConfigs)
    .map(({ id: moduleId, variables }) => (variables || [])
      .map(({ id: variableId }) => `${moduleId}.${variableId}`))
    .flat();
  return {
    conditionalVariables,
    workflowInputs,
    moduleOutputs,
  };
};

export const getPredefinedValues = (workflow, formComponentList) => (
  workflow.modules.reduce((agg, module) => {
    if (module.type === 'dynamicForm') {
      return findFormModulePredefinedVariables(module, formComponentList);
    }
    module?.variables?.forEach((variable) => {
      if (variable.predefinedValues) {
        agg.push({
          moduleId: module.id,
          predefinedValues: variable.predefinedValues,
          moduleName: module.name,
          variableName: variable.name,
        });
      }
    });
    return agg;
  }, []));
