import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import DownloadWorkflow from '../components/ViewWorkflow/v2/DownloadWorkflow';

function ReloadModal(props) {
  const { onReload } = props;
  const { search } = useLocation();
  const workflowId = new URLSearchParams(search).get('id');

  return (
    <div>
      <div className="reload_modal__overlay">
        <div className="reload_modal__content">
          <div className="reload_modal__content__header">
            <div className="reload_modal__content__title">
              Sign in again
            </div>
          </div>
          <div className="reload_modal__content__body">
            <div className="reload_modal__content__info">
              Your previous session has expired as you have logged in to another instance
              of dashboard with different credentials. Please log back into the correct account
              to continue using the workflow builder.
              {
                workflowId && (
                  <div className="reload_modal__content__info_download">
                    You can also download your workflow and continue editing it by
                    importing it after logging in.
                  </div>
                )
              }
            </div>
          </div>
          <div className="reload_modal__content__footer">
            {
              workflowId && (
                <DownloadWorkflow />
              )
            }
            <button type="button" className="reload_modal__content__footer__button" onClick={onReload}>Reload</button>
          </div>
        </div>
      </div>
    </div>
  );
}

ReloadModal.propTypes = {
  onReload: PropTypes.func.isRequired,
};

export default ReloadModal;
