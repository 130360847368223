import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Popper from '@mui/material/Popper';
import generateUniqueID from '../../../utils/generateUniqueId';
import downIcon from '../../../assests/icons/dropDownIcon.svg';
import sideIcon from '../../../assests/icons/sideIcon.svg';
import editIcon from '../../../assests/icons/editIcon2.svg';
import Toggle from './Toggle';
import ValidationDrawer from './ValidationDrawer';
import ConfigureValidationDrawer from './ConfigureValidationDrawer';
import { createNestedMenuData } from '../../ViewWorkflow/v2/NestedDropdown/utils';
import deleteIcon from '../../../assests/icons/Delete.svg';
import ErrorIcon from '../../../assests/icons/Error.svg';
import { onDelete, onDropdownClick, checkForEmptyFields } from '../utils.js';
import { regexValues } from '../../../constants/validations.js';

function SetComponentValidations(props) {
  const {
    validationConfig,
    onChange,
    defaultValue,
    hideRegexTab = false,
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
    preDefinedValues,
  } = props;
  const [toggle, setToggle] = useState(!!(defaultValue && defaultValue.length));
  const prevValue = useRef(defaultValue || '');
  const [validations, setValidations] = useState(defaultValue);
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [openConfigureValidationDrawer, setOpenConfigureValidationDrawer] = useState({});
  const [validationsWithUid, setValidationsWithUid] = useState(defaultValue);

  const isConfigureValidationDrawerOpen = (index) => (
    openConfigureValidationDrawer && openConfigureValidationDrawer[index] !== undefined
      ? openConfigureValidationDrawer[index]
      : false);
  const isDropdownOpen = (index) => openDropdowns[index];

  const onCheckForEmptyFields = (key) => {
    checkForEmptyFields(key, setValidationsWithUid, validationsWithUid);
  };

  const onConfigureValidationDrawerClick = (uid, e) => {
    if (e && isDropdownOpen(uid)) {
      e.stopPropagation();
    }
    const isOpenUid = Object.keys(openConfigureValidationDrawer)
      .find((key) => openConfigureValidationDrawer[key] === true);
    setOpenConfigureValidationDrawer();
    onCheckForEmptyFields(isOpenUid);
    setOpenConfigureValidationDrawer((prevOpenConfigureValidationDrawer) => ({
      ...prevOpenConfigureValidationDrawer,
      [uid]: true,
    }));
  };

  const handleOnDropdownClick = (uid) => {
    onDropdownClick(uid, setOpenDropdowns);
  };
  const addValidation = () => {
    const key = openConfigureValidationDrawer ? Object.keys(openConfigureValidationDrawer)[0] : '';
    setOpenConfigureValidationDrawer();
    if (key !== '') {
      onCheckForEmptyFields(key);
    }
    const uid = generateUniqueID();
    setOpenDropdowns((prevOpenDropdowns) => ({
      ...prevOpenDropdowns,
      [uid]: false,
    }));
    setValidations((currValidations) => [
      ...currValidations,
      {
        type: 'rule', value: '', errorMsg: '',
      },
    ]);
    setValidationsWithUid((currValidations) => [
      ...currValidations,
      {
        uid, type: 'rule', value: '', errorMsg: '',
      },
    ]);
    handleOnDropdownClick(uid);
    onConfigureValidationDrawerClick(uid);
  };
  const handleDelete = (uid) => {
    onDelete(uid, setOpenDropdowns, setValidationsWithUid);
  };

  const handleToggle = (newToggleState) => {
    if (newToggleState && (!validations || validations.length === 0)) {
      addValidation();
    }
    setToggle(newToggleState);
  };

  const findIndex = (uid) => {
    const currValidations = validationsWithUid;
    const updatedValidations = [...currValidations];
    const index = updatedValidations.findIndex((val) => val.uid === uid);
    return index;
  };
  const validationItemRefs = useRef([]);
  const addValidationItemRef = (index, ref) => {
    validationItemRefs.current[index] = ref;
  };
  const handleValidationChange = (value, reference) => {
    setValidationsWithUid((currValidations) => {
      const updatedValidations = [...currValidations];
      const index = updatedValidations.findIndex((val) => val.uid === reference);
      updatedValidations[index] = { ...value, uid: reference };
      return updatedValidations;
    });
  };

  useEffect(() => {
    setOpenDropdowns((prevOpenDropdowns) => {
      const updatedDropdowns = { ...prevOpenDropdowns };
      updatedDropdowns[0] = false;
      return updatedDropdowns;
    });
  }, []);

  useEffect(() => {
    const currValidations = [...validationsWithUid];
    setValidations(() => {
      const updatedValidations = [...currValidations];
      updatedValidations.forEach((validationsTemp) => {
        const {
          type, value, errorMsg, uid,
        } = validationsTemp;
        const updatedValidationItem = { type, value, errorMsg };
        const index = findIndex(uid);
        updatedValidations[index] = { ...updatedValidationItem };
      });
      return updatedValidations;
    });
  }, [validationsWithUid]);

  useEffect(() => {
    setValidationsWithUid((currValidations) => {
      const updatedValidations = currValidations.map((val) => {
        const uid = generateUniqueID();
        const emptyValue = val.value === '' || val.value === 'No value Entered';
        const enableErrorIcon = emptyValue;
        const matchingRegex = regexValues.find((regex) => regex.value === val.value);
        const validationType = matchingRegex ? matchingRegex.validationType : 'custom';

        return {
          ...val,
          uid,
          emptyValue,
          enableErrorIcon,
          value: emptyValue ? 'No value Entered' : val.value,
          errorMsg: val.errorMsg || 'The value you have entered may be incorrect',
          validationType,

        };
      });
      return updatedValidations;
    });
  }, []);

  useEffect(() => {
    if (JSON.stringify(validations) !== JSON.stringify(prevValue.current)) {
      const updatedValidations = validations.map((validation) => {
        if (validation.errorMsg === 'No Message Entered') {
          return {
            ...validation,
            errorMsg: 'The value you have entered may be incorrect',
          };
        }
        return validation;
      });
      onChange(updatedValidations);
    }
    prevValue.current = validations;
  }, [validations]);

  useEffect(() => {
    setValidations(defaultValue);
  }, []);

  const dropDownOptions = createNestedMenuData(
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
  ).items;
  return (
    <div className="edit-settings-div__validation-input">
      <Toggle
        label={validationConfig.name}
        value={toggle}
        onChange={handleToggle}
      />
      {(toggle && validations && validationsWithUid) ? (
        validationsWithUid.map((val) => (
          <>
            <div className="outerContainer">
              <div
                className={`drawer-container${isDropdownOpen(val.uid) ? '__active' : '__inactive'}`}
                id={`${isConfigureValidationDrawerOpen(val.uid) ? 'isActive' : 'notActive'}`}
                key={val.uid}
                ref={(ref) => addValidationItemRef(val.uid, ref)}
              >
                <div className="drawer-container__outer">
                  <div className="drawer-container__toggle1">
                    <button type="button" className={`drawer-container${isDropdownOpen(val.uid) ? '__toggled' : '__toggle'}`} onClick={() => handleOnDropdownClick(val.uid)}>
                      <div className="validation-container">
                        <div className="validation-container__name">
                          <div className="arrowIcon">
                            {isDropdownOpen(val.uid)
                              ? (
                                <img src={downIcon} alt="down" />

                              )
                              : (
                                <img src={sideIcon} alt="side" />
                              )}
                          </div>
                          <div className="errorIcon">
                            {val.enableErrorIcon ? (
                              <img
                                width="16"
                                height="16"
                                src={ErrorIcon}
                                alt="error--v1"
                              />
                            ) : null}
                          </div>
                          <div className="validation-text">
                            Validation
                            {' '}
                            {val.uid}
                          </div>
                        </div>
                        <div className="validation-container__actions">
                          {!isConfigureValidationDrawerOpen(val.uid) && (
                          <button type="button" className="edit_icon" onClick={(e) => onConfigureValidationDrawerClick(val.uid, e)}>
                            <img src={editIcon} alt="edit" />
                          </button>
                          )}
                          <div>
                            <button
                              type="button"
                              className="delete_button"
                              onClick={() => handleDelete(val.uid)}
                            >
                              <img src={deleteIcon} alt="edit" />
                            </button>
                          </div>
                        </div>
                      </div>

                    </button>
                  </div>

                  {isDropdownOpen(val.uid) && (
                    <div className="drawer-container__text">
                      <ValidationDrawer
                        defaultValue={val}
                        val={val}
                      />
                    </div>
                  )}
                </div>
              </div>

              <Popper
                open={isDropdownOpen(val.uid) && isConfigureValidationDrawerOpen(val.uid)}
                anchorEl={validationItemRefs.current[val.uid]}
                placement="left-start"
                modifiers={[
                  {
                    name: 'flip',
                    enabled: false,
                  },
                ]}
              >
                <div className={val.type === 'regex' ? 'popper__regex' : 'popper'}>
                  <ConfigureValidationDrawer
                    defaultValue={val}
                    hideRegexTab={hideRegexTab}
                    onChange={(value) => { handleValidationChange(value, val.uid); }}
                    dropDownOptions={dropDownOptions}
                    preDefinedValues={preDefinedValues}
                    onClose={() => {
                      setOpenConfigureValidationDrawer((prevOpenConfigureValidationDrawer) => ({
                        ...prevOpenConfigureValidationDrawer,
                        [val.uid]: false,
                      }));
                      onCheckForEmptyFields(val.uid);
                    }}
                  />
                </div>
              </Popper>

            </div>

          </>

        ))
      ) : null}
      {toggle && (
        <div>
          <button
            className="edit-settings-div__condition_button"
            type="button"
            onClick={addValidation}
          >
            + add validation
          </button>
        </div>
      )}
    </div>
  );
}

SetComponentValidations.propTypes = {
  validationConfig: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.array.isRequired,
  hideRegexTab: PropTypes.bool,
  workflowInputs: PropTypes.array.isRequired,
  conditionalVariables: PropTypes.array.isRequired,
  moduleOutputs: PropTypes.array.isRequired,
  preDefinedValues: PropTypes.array.isRequired,
};

SetComponentValidations.defaultProps = {
  hideRegexTab: false,
};

export default SetComponentValidations;
