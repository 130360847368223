import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import './ModuleInputsEditPanel.scss';
import { get } from 'lodash';
import closeIcon from '../../assests/icons/CloseIcon.svg';
import ModuleConfigurationGenericEditor from './ModuleConfigurationGenericEditor';
import moduleInputConfigurations from '../../constants/moduleBuilderModuleInputConfig';
import EditConfigurationBase from './EditConfigurationBase';

function ModuleInputsDrawer(props) {
  const {
    moduleIdsDropdown,
    onClose,
    onAddInput,
    currInput,
    configurationDrawerHeading,
    menuOptions,
  } = props;

  const [currInputConfig, setCurrInputConfig] = useState(currInput || null);

  const [inputModuleId, inputModuleKey] = useMemo(() => {
    if (currInputConfig?.workflowKey) {
      const worklfowKeyParts = currInputConfig.workflowKey.split('.');
      const moduleId = worklfowKeyParts[0];
      const moduleKey = worklfowKeyParts.slice(1).join('.');
      return [moduleId, moduleKey];
    }
    return ['', ''];
  }, [currInputConfig]);

  const [editableInputProperties, setEditableInputProperties] = useState(null);
  const [currentInputType, setInputType] = useState(currInput?.uiConfig?.type || null);

  useEffect(() => {
    if (!currentInputType) return;
    const inputConfig = moduleInputConfigurations
      .find((config) => config.type === currentInputType);
    setEditableInputProperties(inputConfig?.properties);
    setCurrInputConfig((prevConfig) => {
      if (prevConfig?.uiConfig?.type !== currentInputType) {
        return {
          ...inputConfig.baseStructure,
          uiConfigSectionName: prevConfig?.uiConfigSectionName,
          configSectionName: prevConfig?.configSectionName,
          workflowKey: prevConfig?.workflowKey,
        };
      }
      return {
        ...inputConfig.baseStructure,
        ...prevConfig,
      };
    });
  }, [currentInputType]);

  const setPropertyValue = (key, location, updatedValue) => {
    setCurrInputConfig((prevConfig) => ({
      ...prevConfig,
      [location]: {
        ...prevConfig[location],
        [key]: updatedValue,
      },
    }));
  };

  const onUiConfigPositionChange = (selectedItem) => {
    setCurrInputConfig((prevConfig) => ({
      ...prevConfig,
      uiConfigSectionName: selectedItem,
    }));
  };

  const getDynamicDropdownItems = (key, inputConfig) => {
    const itemArray = get(inputConfig, key, []);
    const items = (itemArray || []).map((item) => ({
      label: item,
      value: item,
    }));
    return items;
  };

  const updateWorkflowKey = (moduleId, moduleKey) => {
    setCurrInputConfig((prevConfig) => ({
      ...prevConfig,
      workflowKey: `${moduleId}.${moduleKey}`,
    }));
  };

  const canAddInput = (inputConfig) => !inputConfig?.workflowKey
    || !inputConfig?.configSectionName
    || !inputConfig?.uiConfigSectionName
    || !inputConfig?.uiConfig?.type;

  return (
    <div className="module_builder_inputs_drawer__overlay">
      <div className="module_builder_inputs_drawer__body">
        <div className="module_builder_inputs_drawer__headerContainer">
          <div className="module_builder_inputs_drawer__headerContent">
            {configurationDrawerHeading}
          </div>
          <button type="button" className="module_builder_inputs_drawer__headerCloseButton" onClick={onClose}>
            <img
              src={closeIcon}
              alt="close"
            />
          </button>
        </div>

        <div className="module_builder__inputs_container">
          <EditConfigurationBase
            moduleIdsDropdown={moduleIdsDropdown}
            uiConfigSectionName={currInputConfig?.uiConfigSectionName || 'inputs'}
            configSectionName={currInputConfig?.configSectionName || 'properties'}
            onUiConfigPositionChange={onUiConfigPositionChange}
            inputModuleId={inputModuleId}
            inputModuleKey={inputModuleKey}
            updateWorkflowKey={updateWorkflowKey}
            currentInputType={currentInputType}
            setInputType={setInputType}
            moduleInputConfigurations={moduleInputConfigurations}
          />
          <ModuleConfigurationGenericEditor
            properties={editableInputProperties}
            setPropertyValue={setPropertyValue}
            getDynamicDropdownItems={(key) => getDynamicDropdownItems(key, currInputConfig)}
            currInputConfig={currInputConfig}
            menuOptions={menuOptions}
          />
        </div>

        <div className="module_builder_inputs_drawer__bottomContent">
          <div className="module_builder_inputs_drawer__navButton">
            <button type="button" className="back-button" onClick={onClose}>
              Cancel
            </button>
            <button
              type="button"
              className="submit-button"
              onClick={() => onAddInput(currInputConfig)}
              disabled={canAddInput(currInputConfig)}
            >
              Add Input
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}

ModuleInputsDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAddInput: PropTypes.func.isRequired,
  configurationDrawerHeading: PropTypes.string.isRequired,
  moduleIdsDropdown: PropTypes.array.isRequired,
  currInput: PropTypes.object.isRequired,
  menuOptions: PropTypes.object.isRequired,
};

export default ModuleInputsDrawer;
