import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { unset, cloneDeep } from 'lodash';
import AlertCode from '../../../constants/alertCodes';
import ColorPicker from '../../../components/Branding/ColorPicker/ColorPicker';
import EditLogo from '../../../components/Branding/EditLogo';
import useShowErrorAlert from '../../../utils/customHooks';
import {
  selectAccentColorDisabledButtonKeys,
  selectAccentColorHoverButtonKeys,
  selectAccentColorKeys,
  selectCustomUIConfig,
  selectDefaultUIConfig,
  selectPrimaryColorKeys,
  selectPrimaryColorSubsetKeys,
  updateCustomUiConfig,
  selectSdkBackgroundColorKeys,
} from '../../../reducers/editBranding';
import downIcon from '../../../assests/icons/dropDownIcon.svg';
import sideIcon from '../../../assests/icons/sideIcon.svg';
import { updateReloadSdk } from '../../../reducers/dynamicForm';
import { updateWorkflowInState } from '../../../workflowOperations/updateWorkflow';
import useApiHooks from '../../../utils/useApiHooks';
import { workflowOperationsObj } from '../../../workflowOperations';
import { applyColorSchemeToUiConfig } from './utils';

function EditSDKBranding() {
  const showErrorAlert = useShowErrorAlert();
  const dispatch = useDispatch();

  const defaultUiConfig = useSelector(selectDefaultUIConfig);
  const primaryColorKeys = useSelector(selectPrimaryColorKeys);
  const primaryColorSubsetKeys = useSelector(selectPrimaryColorSubsetKeys);
  const accentColorDisabledButtonKeys = useSelector(selectAccentColorDisabledButtonKeys);
  const accentColorHoverButtonKeys = useSelector(selectAccentColorHoverButtonKeys);
  const accentColorKeys = useSelector(selectAccentColorKeys);
  const sdkBackgroundColorKeys = useSelector(selectSdkBackgroundColorKeys);
  const customUiConfig = useSelector(selectCustomUIConfig);
  const imageUrl = customUiConfig.logos?.clientLogo;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const { search } = useLocation();
  const workflowId = new URLSearchParams(search).get('id');

  const uiConfig = Object.keys(customUiConfig).length > 0 ? customUiConfig : defaultUiConfig;

  const [primaryColor, setPrimaryColor] = useState((() => {
    if (uiConfig.colors.primaryColor) return uiConfig.colors.primaryColor;
    const existingColorKey = primaryColorKeys.find((colorKey) => !!uiConfig.colors[colorKey]);
    return uiConfig.colors[existingColorKey];
  })());
  const [accentColor, setAccentColor] = useState((() => {
    const existingColorKey = accentColorKeys.find((colorKey) => !!uiConfig.colors[colorKey]);
    return uiConfig.colors.accentColor || uiConfig.colors[existingColorKey];
  })());

  const [sdkBackgroundColor, setSdkBackgroundColor] = useState((() => {
    const existingColorKey = sdkBackgroundColorKeys.find((colorKey) => !!uiConfig.colors[colorKey]);
    return uiConfig.colors.sdkBackgroundColor || uiConfig.colors[existingColorKey];
  })());

  const updateUiConfigColors = () => {
    try {
      const colorKeys = {
        primaryColorKeys,
        primaryColorSubsetKeys,
        accentColorDisabledButtonKeys,
        accentColorHoverButtonKeys,
        accentColorKeys,
        sdkBackgroundColorKeys,
      };

      const colors = {
        primaryColor,
        accentColor,
        sdkBackgroundColor,
      };

      // Use the refactored function
      const editedUiConfig = applyColorSchemeToUiConfig(colorKeys, colors, uiConfig);
      dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
      updateWorkflowInState({}, true, {
        operation: workflowOperationsObj.SET_WORKFLOW_ATTRIBUTE,
        actionData: {
          path: 'properties.uiConfigSource',
          value: 'custom',
        },
      });
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.error.updateUiConfigs });
    }
  };

  const handleColorChange = (colorType, colorValue) => {
    if (colorType === 'primary') setPrimaryColor(colorValue);
    else if (colorType === 'accent') setAccentColor(colorValue);
    else if (colorType === 'background') setSdkBackgroundColor(colorValue);
  };

  const {
    getLogoUrl, uploadLogo, uploadIcon,
  } = useApiHooks();

  // upload logo to s3 and get the url for ui config
  const updateLogo = async (logoBlob) => {
    if (logoBlob) {
      const uploadResponse = await uploadLogo(workflowId, logoBlob);
      let logoUrl = uploadResponse?.fileUrl;
      if (!logoUrl) {
        logoUrl = await getLogoUrl(workflowId);
      }
      if (!logoUrl) return null;

      const logos = {
        clientLogo: logoUrl,
      };
      const editedUiConfig = {
        ...cloneDeep(uiConfig),
        logos,
      };
      dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
      dispatch(updateReloadSdk());
      return logoUrl;
    }
    const editedUiConfig = {
      ...cloneDeep(uiConfig),
    };
    unset(editedUiConfig, 'logos');
    dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
    dispatch(updateReloadSdk());
    return null;
  };
  const updateIconImage = async (iconBlob, iconType) => {
    if (iconBlob) {
      const uploadResponse = await uploadIcon(workflowId, iconBlob);
      const iconUrl = uploadResponse?.fileUrl;
      if (!iconUrl) return null;

      const iconKey = iconType === 'back' ? 'backButtonIcon' : 'closeButtonIcon';
      const editedUiConfig = {
        ...cloneDeep(uiConfig),
        icons: {
          ...uiConfig.icons,
          [iconKey]: { url: iconUrl },
        },
      };
      dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
      dispatch(updateReloadSdk());
      return iconUrl;
    }
    const iconKey = iconType === 'back' ? 'icons.backButtonIcon.url' : 'icons.closeButtonIcon.url';
    const editedUiConfig = {
      ...cloneDeep(uiConfig),
    };
    unset(editedUiConfig, iconKey);
    dispatch(updateCustomUiConfig({ uiConfig: editedUiConfig }));
    dispatch(updateReloadSdk());
    return null;
  };

  useEffect(() => {
    updateUiConfigColors();
  }, [primaryColor, accentColor, sdkBackgroundColor]);

  return (
    <div className="edit-branding">
      <ColorPicker
        type="primary"
        name="Text Color"
        color={primaryColor}
        onChange={(colorType, colorValue) => {
          handleColorChange(colorType, colorValue);
        }}
      />
      <ColorPicker
        type="accent"
        name="Accent Color"
        color={accentColor}
        onChange={(colorType, colorValue) => {
          handleColorChange(colorType, colorValue);
        }}
      />
      <ColorPicker
        type="background"
        name="Background Color"
        color={sdkBackgroundColor}
        onChange={(colorType, colorValue) => {
          handleColorChange(colorType, colorValue);
        }}
      />
      <EditLogo name="Your logo" onUpload={updateLogo} imageUrl={imageUrl} />
      <div className="edit-properties-div__section">
        <button
          className="edit-properties-div__title"
          onClick={toggleDropdown}
          type="button"
        >
          Close Button and Back Button Customization
          {isDropdownOpen
            ? <img src={downIcon} alt="down" />
            : <img src={sideIcon} alt="side" />}
        </button>
        {/* Dropdown section */}
        {isDropdownOpen && (
          <div className="dropdown-section">
            <EditLogo name="Close Button Image" onUpload={(iconBlob) => updateIconImage(iconBlob, 'close')} imageUrl={uiConfig.icons?.closeButtonIcon?.url} />
            <EditLogo name="Back Button Image" onUpload={(iconBlob) => updateIconImage(iconBlob, 'back')} imageUrl={uiConfig.icons?.backButtonIcon?.url} />
          </div>
        )}
      </div>
    </div>
  );
}

export default EditSDKBranding;
