import PropTypes from 'prop-types';
import { useRef } from 'react';

import ListFormModuleComponentsV2 from './ListFormModuleComponentsV2';
import addImg from '../../assests/icons/addIcon.svg';
import pasteImg from '../../assests/icons/paste.svg';

function ListFormModuleV2(props) {
  const {
    componentLists,
    selectedComponentLocation,
    handleOnClick,
    handleOnDelete,
    handleOnCopy,
    handleOnAdd,
    handleOnDrag,
    handleOnDragOver,
    handleOnPaste,
    draggingComponentId,
    handleOnClone,
  } = props;
  const {
    componentId: selectedComponentId,
    basePath: selectedComponentRootPath,
  } = selectedComponentLocation;
  const draggedIndexRef = useRef(null);
  return (
    // <div className="component-list-div">
    <>
      {componentLists.map(({
        id: basePath,
        title,
        components,
        enableAddButton,
        enableCopyButton,
        enableDeleteButton,
        enablePasteButton,
      }) => (
        <>
          <div className="component-heading">
            <div className="component-list-div__heading">
              {title}
            </div>
            {
                enablePasteButton ? (
                  <button
                    type="button"
                    className="component-add-btn"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOnPaste(basePath);
                    }}
                  >
                    <img src={pasteImg} alt="review" />
                  </button>
                ) : null
            }
            <button
              disabled={!enableAddButton}
              type="button"
              className="component-add-btn"
              onClick={(event) => {
                event.stopPropagation();
                handleOnAdd(null, basePath);
              }}
            >
              <img src={addImg} alt="add" />
            </button>
          </div>
          <ListFormModuleComponentsV2
            formComponents={components}
            handleOnClick={(data) => handleOnClick(data, basePath)}
            handleOnDelete={(data) => handleOnDelete(data, basePath)}
            handleOnCopy={(data) => handleOnCopy(data, basePath)}
            handleOnClone={(data) => handleOnClone(data, basePath)}
            handleOnAdd={(data) => handleOnAdd(data, basePath)}
            handleOnDrag={(pickId, dropId) => handleOnDrag(pickId, dropId, basePath)}
            selectedComponentPath={
                        selectedComponentRootPath === basePath ? selectedComponentId : []
                      }
            enableCopyButton={enableCopyButton}
            enableDeleteButton={enableDeleteButton}
            enableAddButton={enableAddButton}
            draggedIndexRef={draggedIndexRef}
            handleOnDragOver={handleOnDragOver}
            draggingComponentId={draggingComponentId}
          />
        </>
      ))}
    </>
    // </div>
  );
}

export default ListFormModuleV2;

ListFormModuleV2.propTypes = {
  componentLists: PropTypes.array.isRequired,
  selectedComponentLocation: PropTypes.object.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleOnDelete: PropTypes.func.isRequired,
  handleOnCopy: PropTypes.func.isRequired,
  handleOnAdd: PropTypes.func.isRequired,
  handleOnDrag: PropTypes.func.isRequired,
  handleOnDragOver: PropTypes.func.isRequired,
  handleOnPaste: PropTypes.func.isRequired,
  handleOnClone: PropTypes.func.isRequired,
  draggingComponentId: PropTypes.string.isRequired,
};
