import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import NestedDropdownWithInputs from '../../ViewWorkflow/v2/NestedDropdown/NestedDropdown';

function Dropdown(props) {
  const {
    label,
    value,
    onChange,
    items,
    allowCustomInput,
  } = props;

  return (
    <div className="text-input-div">
      <Grid container alignItems="center" rowSpacing={1}>
        <Grid item xs={12}>{label}</Grid>
        <Grid item xs={12}>
          <NestedDropdownWithInputs
            defaultValue={value === '' ? null : value}
            items={{
              items,
            }}
            noSelectLabel="Select Category type"
            onChange={(selectedItem) => onChange(selectedItem.value)}
            allowCustomInput={allowCustomInput}
          />
        </Grid>
      </Grid>
    </div>
  );
}

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  allowCustomInput: PropTypes.bool.isRequired,
};

export default Dropdown;
