import PropTypes from 'prop-types';
import { get } from 'lodash';
import JsonKeyValueEditor from './Common/JsonKeyValueEditor';
import CssBuilder from './Common/CssBuilder';
import JsonEditor from './Common/JsonEditor';

function FormModuleGenericEditorV2(props) {
  const {
    component,
    domId,
    handleComponentPropertyChange,
    propsToRender,
  } = props;

  const handleOnChange = (prop, value) => {
    handleComponentPropertyChange(prop, value);
  };

  const getCurrentValue = (comp, prop) => get(comp, prop);

  return (
    <div
      className="edit-properties-div__property"
    >
      {
      (propsToRender || []).map(({
        label, property, type, rootName,
      }) => {
        switch (type) {
          case 'jsonBuilder':
            return (
              <JsonKeyValueEditor
                key={`${domId}.${property}`}
                label={label}
                onChange={(value) => {
                  handleOnChange(property, value);
                }}
                defaultValue={getCurrentValue(component, property)}
              />
            );
          case 'cssEditor':
            return (
              <CssBuilder
                key={`${domId}.${property}`}
                label={label}
                onChange={(value) => {
                  handleOnChange(property, value);
                }}
                defaultValue={getCurrentValue(component, property) || `#${domId} {}`}
              />
            );
          case 'jsonEditor':
            return (
              <JsonEditor
                label={label}
                key={`${domId}.${property}`}
                onChange={(value) => {
                  handleOnChange(property, value);
                }}
                defaultValue={getCurrentValue(component, property)}
                readOnly={false}
                placeholder="add properties in JSON here"
                rootName={rootName}
              />
            );
          default:
            return null;
        }
      })
    }
    </div>
  );
}

FormModuleGenericEditorV2.propTypes = {
  component: PropTypes.object.isRequired,
  domId: PropTypes.string.isRequired,
  handleComponentPropertyChange: PropTypes.func.isRequired,
  propsToRender: PropTypes.array.isRequired,
};

export default FormModuleGenericEditorV2;
