import PropTypes from 'prop-types';
import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AlertCode from '../../constants/alertCodes';
import { useShowCustomAlert } from '../../utils/customHooks';
import renameEditIcon from '../../assests/icons/updateIcon.svg';
import DownloadWorkflow from '../ViewWorkflow/v2/DownloadWorkflow';

function TableNameCell({
  id, name, onEditIconClick,
}) {
  const showCustomAlert = useShowCustomAlert();
  const isDownloadTriggeredFromTable = true;
  const [isHovered, setIsHovered] = useState(false);
  const handleEditIcon = (e) => {
    e.stopPropagation();
    onEditIconClick(id);
  };

  return (
    <div
      id="workflow_table__body--workflow_name_container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div id="workflow_table__name_and_editIcon_wraper">
        <p id="workflow_table__body--workflow_name">
          {name}
        </p>
        {
          isHovered && (
            <div>
              <button type="submit" onClick={(e) => handleEditIcon(e)} id="workflow_table__renameEditIcon">
                <img src={renameEditIcon} alt="renameEditIcon" />
              </button>
              <DownloadWorkflow
                id={id}
                isDownloadTriggeredFromTable={isDownloadTriggeredFromTable}
              />
            </div>
          )
        }
      </div>
      <div id="workflow_table__body--workflow_id_container">
        <p id="workflow_table__body--workflow_id">
          workflow id:
          {' '}
          {id}
        </p>
        <CopyToClipboard
          text={id}
          onCopy={() => {
            showCustomAlert({
              message: AlertCode.copiedToClipBoard,
            });
          }}
        >
          <p
            id="workflow_table__body--workflow_copy"
            aria-hidden
            onClick={(e) => e.stopPropagation()}
            className={isHovered && 'visible'}
          >
            Copy
          </p>
        </CopyToClipboard>
      </div>
    </div>
  );
}

TableNameCell.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.object.isRequired,
  onEditIconClick: PropTypes.func.isRequired,
};

export default TableNameCell;
