import { useState } from 'react';
import PropTypes from 'prop-types';

import ConfigurationModal from './ConfigurationModal';
import isValidConfig from './validate';

function Main({ config }) {
  const [isOpen, setIsOpen] = useState(false);
  const { texts } = config || {};
  const startButtonText = texts?.startButton || 'Configure';

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {
        isValidConfig(config) ?
          <button type="button" className="workflowInput__bottomContent_button" onClick={toggleModal}>{startButtonText}</button>
          : <p>Opps! The UI config for panel is invalid, Please reach out to PM</p>
      }
      { isOpen && (
      <ConfigurationModal
        config={config}
        toggleModal={toggleModal}
      />
      )}
    </div>
  );
}

Main.propTypes = {
  config: PropTypes.object.isRequired,
};

export default Main;
