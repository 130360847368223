import PropTypes from 'prop-types';
import {
  useEffect, useRef, useMemo, useCallback,
} from 'react';
import Divider from '../../components/ViewWorkflow/Divider';
import { getAccessibility, getDefaultValueFromComponentConfigs } from '../../utils/helper';
import InputComponent from '../../components/ViewWorkflow/v2/InputComponent/InputComponent';

function ModuleConfigurationsEditor(props) {
  const {
    moduleConfig,
    inputs,
    configurations,
    selectedNodeId,
    selectedWorkflow,
    updateModulePropertyInWorkflow,
    versionedModules,
    orderOfModules,
    moduleBuilderTestMode,
  } = props;

  const onChanges = useCallback((updates, moduleId, currModuleConfig) => {
    updates.forEach(({ workflowKey, value }) => {
      updateModulePropertyInWorkflow(workflowKey, value, moduleId, currModuleConfig);
    });
  }, [updateModulePropertyInWorkflow]);

  const prevAccessibilityRef = useRef(getAccessibility(
    selectedWorkflow,
    [...configurations, ...inputs],
    selectedNodeId,
  ));

  const accessibility = useMemo(() => getAccessibility(
    selectedWorkflow,
    [...configurations, ...inputs],
    selectedNodeId,
  ), [
    selectedWorkflow,
    configurations,
    inputs,
    selectedNodeId,
  ]);

  useEffect(() => {
    const prevAccessibilityState = prevAccessibilityRef.current;
    // Unset those properties which aren't visible
    const updates = [];
    Object.keys(accessibility || {}).forEach((key) => {
      const workflowKey = key.split('[*]')[1];
      if (typeof workflowKey === 'string') {
        // No change in the accessibility, so return
        if (
          prevAccessibilityState[key]?.visible === accessibility[key]?.visible &&
          prevAccessibilityState[key]?.enabled === accessibility[key]?.enabled
        ) return;

        // accessibility changed, add or remove from workflow based on the updated accessibility
        if (accessibility[key]?.visible && accessibility[key]?.enabled) {
          // key is both visible and enabled, so add it to workflow
          // Set default value if present
          const allComponents = [...(inputs || []), ...(configurations || [])];
          const defaultValue = getDefaultValueFromComponentConfigs(workflowKey, allComponents);
          if (defaultValue !== null) updates.push({ workflowKey, value: defaultValue });
        } else {
          // key is either not visible or not enabled, so remove from workflow
          updates.push({ workflowKey, value: null });
        }
      }
    });

    onChanges(updates, selectedNodeId, moduleConfig);
    prevAccessibilityRef.current = accessibility;
  }, [accessibility, configurations, inputs, moduleConfig, onChanges, selectedNodeId]);

  return (
    <>
      {inputs?.length ? (
        <>
          <h2 className="configuration-heading">INPUTS</h2>
          <div className="elements">
            {
            inputs.map((element) => {
              if (!accessibility?.[`${selectedNodeId}[*]${element.workflowKey}`]?.visible) return null;
              return (
                <InputComponent
                  key={`${selectedNodeId}_${element.workflowKey}`}
                  element={element}
                  isDisabled={!accessibility?.[`${selectedNodeId}[*]${element.workflowKey}`]?.enabled}
                  updateValueInWorkflow={(workflowKey, value) => {
                    updateModulePropertyInWorkflow(
                      workflowKey,
                      value,
                      selectedNodeId,
                      moduleConfig,
                    );
                  }}
                  selectedWorkflow={selectedWorkflow}
                  selectedNodeId={selectedNodeId}
                  versionedModules={versionedModules}
                  orderOfModules={orderOfModules}
                  moduleBuilderTestMode={moduleBuilderTestMode}
                  infoButton={element.infoButton}
                />
              );
            })
          }
          </div>
        </>
      ) : null}
      <Divider />
      {configurations?.length ? (
        <>
          <div className="configuration-heading">
            Configurations of Module
          </div>
          <div className="elements">
            {(configurations || []).map((element) => {
              if (!accessibility?.[`${selectedNodeId}[*]${element.workflowKey}`]?.visible) return null;
              return (
                <InputComponent
                  key={`${selectedNodeId}_${element.workflowKey}`}
                  element={element}
                  isDisabled={!accessibility?.[`${selectedNodeId}[*]${element.workflowKey}`]?.enabled}
                  updateValueInWorkflow={(workflowKey, value) => {
                    updateModulePropertyInWorkflow(
                      workflowKey,
                      value,
                      selectedNodeId,
                      moduleConfig,
                    );
                  }}
                  selectedWorkflow={selectedWorkflow}
                  selectedNodeId={selectedNodeId}
                  versionedModules={versionedModules}
                  orderOfModules={orderOfModules}
                  moduleBuilderTestMode={moduleBuilderTestMode}
                  infoButton={element.infoButton}
                />
              );
            })}
          </div>
        </>
      ) : null}
    </>
  );
}

ModuleConfigurationsEditor.propTypes = {
  inputs: PropTypes.array.isRequired,
  configurations: PropTypes.array.isRequired,
  versionedModules: PropTypes.object.isRequired,
  orderOfModules: PropTypes.array.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  updateModulePropertyInWorkflow: PropTypes.func.isRequired,
  moduleConfig: PropTypes.object.isRequired,
  moduleBuilderTestMode: PropTypes.bool,
};

ModuleConfigurationsEditor.defaultProps = {
  moduleBuilderTestMode: false,
};

export default ModuleConfigurationsEditor;
