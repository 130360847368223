import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { set } from 'lodash';
import PropTypes from 'prop-types';
import ErrorModal from '../Common/ErrorModal';
import { validateWorkflow } from '../../workflowOperations/updateWorkflow';
import CreateWorkflowButton from './CreateWorkflowButton';
import useShowErrorAlert from '../../utils/customHooks';
import AlertCode from '../../constants/alertCodes';
import useApiHooks from '../../utils/useApiHooks';
import createWorkflowId from './helper';
import FileUpload from './fileUpload';
import { generateWorkflowConfigFromModuleConfigs, generateWorkflowUiConfigFromModuleConfigs } from '../../compilerDecompiler/workflowModuleInverseTransformer';
import PreWorkflowOperations from '../../utils/PreWorkflowOperations';
import { selectVersionedModules } from '../../reducers/workflow';

function ImportWorkflow({ currentAppId, moduleBuilderMode }) {
  const versionedModules = useSelector(selectVersionedModules);

  const [showDrawer, setShowDrawer] = useState(false);
  const [workflowData, setWorkflowData] = useState({});
  const [enableUpload, setEnableUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorData, setErrorData] = useState([]);

  const navigate = useNavigate();
  const showErrorAlert = useShowErrorAlert();

  const {
    publishTextConfig, publishUiConfig, publishWorkflow,
  } = useApiHooks();

  const handleImportButton = () => {
    setShowDrawer((status) => !status);
    setIsLoading(false);
    setWorkflowData({});
  };

  const handleUploadButton = async (data) => {
    if (currentAppId && data?.workflowConfig) {
      setIsLoading(true);
      const workflowId = createWorkflowId();
      const workflow = data.workflowConfig;
      set(workflow, 'properties.builtOnBuilder', true);
      if (!workflow?.properties?.sdkVersions ||
        !Object.keys(workflow?.properties?.sdkVersions).length) {
        set(
          workflow,
          'properties.sdkVersions',
          {
            mobile: {
              maximum: '10.0.0',
              minimum: '0.3.0',
            },
            web: {
              maximum: '10.0.0',
              minimum: '5.0.0',
            },
          },
        );
      }

      const { code, isValid, validationResults } = validateWorkflow(workflow);
      const deletedSteps = code === 'undefinedConditionOrModule' ? validationResults : null;
      const { workflow: finalWorkflow, errorData: finalErrorData } =
      PreWorkflowOperations(workflow, versionedModules, deletedSteps);

      if (!isValid && finalErrorData.length === 0) {
        showErrorAlert({ message: AlertCode.error[code] || 'Something went wrong!' });
        setIsLoading(false);
      } else if (finalErrorData && finalErrorData.length > 0) {
        // Set error data and show modal
        setErrorData(finalErrorData);
        setWorkflowData((prevData) => ({
          ...prevData,
          workflowConfig: finalWorkflow,
        }));
        setShowModal(true);
        setIsLoading(false); // Stop loading since we need to show modal
      } else {
        const { textConfig, uiConfig } = workflowData;
        const promises = [
          publishWorkflow(currentAppId, workflowId, workflow),
        ];
        if (textConfig && Object.keys(textConfig).length > 0) {
          promises.push(publishTextConfig(workflowId, textConfig, 'en'));
        }
        if (uiConfig && Object.keys(uiConfig).length > 0) {
          promises.push(publishUiConfig(workflowId, uiConfig));
        } else if (data.uiConfig && Object.keys(data.uiConfig).length > 0) {
          promises.push(publishUiConfig(workflowId, data.uiConfig));
        }

        const responses = await Promise.all(promises);
        setIsLoading(false);
        const failedPromise = responses.find((response) => !response.isSuccessful);
        if (failedPromise && Object.keys(failedPromise)) {
          showErrorAlert({ error: failedPromise.error, message: failedPromise.message });
        } else if (finalErrorData.length === 0) {
          navigate(`/view?id=${workflowId}`);
          handleImportButton();
        }
      }
    }
    setEnableUpload(true);
  };
  const handleConfirm = () => {
    setShowModal(false);
    handleUploadButton(workflowData);
  };

  const handleImportModule = async (data) => {
    try {
      const { moduleConfig, moduleUiConfig } = data;
      const uiConfig = generateWorkflowUiConfigFromModuleConfigs(moduleConfig);
      const workflow = generateWorkflowConfigFromModuleConfigs(moduleConfig, moduleUiConfig);
      await handleUploadButton({
        workflowConfig: workflow,
        uiConfig,
      });
    } catch (err) {
      showErrorAlert({ message: err?.message || 'Something went wrong!' });
    }
  };
  const handleDataChange = (data, key) => {
    setWorkflowData((prevData) => ({
      ...prevData,
      [key]: data,
    }));
  };

  useEffect(() => {
    if (moduleBuilderMode) {
      setEnableUpload(currentAppId && workflowData?.moduleConfig && workflowData.moduleUiConfig);
    } else {
      setEnableUpload(currentAppId && workflowData?.workflowConfig);
    }
  }, [workflowData, currentAppId, moduleBuilderMode]);

  return (
    <>
      <div className="import_workflow__item">
        <button type="button" onClick={handleImportButton} className="import_workflow__item_button">
          Import Workflow
        </button>
      </div>
      { showDrawer
        && (
          <div className="import_workflow__drawer_background">
            <div className="import_workflow__drawer">
              { moduleBuilderMode ?
                (
                  <>
                    <FileUpload
                      title="Module Config"
                      onChange={(jsonData) => { handleDataChange(jsonData, 'moduleConfig'); }}
                    />
                    <FileUpload
                      title="Module UI Config"
                      onChange={(jsonData) => { handleDataChange(jsonData, 'moduleUiConfig'); }}
                    />
                  </>
                ) :
                (
                  <>
                    <FileUpload
                      title="Workflow"
                      onChange={(jsonData) => { handleDataChange(jsonData, 'workflowConfig'); }}
                    />
                    <FileUpload
                      title="Text Config (en)"
                      onChange={(jsonData) => { handleDataChange(jsonData, 'textConfig'); }}
                    />
                    <FileUpload
                      title="UI Config"
                      onChange={(jsonData) => { handleDataChange(jsonData, 'uiConfig'); }}
                    />
                  </>
                )}
              <div className="import_workflow__item">
                <button type="button" onClick={handleImportButton} className="import_workflow__button">
                  Cancel
                </button>
                { enableUpload
                  ? (
                    <CreateWorkflowButton
                      onButtonClick={moduleBuilderMode ?
                        () => handleImportModule(workflowData) :
                        () => handleUploadButton(workflowData)}
                      normalButtonText="Upload"
                      loadingButtonText="Uploading"
                      isLoading={isLoading}
                      buttonClassName="import_workflow__button__primary"
                    />
                  )
                  : null}
              </div>
            </div>
          </div>
        )}
      <ErrorModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        errorData={errorData}
        onConfirm={handleConfirm}
      />
    </>
  );
}

export default ImportWorkflow;

ImportWorkflow.propTypes = {
  currentAppId: PropTypes.string.isRequired,
  moduleBuilderMode: PropTypes.bool.isRequired,
};
