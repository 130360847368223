import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

import conditionDeleteIcon from '../../../../assests/icons/conditionDeleteIcon.svg';

function ModuleVariable({
  name: defaultName,
  path: defaultPath,
  onNameChange,
  onPathChange,
  onDelete,
  nameTitle,
  pathTitle,
  isKeyNameValid,
  errorText,
}) {
  const handleNameChange = (event) => {
    const { value } = event.target;
    onNameChange(value);
  };

  const handlePathChange = (event) => {
    const { value } = event.target;
    onPathChange(value);
  };

  return (
    <div className="workflow_output_field">
      <div className="workflow_output_field_key">
        <TextField
          label={nameTitle}
          variant="outlined"
          value={defaultName}
          onChange={handleNameChange}
          error={!isKeyNameValid}
          helperText={errorText}
          size="small"
          className="workflow_output_field_textfield"
        />
      </div>
      <div className="workflow_output_field_value">

        <TextField
          label={pathTitle}
          variant="outlined"
          value={defaultPath}
          onChange={handlePathChange}
          error={false}
          helperText=""
          placeholder="response.a.b"
          size="small"
          className="workflow_output_field_textfield"
        />
      </div>
      <div className="workflow_output_field_deleteButtonDiv">

        <button type="button" onClick={onDelete} className="workflow_output_field_deleteButton">
          <img
            src={conditionDeleteIcon}
            alt="delete"
          />
        </button>
      </div>
    </div>
  );
}

ModuleVariable.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  nameTitle: PropTypes.string.isRequired,
  pathTitle: PropTypes.string.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onPathChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isKeyNameValid: PropTypes.bool.isRequired,
  errorText: PropTypes.string.isRequired,
};

export default ModuleVariable;
