const moduleInputConfigurations = [
  {
    type: 'toggle',
    label: 'Toggle',
    baseStructure: {
      config: {
        values: ['yes', 'no'],
        type: 'boolean',
      },
      uiConfig: {
        type: 'toggle',
        values: ['yes', 'no'],
        on: 'yes',
        off: 'no',
      },
    },
    properties: [
      {
        key: 'required',
        label: 'Required',
        type: 'toggle',
        location: 'config',
        default: true,
      },
      {
        key: 'default',
        label: 'Default',
        type: 'dropDown',
        values: [
          {
            label: 'yes',
            value: 'yes',
          },
          {
            label: 'no',
            value: 'no',
          },
        ],
        location: 'uiConfig',
        default: 'yes',
      },
      {
        key: 'text',
        label: 'Text',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'enabled',
        label: 'Configure Enabled Rule',
        type: 'condition',
        location: 'uiConfig',
      },
      {
        key: 'visible',
        label: 'Configure Visibility Rule',
        type: 'condition',
        location: 'uiConfig',
      },
    ],
  },
  {
    type: 'singleSelectDropDown',
    label: 'Single Select Dropdown',
    baseStructure: {
      config: {
        type: 'string',
      },
      uiConfig: {
        type: 'singleSelectDropDown',
      },
    },
    properties: [
      {
        key: 'required',
        label: 'Required',
        type: 'toggle',
        location: 'config',
        default: true,
      },
      {
        key: 'default',
        label: 'Default',
        type: 'dynamicDropdown',
        location: 'uiConfig',
        allowCustomInput: false,
        dynamicItemKey: 'uiConfig.values',
        default: null,
      },
      {
        key: 'title',
        label: 'Title',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'values',
        label: 'Configure dropdown values',
        type: 'JSONArrayInput',
        location: 'uiConfig',
        default: [],
      },
      {
        key: 'text',
        label: 'Configure labels for the dropdown values',
        type: 'JSONArrayInput',
        location: 'uiConfig',
        default: [],
      },
      {
        key: 'enabled',
        label: 'Configure Enabled Rule',
        type: 'condition',
        location: 'uiConfig',
      },
      {
        key: 'visible',
        label: 'Configure Visibility Rule',
        type: 'condition',
        location: 'uiConfig',
      },
    ],
  },
  {
    type: 'textbox',
    label: 'Textbox',
    baseStructure: {
      config: {
        type: 'string',
      },
      uiConfig: {
        type: 'textbox',
      },
    },
    properties: [
      {
        key: 'required',
        label: 'Required',
        type: 'toggle',
        location: 'config',
        default: true,
      },
      {
        key: 'default',
        label: 'Default',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'text',
        label: 'Text',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'regex',
        label: 'Regex',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'placeholder',
        label: 'Placeholder',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'errortext',
        label: 'Error Text',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'enabled',
        label: 'Configure Enabled Rule',
        type: 'condition',
        location: 'uiConfig',
      },
      {
        key: 'visible',
        label: 'Configure Visibility Rule',
        type: 'condition',
        location: 'uiConfig',
      },
    ],
  },
  {
    type: 'inputToModule',
    label: 'Input to Module',
    baseStructure: {
      config: {
        type: 'string',
      },
      uiConfig: {
        type: 'inputToModule',
      },
    },
    properties: [
      {
        key: 'required',
        label: 'Required',
        type: 'toggle',
        location: 'config',
        default: true,
      },
      {
        key: 'default',
        label: 'Default',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'title',
        label: 'Title',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'sourceTitle',
        label: 'Source Title',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'sourceLabel',
        label: 'Source Label',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'variableTitle',
        label: 'Variable Title',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'variableLabel',
        label: 'Variable Label',
        type: 'textbox',
        location: 'uiConfig',
        default: '',
      },
      {
        key: 'enabled',
        label: 'Configure Enabled Rule',
        type: 'condition',
        location: 'uiConfig',
      },
      {
        key: 'visible',
        label: 'Configure Visibility Rule',
        type: 'condition',
        location: 'uiConfig',
      },
    ],
  },
];

export default moduleInputConfigurations;
