import PropTypes from 'prop-types';
import { useState } from 'react';
import plus from '../../assests/icons/Plus.svg';
import ModuleInputsDrawer from './ModuleInputsDrawer';
import generateUniqueID from '../../utils/generateUniqueId';
import ModuleInputRow from './ModuleInputRow';

function ModuleConfigurations(props) {
  const {
    moduleInputs,
    moduleIdsDropdown,
    pageHeading,
    pageDescription,
    addButtonText,
    moduleConfigPosition,
    configurationDrawerHeading,
    onAdd,
    onDelete,
    menuOptions,
  } = props;

  const [showInputsDrawer, setShowInputsDrawer] = useState(false);
  const [selectedInputId, setSelectedInputId] = useState('');

  const createNewInput = () => {
    const newInputId = generateUniqueID();
    setSelectedInputId(newInputId);
    setShowInputsDrawer(true);
  };

  const onSelectModuleInput = (moduleInputId) => {
    setSelectedInputId(moduleInputId);
    setShowInputsDrawer(true);
  };

  const closeConfigDrawer = () => {
    setSelectedInputId('');
    setShowInputsDrawer(false);
  };

  const getDefaultInputConfig = () => ({
    configSectionName: moduleConfigPosition,
    uiConfigSectionName: 'inputs',
  });

  const addInput = (inputConfig) => {
    onAdd(selectedInputId, inputConfig);
    closeConfigDrawer();
  };

  const deleteInput = (inputId) => {
    onDelete(inputId);
  };

  return (
    <>
      <div className="workflowInput__topContent">
        <div className="workflowInput__topContent_heading">
          {pageHeading}
        </div>
        <div className="workflowInput__topContent_subheading">
          {pageDescription}
        </div>
      </div>
      <div className="module_builder__input_button_container">
        {Object.keys(moduleInputs || {}).map((input) => (
          <ModuleInputRow
            id={input}
            name={moduleInputs[input].workflowKey}
            onClick={() => onSelectModuleInput(input)}
            onDelete={() => deleteInput(input)}
            key={input}
          />
        ))}
      </div>
      <div className="workflowOutput_buttonContainer">
        <button type="button" className="workflowOutput_addBtn" onClick={createNewInput}>
          <img alt="plus" src={plus} />
          <div>
            {addButtonText}
          </div>
        </button>
      </div>
      {showInputsDrawer && (
        <ModuleInputsDrawer
          moduleIdsDropdown={moduleIdsDropdown}
          onClose={closeConfigDrawer}
          onAddInput={addInput}
          currInput={moduleInputs[selectedInputId] || getDefaultInputConfig()}
          configurationDrawerHeading={configurationDrawerHeading}
          menuOptions={menuOptions}
        />
      )}
    </>
  );
}

ModuleConfigurations.propTypes = {
  moduleInputs: PropTypes.object.isRequired,
  moduleIdsDropdown: PropTypes.array.isRequired,
  pageHeading: PropTypes.string.isRequired,
  pageDescription: PropTypes.string.isRequired,
  addButtonText: PropTypes.string.isRequired,
  moduleConfigPosition: PropTypes.string.isRequired,
  configurationDrawerHeading: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  menuOptions: PropTypes.object.isRequired,
};

export default ModuleConfigurations;
