import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import PropertiesTooltip from '../../ViewWorkflow/v2/Tooltip/Tooltip';

import useDebounce from '../../../utils/useDebounce';

function DebouncedJsonInput(props) {
  const {
    label,
    onChange,
    formatAsJson,
    defaultValue,
    readOnly,
    placeholder,
    infoButton,
  } = props;
  const getParsedValue = (value, asJson) => (asJson ? JSON.stringify(value || {}) : value);
  const [value, setValue] = useState(getParsedValue(defaultValue, formatAsJson));
  const [isValidJson, setValidJson] = useState(true);
  const prevValue = useRef(getParsedValue(defaultValue, formatAsJson));
  const debouncedValue = useDebounce(value, 500);
  const onValueChange = (e) => {
    const updatedValue = e.target.value;
    setValue(updatedValue);
  };

  useEffect(() => {
    const prevStringifiedValue = JSON.stringify(prevValue.current);
    const newStringifiedValue = JSON.stringify(debouncedValue);
    if (prevStringifiedValue !== newStringifiedValue) {
      let parsedData = debouncedValue;
      if (formatAsJson) {
        try {
          parsedData = JSON.parse(debouncedValue);
          setValidJson(true);
          onChange(parsedData);
          prevValue.current = debouncedValue;
        } catch (err) {
          setValidJson(false);
        }
      } else {
        onChange(parsedData);
        prevValue.current = debouncedValue;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return (
    <Grid container alignItems="center" className="edit-properties-div__text-input-container">
      <Grid item xs={4}>
        <label htmlFor={label} className="edit-properties-div__label">
          {label}
          <PropertiesTooltip infoButton={infoButton} />
        </label>
      </Grid>
      <Grid item xs={8} className="edit-properties-div__json-editor-container">
        <textarea
          className={`edit-properties-div__code-editor${!isValidJson ? '__invalid' : ''}`}
          placeholder={placeholder}
          value={value}
          readOnly={readOnly}
          onChange={onValueChange}
        />
      </Grid>
    </Grid>
  );
}

DebouncedJsonInput.propTypes = {
  label: PropTypes.string.isRequired,
  formatAsJson: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  infoButton: PropTypes.object,
};

DebouncedJsonInput.defaultProps = {
  placeholder: 'Please enter a value',
  infoButton: null,
};

export default DebouncedJsonInput;
