import {
  cloneDeep, set, get, unset,
} from 'lodash';
import generateUniqueID, { generateTimestamp } from '../../utils/generateUniqueId';
import {
  getAllFormComponents,
  getAllFormComponentsObj,
  setComponentsArrayAtRootPath,
} from '../../containers/FormModule/helper';
import { extractBaseNameAndNumber } from '../../utils/updateModuleNamesInWorkflow';
import { eventHandlers as nextStepEventHandlers } from '../constants';
import { formComponentsBasePath } from '../../constants/dynamicFormComponents';

export const getFlattenedComponentList = (components) => {
  if (!components?.length) return [];
  const clonnedComponents = cloneDeep(components || []);
  const allComponents = [];
  clonnedComponents.forEach((component) => {
    if (component?.subComponents?.length) {
      const allSubComponents = getFlattenedComponentList(component.subComponents);
      allComponents.push(...allSubComponents);
    }
    // Don't use else as vertical component is also a component and should be added
    allComponents.push(component);
  });
  return allComponents;
};

export const copyUiConfigurations = (currentUiConfig, moduleId, originalToClonedComponentIdMap) => {
  if (!currentUiConfig[moduleId]) return currentUiConfig;
  const updatedUiConfig = cloneDeep(currentUiConfig);
  const uiConfigurations = updatedUiConfig[moduleId];
  const originalKeys = Object.keys(originalToClonedComponentIdMap);
  originalKeys.forEach((componentId) => {
    if (uiConfigurations[componentId]) {
      const clonnedKey = originalToClonedComponentIdMap[componentId];
      uiConfigurations[clonnedKey] = uiConfigurations[componentId];
    }
  });
  updatedUiConfig[moduleId] = uiConfigurations;
  return updatedUiConfig;
};

const getFlattenedComponentListFromModule = (module) => {
  const allNestedComponents = getAllFormComponents(module);
  const flattentedComponentsList = getFlattenedComponentList(allNestedComponents);
  return flattentedComponentsList;
};

export const getUniqueLabelAndId = (allExistingComponents, defaultComponent, labelKey = null) => {
  const defaultId = defaultComponent?.id;
  const defaultLabel = labelKey ? defaultComponent?.[labelKey] : null;
  const componentType = defaultComponent?.type === 'list' ? defaultComponent?.itemsGenerator?.subComponents?.[0]?.type : defaultComponent?.type;
  let maxIdCount = 0;
  let maxLabelCount = 0;
  const { base: baseComponentId } = extractBaseNameAndNumber(defaultId);
  const baseComponentLabel = defaultLabel ? extractBaseNameAndNumber(defaultLabel)?.base : '';
  allExistingComponents.forEach((existingComponent) => {
    const { base: currentBaseComponentId, no: currentIdCount } = extractBaseNameAndNumber(
      existingComponent?.id,
    );
    if (baseComponentId === currentBaseComponentId) {
      maxIdCount = Math.max(maxIdCount, currentIdCount);
    }
    const existingComponentType = existingComponent?.type === 'list' ? existingComponent?.itemsGenerator?.subComponents?.[0]?.type : existingComponent?.type;
    if (defaultLabel && componentType === existingComponentType) {
      const { base: currentBaseLabel, no: currentLabelCount } = extractBaseNameAndNumber(
        existingComponent?.[labelKey],
      );
      if (currentBaseLabel === baseComponentLabel) {
        maxLabelCount = Math.max(maxLabelCount, currentLabelCount);
      }
    }
  });
  if (!labelKey) return { id: `${defaultId}${maxIdCount + 1}` };
  return {
    id: `${defaultId}${maxIdCount + 1}_${generateUniqueID()}`,
    label: `${defaultLabel}${maxLabelCount + 1}`,
  };
};

export const getDefaultComponent = (
  componentConfig,
  module,
  selectedSubType = null,
) => {
  const {
    primaryBrandingKey: labelKey,
    default: defaultComponent,
    subType,
  } = componentConfig;
  const allExistingComponents = getFlattenedComponentListFromModule(module);

  const defaultSubType = subType ? Object.keys(defaultComponent)[0] : null;

  const selectedDefault = selectedSubType
    ? defaultComponent[selectedSubType]
    : defaultComponent[defaultSubType] || defaultComponent;

  const newEditedComponent = cloneDeep(selectedDefault);

  const { id: newId, label: newLabel } = getUniqueLabelAndId(
    allExistingComponents,
    newEditedComponent,
    labelKey || null,
  );
  if (defaultComponent?.type === 'list') {
    const updatedItemsGenerator = cloneDeep(defaultComponent?.itemsGenerator || {});
    updatedItemsGenerator.id = `${newId}_itemsGenerator`;
    updatedItemsGenerator.subComponents[0].id = `${newId}_chip`;
    updatedItemsGenerator.subComponents[0].selected = `$item.val == ${module.id}.${newId}.value.val`;
    defaultComponent.itemsGenerator = updatedItemsGenerator;
  }
  const newComponent = { ...newEditedComponent, id: newId };
  if (labelKey) newComponent[labelKey] = newLabel;
  return newComponent;
};

export const refreshComponentIds = (component, currentModule, formConfig) => {
  const clonnedComponent = cloneDeep(component);
  const originalToClonedComponentIdMap = {};
  const oldComponenId = clonnedComponent.id;
  const currentType = clonnedComponent.type === 'list' ? clonnedComponent.itemsGenerator?.subComponents?.[0]?.type : clonnedComponent.type;
  const defaultConfig = formConfig.find((comp) => comp.type === currentType);
  const defaultComponent = getDefaultComponent(defaultConfig, currentModule);
  const { id: newComponentId, itemsGenerator = null } = defaultComponent;
  const subComponents = clonnedComponent?.subComponents || [];
  if (subComponents?.length) {
    const updatedComponentsData = subComponents.map((subComponent) => refreshComponentIds(
      subComponent,
      currentModule,
      formConfig,
    ));
    const updatedSubComponents = updatedComponentsData
      .map(({ component: updatedComp }) => updatedComp);
    clonnedComponent.subComponents = updatedSubComponents;
    const updatedMap = updatedComponentsData
      .map(({ originalToClonedComponentIdMap: nestedIdMap }) => nestedIdMap)
      .reduce((acc, curr) => (Object.assign(acc || {}, curr || {})), {});
    Object.assign(originalToClonedComponentIdMap, updatedMap);
  }
  if (itemsGenerator) {
    const oldItemsGeneratorId = clonnedComponent.itemsGenerator?.id;
    const oldChipId = clonnedComponent.itemsGenerator?.subComponents?.[0]?.id;
    const {
      id: itemsGeneratorId,
      subComponents: itemsGeneratorSubComponents,
    } = itemsGenerator;
    const { id: chipId } = itemsGeneratorSubComponents[0];
    clonnedComponent.itemsGenerator.id = itemsGeneratorId;
    clonnedComponent.itemsGenerator.subComponents[0].id = chipId;
    clonnedComponent.itemsGenerator.subComponents[0].selected = `$item.val == ${currentModule.id}.${newComponentId}.value.val`;
    clonnedComponent.itemsGenerator.reload = {
      [newComponentId]: {},
    };
    originalToClonedComponentIdMap[oldItemsGeneratorId] = itemsGeneratorId;
    originalToClonedComponentIdMap[oldChipId] = chipId;
  }
  originalToClonedComponentIdMap[oldComponenId] = newComponentId;
  clonnedComponent.id = newComponentId;
  return { component: clonnedComponent, originalToClonedComponentIdMap };
};

export const terminals = ['decline', 'approve', 'start', 'manualReview', 'goto', 'dismissToParent', 'user_cancelled'];
export const nonModuleNodes = ['condition', ...terminals];

export const findModules = (orderOfNodes) => {
  const nodes = orderOfNodes
    .map((node) => (!nonModuleNodes.includes(node.nodeType) ? node : null))
    .filter((id) => id !== null);

  return nodes;
};

export const findModulesAndConditions = (orderOfNodes) => {
  const nodes = orderOfNodes
    .map((node) => (!terminals.includes(node.nodeType) ? node : null))
    .filter((id) => id !== null);

  return nodes;
};

export const updateEditedWorkflow = (
  value,
  key,
  component,
  selectedWorkflow,
  selectedModuleId,
  selectedComponentType,
  componentIndex,
  subComponentIndex = -1,
) => {
  const editedWorkflow = cloneDeep(selectedWorkflow);

  editedWorkflow.modules.forEach((module, index) => {
    if (module.id === selectedModuleId) {
      const { components } = editedWorkflow.modules[index].properties.sections[0];
      if (subComponentIndex !== -1) {
        components[componentIndex].subComponents[subComponentIndex][key] = value;
      } else {
        components[componentIndex][key] = value;
      }
    }
  });

  return editedWorkflow;
};

const findNextStepHandler = (component, nextStep = null) => {
  const presentNextStepHandler = nextStepEventHandlers.find((nextStepHandler) => {
    const currentNextStep = get(component, `${nextStepHandler}.nextStep`);
    if (nextStep === null && currentNextStep) return true;
    if (nextStep && currentNextStep === nextStep) return true;
    return false;
  });
  return presentNextStepHandler;
};

export const removePropertiesRecursivelyFromComponent = (component, propertiesToRemove = []) => {
  if (!component) return null;

  const clonedComponent = cloneDeep(component);
  propertiesToRemove.forEach((property) => {
    delete clonedComponent[property];
  });

  if (clonedComponent?.subComponents?.length) {
    clonedComponent.subComponents = clonedComponent.subComponents.map(
      (subComponent) => removePropertiesRecursivelyFromComponent(subComponent, propertiesToRemove),
    );
  }

  return clonedComponent;
};

export const findComponentWithNextStep = (module, nextStep = null) => {
  const allComponents = getAllFormComponents(module);
  const flattentedComponentsList = getFlattenedComponentList(allComponents);
  const component = flattentedComponentsList.find((comp) => {
    const presentNextStepHandler = findNextStepHandler(comp, nextStep);
    if (presentNextStepHandler) return true;
    return false;
  });

  if (!component) return null;
  const nextStepHandler = findNextStepHandler(component, nextStep);
  return { component, nextStepHandler };
};

export const findPathArrayForComponentId = (componentId, components = []) => {
  if (!components?.length) return [];
  let pathArray = [];
  components.some((component, index) => {
    if (component?.id === componentId) {
      pathArray = [index];
      return true;
    }
    const subComponents = component?.subComponents || [];
    const subPathArray = findPathArrayForComponentId(componentId, subComponents);
    if (subPathArray?.length) {
      pathArray = [index, ...subPathArray];
      return true;
    }
    return false;
  });
  return pathArray;
};

// TODO: Deprecate this and generalize operateOnFormComponents as it does something similar
export const updateFormPropertyInComponents = (value, key, pathArray, components) => {
  if (!components?.length) return [];
  if (!pathArray?.length) return components;
  const clonnedComponents = cloneDeep(components || []);
  if (pathArray.length === 1) {
    if (clonnedComponents.length > pathArray[0]) set(clonnedComponents[pathArray[0]], key, value);
    return clonnedComponents;
  }
  const [currentIndex, ...newPath] = pathArray;
  const currentSubComponents = clonnedComponents[currentIndex]?.subComponents || [];
  if (currentSubComponents.length === 0) return clonnedComponents;
  const newChildSubComponents = updateFormPropertyInComponents(
    value,
    key,
    newPath,
    currentSubComponents,
  );
  clonnedComponents[currentIndex].subComponents = newChildSubComponents;
  return clonnedComponents;
};

export const getLocationOfTheComponentInForm = (module, componentId) => {
  const componentsObj = getAllFormComponentsObj(module);
  const basePaths = Object.keys(formComponentsBasePath);
  const location = {
    basePath: '',
    pathArray: [],
  };

  // Finding location of component
  basePaths.some((basePath) => {
    const components = componentsObj[basePath];
    const pathArrayForId = findPathArrayForComponentId(componentId, components);
    if (pathArrayForId.length) {
      location.pathArray = pathArrayForId;
      location.basePath = basePath;
      return true;
    }
    return false;
  });
  return location;
};

export const updateFormPropertyViaComponentId = (module, componentId, key, value) => {
  const componentsObj = getAllFormComponentsObj(module);
  const location = getLocationOfTheComponentInForm(module, componentId);

  if (!location?.basePath?.length || !location?.pathArray?.length) return module;

  const componentsToChange = componentsObj[location.basePath];
  const updatedComponents = updateFormPropertyInComponents(
    value,
    key,
    location.pathArray,
    componentsToChange,
  );

  const updatedModule = setComponentsArrayAtRootPath(
    updatedComponents,
    formComponentsBasePath[location.basePath],
    module,
  );
  return updatedModule;
};

export const findAndUpdateTheOnlyNextStepInModule = (module, oldNextStep, newNextStep) => {
  const clonnedModule = cloneDeep(module);
  const nextStepDetails = findComponentWithNextStep(module, oldNextStep);
  if (!nextStepDetails) return clonnedModule;

  const { component, nextStepHandler } = nextStepDetails;
  const updatedModule = updateFormPropertyViaComponentId(
    clonnedModule,
    component?.id,
    `${nextStepHandler}.nextStep`,
    newNextStep,
  );
  return {
    module: updatedModule,
    componentId: component?.id,
    path: `${nextStepHandler}.nextStep`,
  };
};

export const shouldDisplayProperty = (brandingKey, componentSubType) => {
  if (!componentSubType || !brandingKey?.validSubTypes) return true;
  return brandingKey.validSubTypes.includes(componentSubType);
};

export const updateComponentProperties = (value, key, component, formConfig, componentUiConfig) => {
  if (!component) return null;
  const editedComponent = cloneDeep(component);
  const editedComponentUiConfig = cloneDeep(componentUiConfig);
  if (value === null) unset(editedComponent, key);
  else set(editedComponent, key, value);
  if (key === 'subType') {
    const selectedComponentConfig = formConfig.find(
      (field) => field.type === component.type,
    );
    const { brandingKeys = [] } = selectedComponentConfig || {};

    Object.keys(component).forEach((compKey) => {
      const brandingKey = brandingKeys.find((k) => k.type === compKey);
      const isValidProperty = shouldDisplayProperty(brandingKey, component.subType);
      if (!isValidProperty) {
        unset(editedComponent, compKey);
      }
    });
    return { editedComponent, editedComponentUiConfig: {} };
  }
  return { editedComponent, editedComponentUiConfig };
};

export const getImageFileName = () => {
  const uuid = generateUniqueID();
  const timestamp = generateTimestamp();
  return `${uuid}_${timestamp}`;
};
export const onDelete = (uidToDelete, setOpenDropdowns, setValidationsWithUid) => {
  setOpenDropdowns((prevOpenDropdowns) => {
    const updatedDropdowns = { ...prevOpenDropdowns };
    delete updatedDropdowns[uidToDelete];
    return updatedDropdowns;
  });
  setValidationsWithUid(
    (currValidations) => currValidations.filter((val) => val.uid !== uidToDelete),
  );
};
export const onDropdownClick = (uid, setOpenDropdowns) => {
  setOpenDropdowns((prevOpenDropdowns) => ({
    ...prevOpenDropdowns,
    [uid]: !prevOpenDropdowns[uid],
  }));
};

export const checkForEmptyFields = (key, setValidationsWithUid, validationsWithUid) => {
  if (validationsWithUid && validationsWithUid.length > 0) {
    setValidationsWithUid((currValidations) => {
      const index = currValidations.findIndex((val) => val.uid === key);
      if (index !== -1) {
        const updatedValidations = [...currValidations];
        const { value, errorMsg, ...rest } = updatedValidations[index];
        const emptyValue = value === '' || value === 'No value Entered';
        const enableErrorIcon = emptyValue;

        updatedValidations[index] = {
          ...rest,
          value: emptyValue ? 'No value Entered' : value,
          errorMsg: errorMsg || 'The value you have entered may be incorrect',
          emptyValue,
          enableErrorIcon,
          uid: key,
        };

        return updatedValidations;
      }
      return currValidations; // Key not found, return unchanged validations
    });
  }
};
export const findIndex = (uid, validationsWithUid) => {
  const currValidations = validationsWithUid;
  const updatedValidations = [...currValidations];
  const index = updatedValidations.findIndex((val) => val.uid === uid);
  return index;
};
