import PropTypes from 'prop-types';
import { getSelectedModule } from '../../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import Divider from '../../components/ViewWorkflow/Divider';
import SetNodeName from '../../components/ViewWorkflow/v2/SetNodeName/SetNodeName';
import OutputDrawer from '../../components/ViewWorkflow/v2/Outputs/OutputDrawer';
import ConfigurationModal from '../../components/ViewWorkflow/v2/ConfigurationPanel/main';
import TextInput from '../../components/ImportWorkflow/TextInput';
import DropDown from '../../components/ViewWorkflow/v2/DropDown';
import '../../components/Workflow.scss';
import ModuleConfigurationsEditor from './ModuleConfigurationsEditor';

// TODO: Add a validator to check validity of props

function PropertiesTab({
  uiConfig,
  moduleConfig,
  selectedNodeId,
  selectedNodeType,
  selectedWorkflow,
  updateModulePropertyInWorkflow,
  setPreviousStep,
  versionedModules,
  orderOfModules,
}) {
  const isSuperModule = (workflow, moduleId) => {
    const selectedModule = getSelectedModule(workflow, moduleId);
    return selectedModule?.type === 'superModule';
  };

  const {
    inputs,
    configurations,
    outputs = null,
    modalConfig = null,
  } = uiConfig.sections;

  const getCurrentPreviousStep = (workflow, moduleId) => {
    const module = getSelectedModule(workflow, moduleId);
    if (!module) return '';
    if (module.previousStep === undefined || module.previousStep === null) return 'not-present';
    if (module.previousStep === '') return 'hide-back';
    return module.previousStep;
  };

  return (
    <div>
      <div className="border-box">
        <div className="border-box__content">
          <SetNodeName />
          <TextInput
            label="Node Id"
            setDefaultValue
            readOnly
            placeholder={selectedNodeId}
          />
          {selectedNodeType !== 'condition' && selectedNodeType !== 'countries' && !isSuperModule(selectedWorkflow, selectedNodeId) ? (
            <div className="title-and-dropdowns">
              <h2 className="title-and-dropdowns__title">Previous Step</h2>
              <div className="title-and-dropdowns__dropdowns">
                <DropDown
                  allowCustomInput={false}
                  textBoxPlaceholder="value"
                  onChange={({ value }) => {
                    setPreviousStep(selectedNodeId, value);
                  }}
                  defaultValue={getCurrentPreviousStep(selectedWorkflow, selectedNodeId)}
                  items={{
                    label: 'Please Select',
                    items: [
                      {
                        label: 'Default behavior',
                        value: 'not-present',
                        displayLabel: 'Default behavior',
                      },
                      {
                        label: 'Hide back button',
                        value: 'hide-back',
                        displayLabel: 'Hide back button',
                      },
                    ],
                  }}
                />
              </div>
            </div>
          )
            : null}
        </div>
        <ModuleConfigurationsEditor
          key={selectedNodeId}
          moduleConfig={moduleConfig}
          inputs={inputs}
          configurations={configurations}
          selectedNodeId={selectedNodeId}
          selectedWorkflow={selectedWorkflow}
          updateModulePropertyInWorkflow={updateModulePropertyInWorkflow}
          versionedModules={versionedModules}
          orderOfModules={orderOfModules}
          moduleBuilderTestMode={false}
        />
      </div>
      {
        outputs ? (
          <>
            <Divider />
            <OutputDrawer heading="Output from Module" data={outputs} />
          </>
        )
          : null
      }
      {
        modalConfig ? (
          <>
            <Divider />
            <div className="configuration-modal__button">
              <ConfigurationModal
                config={modalConfig}
              />
            </div>
          </>
        ) : null
      }
    </div>
  );
}

PropertiesTab.propTypes = {
  uiConfig: PropTypes.object.isRequired,
  moduleConfig: PropTypes.object.isRequired,
  selectedNodeId: PropTypes.string.isRequired,
  selectedWorkflow: PropTypes.object.isRequired,
  selectedNodeType: PropTypes.string.isRequired,
  updateModulePropertyInWorkflow: PropTypes.func.isRequired,
  setPreviousStep: PropTypes.func.isRequired,
  versionedModules: PropTypes.object.isRequired,
  orderOfModules: PropTypes.array.isRequired,
};

export default PropertiesTab;
