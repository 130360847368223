import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { createNestedMenuData } from '../../ViewWorkflow/v2/NestedDropdown/utils';
import generateUniqueID from '../../../utils/generateUniqueId';
import DropDown from '../../ViewWorkflow/v2/DropDown';
import useDebounce from '../../../utils/useDebounce';

function SetDropDownOptions(props) {
  const {
    label,
    onChange,
    defaultItems,
    defaultLabels,
    workflowInputs,
    conditionalVariables,
    moduleOutputs,
  } = props;

  const getDefaultOptions = (items, labels) => items.map((item) => ({
    key: labels[item],
    value: item,
    id: generateUniqueID(),
  }));
  const prevValue = useRef(getDefaultOptions(defaultItems, defaultLabels));
  const [options, setOptions] = useState(getDefaultOptions(defaultItems, defaultLabels));
  const debouncedOptions = useDebounce(options, 500);

  const handleDeleteClick = (reference) => {
    const local = options.map((option) => ({ ...option }));
    local.splice(reference, 1);
    setOptions(local);
  };

  const handleTextValueOnChange = (value, type, reference) => {
    setOptions((existingOptions) => {
      const local = existingOptions.map((option) => ({ ...option }));
      local[reference][type] = value;
      return local;
    });
  };

  const handleAddOption = () => {
    const local = options.map((option) => ({ ...option }));
    local.push({
      key: '',
      value: '',
      id: generateUniqueID(),
    });
    setOptions(local);
  };

  const ifValueChanged = (currentValue, newValue) => {
    const newWithoutId = newValue.map(({ key, value }) => ({ key, value }));
    const currentWithoutId = currentValue.map(({ key, value }) => ({ key, value }));
    return JSON.stringify(newWithoutId) !== JSON.stringify(currentWithoutId);
  };

  useEffect(() => {
    if (ifValueChanged(prevValue.current, debouncedOptions)) onChange(debouncedOptions);
    prevValue.current = debouncedOptions;
  }, [debouncedOptions]);

  return (
    <div className="edit-settings-div">
      <div className="edit-settings-div__title">
        <label htmlFor={label} className="edit-properties-div__label">{label}</label>
      </div>
      <Grid
        container
        className="edit-settings-div__label"
        alignItems="center"
        columnSpacing={1}
      >
        <Grid item xs={5}>
          <div className="edit-settings-div__subtitle">
            LABEL
          </div>
        </Grid>
        <Grid item xs={5}>
          <div className="edit-settings-div__subtitle">
            VALUE
          </div>
        </Grid>
      </Grid>
      {options && options.map((option, index) => (
        <div key={option.id}>
          <Grid
            container
            className="edit-settings-div__dropdown-options-row"
            alignItems="center"
            columnSpacing={1}
          >
            <Grid item xs={5}>
              <DropDown
                allowCustomInput
                noSelectLabel="Please select"
                textBoxPlaceholder="Enter Label"
                defaultValue={option.key}
                onChange={(data) => {
                  handleTextValueOnChange(data.value, 'key', index);
                }}
                items={createNestedMenuData(
                  workflowInputs,
                  conditionalVariables,
                  moduleOutputs,
                )}
                maxLength={12}
              />
            </Grid>
            <Grid item xs={5}>
              <DropDown
                allowCustomInput
                noSelectLabel="Please select"
                textBoxPlaceholder="Enter value"
                defaultValue={option.value}
                onChange={(data) => {
                  handleTextValueOnChange(data.value, 'value', index);
                }}
                items={createNestedMenuData(
                  workflowInputs,
                  conditionalVariables,
                  moduleOutputs,
                )}
                maxLength={12}
              />
            </Grid>
            <Grid item xs={2}>
              <button
                className="edit-branding-popup__header__save__button"
                type="button"
                onClick={() => handleDeleteClick(index)}
              >
                Delete
              </button>
            </Grid>
          </Grid>
        </div>
      ))}
      <button
        className="edit-settings-div__condition_button"
        type="button"
        onClick={handleAddOption}
      >
        + add option
      </button>
    </div>
  );
}

export default SetDropDownOptions;

SetDropDownOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultItems: PropTypes.array.isRequired,
  defaultLabels: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  workflowInputs: PropTypes.array.isRequired,
  conditionalVariables: PropTypes.array.isRequired,
  moduleOutputs: PropTypes.array.isRequired,
};
