import Joi from 'joi-browser';

const moduleConfigValidation = Joi.object({
  type: Joi.string().valid('superModule'),
  id: Joi.string().valid('<will_be_generated>'),
  subType: Joi.string().required(),
  initialStep: Joi.string().required(),
  // TODO: finalize a fixed structure for the objects inside builder properties
  builderProperties: Joi.object().pattern(
    Joi.string(),
    Joi.object(),
  ).optional(),
  // TODO: finalize a fixed structure for the objects inside properties
  properties: Joi.object().pattern(
    Joi.string().regex(/^.+\[\+\].+$/),
    Joi.object(),
  ).required(),
  library: Joi.object({
    conditionalVariables: Joi.object().pattern(
      Joi.string(),
      Joi.object({
        if_false: Joi.string().required().allow(''),
        if_true: Joi.string().required().allow(''),
        rule: Joi.string().required(),
      }),
    ).required(),
    conditions: Joi.object().pattern(
      Joi.string(),
      Joi.object({
        if_false: Joi.string().required(),
        if_true: Joi.string().required(),
        rule: Joi.string().required(),
        name: Joi.string(),
      }),
    ).required(),
    modules: Joi.array().items(Joi.object({
      superModuleId: Joi.string().valid('').optional(),
      type: Joi.string().required(),
      subType: Joi.string().required(),
      id: Joi.string().required(),
      // empty strings are valid for dynamic forms
      nextStep: Joi.string().required().allow(''),
      properties: Joi.object(),
    }).unknown(true))
      .required(),
    uiFormatting: Joi.object(),
    properties: Joi.object(),
  }).required(),
  variables: Joi.array().items(
    Joi.object({
      name: Joi.string().required(),
      path: Joi.string().required(),
      predefinedValues: Joi.array().items(Joi.object()),
    }),
  ).optional(),
  version: Joi.string().optional(),
}).unknown(true);

export default moduleConfigValidation;
