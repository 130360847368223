import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useShowErrorAlert from './customHooks';
import AlertCode from '../constants/alertCodes';
import { updateUserInfo } from '../reducers/user';
import { persistor } from '../store';
import useApiHooks from './useApiHooks';

const RedirectPage = () => {
  useEffect(() => {
    window.location.replace(`${process.env.REACT_APP_APPLICATION_DASHBOARD_URL}`);
  }, []);
};

function PrivateRoute({ children }) {
  const showErrorAlert = useShowErrorAlert();
  const { fetchUser } = useApiHooks();
  const [isLoading, setIsLoading] = useState(true);
  const [auth, setAuth] = useState(false);
  const permissions = useSelector((state) => state.user.permissions);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const validateAuth = async () => {
    try {
      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/authenticate`,
      });
      setAuth(true);
    } catch (error) {
      setAuth(false);
      showErrorAlert({ error, message: AlertCode.error.login });
    }
  };

  useEffect(() => {
    async function load() {
      setIsLoading(true);
      dispatch({ type: 'RESET' });
      await persistor.purge();
      await validateAuth();
      const { isSuccessful, data: userResponse = null } = await fetchUser();
      if (isSuccessful && userResponse) {
        dispatch(updateUserInfo(userResponse.user));
        sessionStorage.setItem('lastLoggedInUser', userResponse.user.email);
      }
      setIsLoading(false);
    }
    load();
  }, []);

  useEffect(() => {
    validateAuth();
  }, [pathname]);

  if (!auth && !isLoading) return <RedirectPage />;

  // Wait for permissions to be set
  return permissions ? children : null;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
