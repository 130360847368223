import TYPE_TO_SUBTYPE_MAP from '../pages/constants';
import { checkAndUpdateNextStepsInFormModuleV2, checkAndUpdateNextStepsInFormModule } from '../compilerDecompiler/utils/replaceNextStepId';

export const validateAndUpdateModulesSubtypes = (
  workflow,
  versionedModules,
  enableAtDecompilation = false,
) => {
  const subtypeErrorItems = [];
  const updatedModules = workflow.modules.map((module) => {
    const updatedModule = { ...module };
    // Check the conditions for updating the module
    if (enableAtDecompilation) {
      if ((!updatedModule.superModuleType && TYPE_TO_SUBTYPE_MAP[updatedModule.type]
        // In the decompilation stage, we want each and every type
        // to be mapped to the subtype as we have in the constants
        !== updatedModule?.subType && TYPE_TO_SUBTYPE_MAP[updatedModule.type])
        || (updatedModule.superModuleType && !updatedModule.superModuleId
          && !versionedModules[updatedModule.subType]
          && TYPE_TO_SUBTYPE_MAP[updatedModule.type])) {
        // Push the error item to the subtypeErrorItems array
        subtypeErrorItems.push({
          description: `- ${updatedModule.subType}`,
          actionText: `Changing subType to ${TYPE_TO_SUBTYPE_MAP[updatedModule.type].subType}`,
        });
        updatedModule.subType = TYPE_TO_SUBTYPE_MAP[updatedModule.type].subType;
        updatedModule.version = TYPE_TO_SUBTYPE_MAP[updatedModule.type].version;
      }
    } else if ((!updatedModule.superModuleType && !versionedModules[updatedModule.subType]
        && TYPE_TO_SUBTYPE_MAP[updatedModule.type])
        || (updatedModule.superModuleType && !updatedModule.superModuleId
          && !versionedModules[updatedModule.subType]
          && TYPE_TO_SUBTYPE_MAP[updatedModule.type])) {
      // Push the error item to the subtypeErrorItems array
      subtypeErrorItems.push({
        description: `- ${updatedModule.subType}`,
        actionText: `Changing subType to ${TYPE_TO_SUBTYPE_MAP[updatedModule.type].subType}`,
      });
      updatedModule.subType = TYPE_TO_SUBTYPE_MAP[updatedModule.type].subType;
      updatedModule.version = TYPE_TO_SUBTYPE_MAP[updatedModule.type].version;
    }
    return updatedModule;
  });
  const updatedWorkflow = {
    ...workflow,
    modules: updatedModules,
  };
  const errorData = [];
  if (subtypeErrorItems.length > 0) {
    errorData.push({
      message: 'The following module subTypes are not builder-compatible. You can change the subTypes to "genericApiV1" in case of an API, or "genericWebviewV1" in case of a Webview. This will allow you to import the workflow',
      items: subtypeErrorItems,
    });
  }
  return { workflow: updatedWorkflow, errorData };
};

export const changeNextStepToApprove = (module, deletedSteps) => {
  // Create a replacement map where each deleted step maps to 'approve'
  const replacementMap = deletedSteps.reduce((map, step) => ({
    ...map,
    [step]: 'approve',
  }), {});

  if (module.type === 'dynamicForm') {
    const updatedModule = checkAndUpdateNextStepsInFormModule(module, replacementMap);
    return updatedModule;
  } if (module.type === 'dynamicFormV2') {
    const updatedModule = checkAndUpdateNextStepsInFormModuleV2(module, replacementMap);
    return updatedModule;
  } if (module.type !== 'countries' && replacementMap[module.nextStep]) {
    const updatedModule = { ...module, nextStep: 'approve' };
    return updatedModule;
  }

  return module;
};

export const checkDeletedModulesOrConditions = (workflow, deletedSteps) => {
  const errorData = [];
  if (deletedSteps && deletedSteps.length > 0) {
    errorData.push({
      message: 'The following nextSteps are present in the workflow but the module/condition does not exist. You can change the nextStep to approve which will let you import the workflow',
      items: deletedSteps.map((step) => ({
        description: `- ${step}`,
        actionLink: '#',
        actionText: 'Change nextStep to "approve"',
      })),
    });
    const updatedModules = workflow.modules.map(
      (module) => changeNextStepToApprove(module, deletedSteps),
    );

    const updatedWorkflow = {
      ...workflow,
      modules: updatedModules,
    };

    return { workflow: updatedWorkflow, errorData };
  }
  return { workflow, errorData };
};

const PreWorkflowOperations = (
  workflow,
  versionedModules,
  deletedSteps = [],
  enableAtDecompilation = false,
) => {
  const operations = [
    (currentWorkflow) => validateAndUpdateModulesSubtypes(
      currentWorkflow,
      versionedModules,
      enableAtDecompilation,
    ),
    (currentWorkflow) => checkDeletedModulesOrConditions(currentWorkflow, deletedSteps),
  ];

  const { workflow: updatedWorkflow, errorData: finalErrorData } = operations.reduce(
    (acc, operation) => {
      // Run the operation and return the updated workflow and errorData
      const result = operation(acc.workflow, acc.errorData);
      return { workflow: result.workflow, errorData: acc.errorData.concat(result.errorData) };
    },
    { workflow, errorData: [] },
  );

  return { workflow: updatedWorkflow, errorData: finalErrorData };
};

export default PreWorkflowOperations;
