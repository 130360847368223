import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  useRef,
  useState,
} from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

import CopyIcon from '../../assests/icons/contentCopyIcon.svg';
import FormModuleGenericEditorV2 from './FormModuleGenericEditorV2';
import DropDownWithCustomInput from './Common/DropDownWithCustomInput';

function FormModuleEditPropertiesV2(props) {
  const {
    selectedComponentTagName,
    selectedModuleId,
    selectedComponent,
    selectedComponentId,
    onUpdateComponent,
    onTagNameUpdate,
  } = props;

  const [copyAlert, setCopyAlert] = useState(false);
  const timerRef = useRef();

  const setAlert = () => {
    clearTimeout(timerRef.current);
    setCopyAlert(true);
    timerRef.current = setTimeout(() => {
      setCopyAlert(false);
    }, 5000);
  };

  const options = [
    { key: 'p', name: 'Paragraph' },
    { key: 'h1', name: 'Heading 1' },
    { key: 'h2', name: 'Heading 2' },
    { key: 'h3', name: 'Heading 3' },
    { key: 'h4', name: 'Heading 4' },
    { key: 'div', name: 'Section' },
    { key: 'span', name: 'Text' },
  ];

  return (
    <div className="edit-properties-div">
      <div className="edit-properties-div__property">
        <Grid container alignItems="center" className="moduleId">
          <Grid item xs={4}>Module Id</Grid>
          <Grid item xs={8}>
            <div className="edit-properties-div__text-container">
              <input
                type="text"
                className="edit-properties-div__text-input"
                value={selectedModuleId}
                readOnly
              />
              <button
                type="button"
                className="edit-properties-div__text-copy"
              >
                <CopyToClipboard
                  text={selectedModuleId}
                  onCopy={setAlert}
                >
                  <img src={CopyIcon} alt="Copy" />
                </CopyToClipboard>
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="edit-properties-div__property">
        <Grid container alignItems="center" className="moduleId">
          <Grid item xs={4}>Component Id</Grid>
          <Grid item xs={8}>
            <div className="edit-properties-div__text-container">
              <input
                type="text"
                className="edit-properties-div__text-input"
                value={selectedComponentId}
                readOnly
              />
              <button
                type="button"
                className="edit-properties-div__text-copy"
              >
                <CopyToClipboard
                  text={selectedComponentId}
                  onCopy={setAlert}
                >
                  <img src={CopyIcon} alt="Copy" />
                </CopyToClipboard>
              </button>
            </div>
          </Grid>
        </Grid>
        {copyAlert && (
        <div className="edit-properties-div__alert">
          copied!
        </div>
        )}
      </div>
      <div className="edit-properties-div__property">
        <DropDownWithCustomInput
          key={`${selectedModuleId}_${selectedComponentId}`}
          label="Type"
          options={options}
          onChange={(newType) => {
            if (newType && newType?.trim() !== '') {
              onTagNameUpdate(newType);
            }
          }}
          defaultValue={selectedComponentTagName}
          allowOnlyNumbers={false}
        />
      </div>
      <FormModuleGenericEditorV2
        key={`${selectedModuleId}_${selectedComponentId}`}
        component={selectedComponent}
        domId={selectedComponentId}
        handleComponentPropertyChange={onUpdateComponent}
        propsToRender={[
          {
            label: 'Attributes', property: 'attributes', formatAsJson: true, type: 'jsonBuilder',
          },
          {
            label: 'Properties', property: 'properties', formatAsJson: true, type: 'jsonBuilder',
          },
          {
            label: 'Style Variables', property: 'styles', formatAsJson: true, type: 'jsonBuilder',
          },
          {
            label: 'SDK Attributes', property: 'sdkController', formatAsJson: true, type: 'jsonBuilder',
          },
          {
            label: 'CSS', property: 'css', formatAsJson: true, type: 'cssEditor',
          },
          {
            label: 'Events', property: 'events', formatAsJson: true, type: 'jsonEditor',
          },
          {
            label: 'Dynamic Handlers', property: 'dynamicHandlers', formatAsJson: true, type: 'jsonEditor', rootName: 'dynamicHandlers',
          },
        ]}
      />
    </div>
  );
}

export default FormModuleEditPropertiesV2;

FormModuleEditPropertiesV2.propTypes = {
  selectedComponentTagName: PropTypes.string.isRequired,
  selectedModuleId: PropTypes.string.isRequired,
  selectedComponentId: PropTypes.string.isRequired,
  selectedComponent: PropTypes.object.isRequired,
  onUpdateComponent: PropTypes.func.isRequired,
  onTagNameUpdate: PropTypes.func.isRequired,
};
