import { cloneDeep, unset } from 'lodash';
import {
  fetchCurrentValueFromWorkflow,
  getSelectedModule,
  setModulePropertyInWorkflow,
} from '../../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import {
  addNodeFromHtmlString,
  copyComponentConfigs,
  copyNodeFromHtmlString,
  deleteNodeFromHtmlString,
  dragNodeFromHtmlString,
  getComponentConfigsWithRefreshedIds,
  getFormHtmlStringForV2,
  updateTagNameOfNodeInHtmlString,
} from '../../containers/FormModule/helper';

export const addFormComponentInV2 = (workflow, moduleId, componentId) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const { html: updatedHtml } = addNodeFromHtmlString(componentId, htmlContent);
    const editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return { workflow, success: false };
  }
};

export const deleteFormComponentInV2 = (workflow, moduleId, componentId) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const { html: updatedHtml, deletedNodeIds } = deleteNodeFromHtmlString(
      componentId,
      htmlContent,
    );
    const componentConfig = fetchCurrentValueFromWorkflow(module, 'componentConfigs');
    const clonedComponentConfigs = cloneDeep(componentConfig);
    deletedNodeIds.forEach((id) => {
      unset(clonedComponentConfigs, id);
    });
    let editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'componentConfigs',
      clonedComponentConfigs,
      {},
    );
    editedWorkflow = setModulePropertyInWorkflow(
      editedWorkflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return { workflow, success: false };
  }
};

export const addFormComponentViaClipboardInFormV2 = (
  workflow,
  moduleId,
  htmlString,
  componentConfigsFromContextCopy,
) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const { html: updatedHtml, map } = addNodeFromHtmlString(null, htmlContent, htmlString);
    const componentConfig = fetchCurrentValueFromWorkflow(module, 'componentConfigs');
    const componentConfigForAddedNodes = getComponentConfigsWithRefreshedIds(
      componentConfigsFromContextCopy,
      map,
    );

    const updatedComponentConfigs = {
      ...cloneDeep(componentConfig || {}),
      ...(componentConfigForAddedNodes || {}),
    };

    let editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );

    editedWorkflow = setModulePropertyInWorkflow(
      editedWorkflow,
      moduleId,
      'componentConfigs',
      updatedComponentConfigs,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return { workflow, success: false };
  }
};

export const cloneFormComponentInV2 = (workflow, moduleId, componentId) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const { html: updatedHtml, map } = copyNodeFromHtmlString(componentId, htmlContent);

    const componentConfig = fetchCurrentValueFromWorkflow(module, 'componentConfigs');
    const updatedComponentConfig = copyComponentConfigs(componentConfig, map);

    let editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'componentConfigs',
      updatedComponentConfig,
      {},
    );
    editedWorkflow = setModulePropertyInWorkflow(
      editedWorkflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return { workflow, success: false };
  }
};

export const dragComponentInFormV2 = (workflow, moduleId, pickComponentId, dropComponentId) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const updatedHtml = dragNodeFromHtmlString(pickComponentId, dropComponentId, htmlContent);
    const editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return { workflow, success: false };
  }
};

export const updateTagNameOfComponentInFormV2 = (workflow, moduleId, componentId, tagName) => {
  try {
    const module = getSelectedModule(workflow, moduleId);
    const htmlContent = getFormHtmlStringForV2(module);
    const updatedHtml = updateTagNameOfNodeInHtmlString(tagName, componentId, htmlContent);
    const editedWorkflow = setModulePropertyInWorkflow(
      workflow,
      moduleId,
      'content',
      updatedHtml,
      {},
    );
    return { workflow: editedWorkflow, success: true };
  } catch (err) {
    return { workflow, success: false };
  }
};
