/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import PropertiesTooltip from '../../ViewWorkflow/v2/Tooltip/Tooltip';

function Toggle(props) {
  const {
    value, onChange, label, infoButton,
  } = props;

  return (
    <Grid container alignItems="center">
      <Grid item xs={4}>
        <label htmlFor={label} className="edit-properties-div__label">{label}</label>
        <PropertiesTooltip infoButton={infoButton} />
      </Grid>
      <Grid item xs={8} container className="edit-settings-div__toggle">
        <div className="toggle">
          <label htmlFor={label}>
            <input
              type="checkbox"
              checked={value}
              id={label}
              onChange={() => onChange(!value)}
            />
            <span className="slider round" />
          </label>
        </div>
      </Grid>
    </Grid>
  );
}

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  infoButton: PropTypes.object,
};

Toggle.defaultProps = {
  infoButton: null,
};

export default Toggle;
