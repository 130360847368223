/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './MultiSelectDropDown.scss';
import { cloneDeep } from 'lodash';
import { useSelector } from 'react-redux';
import {
  selectSelectedNode, selectSelectedWorkflow, selectVersionedModules,
} from '../../../../reducers/workflow';
import setDefaultSelectedOptions from './utils/setDefaultSelectedOptions';
import ShowMultiSelectOptions from './ShowMultiSelectOptions';
import { updateWorkflowInState } from '../../../../workflowOperations/updateWorkflow';
import { workflowOperationsObj } from '../../../../workflowOperations';
import PropertiesTooltip from '../Tooltip/Tooltip';

function MultiSelectDropdown({ options, isDisabled, infoButton }) {
  const {
    values, label, title, workflowKey,
  } = options;
  const buttonRef = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const selectedNode = useSelector(selectSelectedNode);
  const {
    id: selectedNodeId,
    nodeType: selectedNodeType,
    version: selectedNodeVersion = 'v1',
  } = selectedNode || {};
  const versionedModules = useSelector(selectVersionedModules);
  const selectedModuleConfig = versionedModules?.[selectedNodeType]?.[selectedNodeVersion]?.config;
  const selectedWorkflow = useSelector(selectSelectedWorkflow);
  const [selectedOptions, setSelectedOptions] = useState(
    setDefaultSelectedOptions(selectedWorkflow, selectedNodeId, workflowKey),
  );

  useEffect(() => {
    updateWorkflowInState({}, true, {
      operation: workflowOperationsObj.SET_MODULE_PROPERTY,
      actionData: {
        targetNodeId: selectedNodeId,
        workflowKey,
        value: selectedOptions,
        moduleConfig: selectedModuleConfig,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const shouldSelectCheckBox = (option) => {
    const index = selectedOptions?.findIndex((SelectedOption) => option === SelectedOption);

    if (index !== -1) return true;
    return false;
  };

  const handleClick = () => {
    setShowOptions(!showOptions);
  };

  const handleChange = (optionsSelected) => {
    const index = selectedOptions.findIndex((option) => option === optionsSelected);

    const newOptions = cloneDeep(selectedOptions);
    if (index === -1) {
      newOptions.push(optionsSelected);
    } else newOptions.splice(index, 1);

    if (newOptions.length > 0) {
      setSelectedOptions(newOptions);
    } else setSelectedOptions([]);
  };
  const truncate = (str, n) => ((str.length > n) ? `${str.slice(0, n - 1)}...` : str);
  return (
    <div className="multi-select-dropDown__container">
      <div className="multi-select-dropDown__title">
        {title}
        <PropertiesTooltip infoButton={infoButton} />
      </div>
      <button
        type="button"
        className="multi-select-dropDown"
        onClick={handleClick}
        ref={buttonRef}
        disabled={isDisabled}
      >
        {truncate(selectedOptions?.join(', ') || label, 50)}
      </button>
      {showOptions ? (
        <div>
          <ShowMultiSelectOptions
            values={values}
            handleChange={handleChange}
            shouldSelectCheckBox={shouldSelectCheckBox}
            buttonRef={buttonRef}
          />
          <div className="multi-select__popup__options__modal" onClick={handleClick} />
        </div>
      ) : ''}
    </div>
  );
}

MultiSelectDropdown.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
  isDisabled: PropTypes.bool,
  infoButton: PropTypes.object,
};

MultiSelectDropdown.defaultProps = {
  isDisabled: false,
  infoButton: null,
};

export default MultiSelectDropdown;
