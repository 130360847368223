/* eslint-disable no-alert */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { cloneDeep, get, set } from 'lodash';
import findAllDocumentsInSelectedCountries from '../utils/findAllDocumentsInSelectedCountries';
import {
  findComponentWithNextStep,
  updateFormPropertyViaComponentId,
} from '../components/FormModule/utils';
import { setModuleProperty, setModulePropertyInWorkflow, unsetModulePropertyInWorkflow } from '../components/ViewWorkflow/v2/InputsToModule/utils/updateWorkflow';
import {
  createNewDomId, getSelectedComponent,
} from './FormModule/helper';
import generateUniqueID from '../utils/generateUniqueId';
import {
  logUpdateGoto,
} from '../logger/logHighLevelWorkflowUpdates';
import { endStateKeys as endStates } from '../components/constants';
import { getNextStepForModule } from '../components/utils';
import { getAccessibility } from '../utils/helper';

const getPathArray = (path) => path.split(':').map(Number).filter((n) => !Number.isNaN(n));

export const getParentPathAndBranch = (id, localOrderOrNodes) => {
  const { parentPath, parentBranch } = localOrderOrNodes.find((node) => node.id === id) || {};
  return { parentPath, parentBranch };
};

export const getNodeAndType = (id, selectedWorkflow) => {
  const { conditions, modules } = selectedWorkflow;
  if (conditions[id]) {
    return {
      node: conditions[id],
      type: 'condition',
    };
  }
  const module = modules.find((module) => module.id === id);
  if (module) {
    return {
      node: module,
      type: 'module',
    };
  }
  return null;
};

const getNextNodeTypeForDynamicForm = (module, componentId, nextStepHandler) => {
  const key = `${componentId}.${nextStepHandler}.nextStep`;
  return (module?.next_node_type?.[key] || '');
};

export const getNewNextStepFromNodeToBeDeleted = (node, type) => {
  if (type === 'condition') {
    // TODO: Implement for condition
    if (!endStates.includes(node.if_false) && !endStates.includes(node.if_true)) return null;
    const preservedBranch = endStates.includes(node.if_false) ? 'if_true' : 'if_false';
    return {
      nextStep: node[preservedBranch],
      nextNodeType: node?.next_node_type?.[preservedBranch] || '',
    };
  }
  if (type === 'module') {
    if (node?.type === 'dynamicForm') {
      const { component: componentWithNextStep, nextStepHandler } = findComponentWithNextStep(node);
      const componentId = componentWithNextStep?.id;

      const nextNodeType =
        getNextNodeTypeForDynamicForm(node, componentId, nextStepHandler)
        || '';

      return {
        nextStep: get(componentWithNextStep, `${nextStepHandler}.nextStep`),
        nextNodeType,
      };
    }
    if (node?.type === 'dynamicFormV2') {
      const allNextSteps = getNextStepForModule(node);
      const { nextStep, path } = allNextSteps[0] || {};
      return {
        nextStep,
        nextNodeType: node?.next_node_type?.[path] || '',
      };
    }
    return {
      nextStep: node?.nextStep,
      nextNodeType: node?.next_node_type?.default || '',
    };
  }
  // TODO: Throw warning here ?
  return null;
};

// TODO: Refactor by removing nodes argument?
export const createNewConditionNode = (visualNodeIdOfNewChild, nodes) => {
  // ASSUMPTION: visualNodeId ( graph ) of the endstates is of the form '<end-state>...'
  // else actual id otherwise

  let nextStep = visualNodeIdOfNewChild;
  const next_node_type = {
    if_true: '',
    if_false: '',
  };

  if (visualNodeIdOfNewChild.startsWith('approve')) {
    nextStep = 'approve';
  } else if (visualNodeIdOfNewChild.startsWith('decline')) {
    nextStep = 'decline';
  } else if (visualNodeIdOfNewChild.startsWith('manualReview')) {
    nextStep = 'manualReview';
  } else if (visualNodeIdOfNewChild.startsWith('goto')) {
    const [childNode] = nodes.filter((n) => n.id === visualNodeIdOfNewChild);
    // ASSUMPTION: actualNode is only defined for goto nodes
    nextStep = childNode.actualNode;
    next_node_type.if_true = 'goto';
  } else if (visualNodeIdOfNewChild.startsWith('dismissToParent')) {
    nextStep = 'dismissToParent';
  } else if (visualNodeIdOfNewChild.startsWith('user_cancelled')) {
    nextStep = 'user_cancelled';
  }

  const condition = {
    rule: "'NONE_NONE' == 'NONE_NONE'",
    if_true: nextStep,
    if_false: 'approve',
    next_node_type,
  };

  const uid = generateUniqueID();
  const id = `condition_${uid}`;
  return { node: condition, id };
};

// TODO: Refactor the below code to create modules, default values should be preset
export const getNewNode = (
  node,
  addNodeBetween,
  nodes,
  workflowConfig,
  countryDocMapping,
  defaultFormSections,
) => {
  const { child } = addNodeBetween;

  // generate the id
  const uid = generateUniqueID();
  const {
    type, subType, properties, variables, version = 'v1',
  } = node;

  const id = `module_${uid}_${node.subType}`;
  // add the next step
  let nextStep = child;
  let next_node_type = {};
  if (endStates.some((state) => child.startsWith(state))) {
    nextStep = endStates.find((state) => child.startsWith(state));
  } else if (child.startsWith('goto')) {
    const [childNode] = nodes.filter((n) => n.id === child);
    nextStep = childNode.actualNode;
    if (node.type !== 'dynamicForm' && node.type !== 'dynamicFormV2') next_node_type = { default: 'goto' };
  }

  const createdNode = {
    type,
    subType,
    id,
    nextStep,
    next_node_type,
    version,
  };
  const variablesWithPath =
    variables && Array.isArray(variables) ? variables.filter((variable) => variable.path) : [];
  if (node.type === 'countries') {
    return {
      ...createdNode,
      properties: {
        countriesSupported: ['ind'],
      },
    };
  }
  if (node.type === 'document') {
    // create url
    const url = `{${workflowConfig.modules[0].id}.baseUrl}/${properties.endpoint}`;
    const { apiType } = properties;
    // compute documents for all selected countries
    const selectedCountries = workflowConfig.modules[0].properties.countriesSupported;
    const documentsSupported = findAllDocumentsInSelectedCountries(
      selectedCountries,
      countryDocMapping,
    );

    const newProperties = {
      url,
      apiType,
      documentsSupported,
      // TODO: This won't work if workflow is imported and id of country module is different
      countryId: '{module_countryPicker.countryId}',
    };
    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          // eslint-disable-next-line prefer-destructuring
          newProperties[key] = properties[key].values[0];
        } else if (properties[key].type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });
    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
    };
  }
  if (node.type === 'face') {
    // create url
    const url = `{${workflowConfig.modules[0].id}.baseUrl}${properties.endpoint}`;
    const { apiType } = properties;
    const newProperties = { url, apiType };
    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          // eslint-disable-next-line prefer-destructuring
          newProperties[key] = properties[key].values[0];
        } else if (properties[key].type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });
    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
    };
  }
  if (node.type === 'api') {
    // create url
    // what if multiple country modules are there
    // TODO: Refactor later
    const baseUrl = typeof properties?.baseUrl === 'string' ? properties?.baseUrl : '';
    const endpoint = typeof properties?.endpoint === 'string' ? properties?.endpoint : '';

    let url = `${baseUrl}${endpoint}`;
    url = url.replace('<countries>', workflowConfig.modules[0].id);
    const { apiType, allowedStatusCodes } = properties;
    const newProperties = { url, apiType };
    if (Array.isArray(allowedStatusCodes) && (allowedStatusCodes || []).length > 0) {
      newProperties.allowedStatusCodes = allowedStatusCodes;
    }
    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          // eslint-disable-next-line prefer-destructuring
          newProperties[key] = properties[key].values[0];
        } else if (properties[key].type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });
    if (properties.requestParameters) {
      newProperties.requestParameters = properties.requestParameters.map((param) => ({
        type: param.type,
        name: param.name,
        value: param.value.replace('<countries>', workflowConfig.modules[0].id),
      }));
    }
    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
    };
  }
  if (node.type === 'webview') {
    const { url = '' } = properties;
    const newProperties = { url };

    Object.keys(properties).forEach((key) => {
      if (properties[key].required) {
        if (properties[key].type === 'boolean' || properties[key].type === 'string') {
          const [property] = properties[key].values;
          newProperties[key] = property;
        } else if (properties[key].type === 'array') {
          newProperties[key] = properties[key].values;
        } else {
          newProperties[key] = '';
        }
      }
    });
    return {
      ...createdNode,
      properties: newProperties,
      variables: variablesWithPath,
    };
  }
  if (node.type === 'dynamicForm') {
    const defaultSections = cloneDeep(defaultFormSections);
    const defaultButton = defaultSections[0].components.find((comp) => comp.type === 'button');
    set(defaultButton, 'onClick.nextStep', nextStep);

    (defaultSections[0].components || []).forEach((_, i) => {
      const currentCompId = defaultSections[0].components[i].id;
      defaultSections[0].components[i].id = `${currentCompId}_${generateUniqueID()}`;
    });

    const updatedDefaultBtnId = defaultSections[0].components.find((comp) => comp.type === 'button')?.id;
    if (child.startsWith('goto')) {
      createdNode.next_node_type = {
        [`${updatedDefaultBtnId}.onClick.nextStep`]: 'goto',
      };
    }

    return {
      ...createdNode,
      nextStep: '',
      properties: {
        sections: defaultSections,
      },
    };
  }
  if (node.type === 'dynamicFormV2') {
    const buttonId = createNewDomId();
    const spanId = createNewDomId();
    return {
      ...createdNode,
      nextStep: '',
      properties: {
        styles: '',
        scripts: [],
        content: `<button id="${buttonId}"><span id="${spanId}"></span></button>`,
        stylesheets: [],
        componentConfigs: {
          [buttonId]: {
            attributes: {},
            properties: {
              textContent: 'Default button',
              componentConfigs: {
                [spanId]: {
                  attributes: {
                    textContent: 'text-goes-there',
                  },
                },
              },
            },
            events: {
              click: {
                nextStep,
              },
            },
          },
        },
      },
    };
  }
  if (node.type === 'superModule') {
    return {
      ...createdNode,
      properties: {},
      variables: variablesWithPath,
      builderProperties: {},
    };
  }
  return {
    ...createdNode,
    variables,
  };
};

export const createNewModuleNode = (
  node,
  addNodeBetween,
  nodes,
  workflowConfig,
  countryDocMapping,
  defaultFormSections,
) => {
  const newNode = getNewNode(
    node,
    addNodeBetween,
    nodes,
    workflowConfig,
    countryDocMapping,
    defaultFormSections,
  );
  return { node: newNode, id: newNode?.id };
};

export const addDefaultPropertiesToModule = (moduleId, workflow, node) => {
  let editedWorkflow = cloneDeep(workflow);
  const propertiesToSet = [
    ...(node.sections?.configurations || []),
    ...(node.sections?.inputs || []),
  ];

  try {
    // add all the properties with their default values
    propertiesToSet.forEach((property) => {
      const { workflowKey = '', default: defaultValue, type: inputType } = property;
      let valueToSet = defaultValue;
      if (typeof defaultValue === 'undefined' && inputType === 'duration') valueToSet = 0;
      if (typeof valueToSet !== 'undefined') {
        editedWorkflow = setModulePropertyInWorkflow(
          editedWorkflow,
          moduleId,
          workflowKey,
          valueToSet,
          node,
        );
      }
    });
    // remove the properties that are not visible or enabled
    const accessibility = getAccessibility(editedWorkflow, propertiesToSet, moduleId);
    propertiesToSet.forEach((property) => {
      const { workflowKey = '' } = property;
      const key = `${moduleId}[*]${workflowKey}`;
      if (!accessibility[key]?.visible || !accessibility[key]?.enabled) {
        editedWorkflow = unsetModulePropertyInWorkflow(
          editedWorkflow,
          moduleId,
          workflowKey,
        );
      }
    });
  } catch (e) {
    alert(`Failed to add module. Error in default module properties: ${e.message}`);
  }
  return editedWorkflow;
};

export const updateGotoInWorkflow = (parentNodeId, parentBranch, updatedGotoModule, workflow) => {
  // Check if parent node Id is a condition
  const { node: parentNode, type: parentNodeType } = getNodeAndType(parentNodeId, workflow) || {};
  const isParentForm = parentNode?.type === 'dynamicForm';
  const isParentCondition = parentNodeType === 'condition';

  const editedWorkflow = cloneDeep(workflow);
  let oldPath = null;
  if (isParentCondition) {
    oldPath = editedWorkflow.conditions[parentNodeId][parentBranch];
    editedWorkflow.conditions[parentNodeId] = {
      ...editedWorkflow.conditions[parentNodeId],
      next_node_type: {
        ...editedWorkflow.conditions[parentNodeId].next_node_type,
        [parentBranch]: 'goto',
      },
      [parentBranch]: updatedGotoModule,
    };
  } else if (isParentForm) {
    const parentModuleIndex = workflow.modules.findIndex((module) => module.id === parentNodeId);
    // TODO: Check if we are getting expected parentBranch ?
    const [componentId, ...nextStepPathArray] = parentBranch.split('.');
    const nextStepPath = nextStepPathArray.join('.');
    const updatedModule = updateFormPropertyViaComponentId(parentNode, componentId, nextStepPath, updatedGotoModule);
    updatedModule.next_node_type = {
      ...(updatedModule.next_node_type || {}),
      [parentBranch]: 'goto',
    };
    editedWorkflow.modules[parentModuleIndex] = updatedModule;
  } else if (parentNode?.type === 'dynamicFormV2') {
    const parentModuleIndex = workflow.modules.findIndex((module) => module.id === parentNodeId);
    const module = workflow.modules[parentModuleIndex];
    const [componentId, ...nextStepPathArray] = parentBranch.split('.');
    const nextStepPath = nextStepPathArray.join('.');
    const keyToEdit = `componentConfigs.${componentId}.${nextStepPath}`;
    const updatedProperties = setModuleProperty(keyToEdit, updatedGotoModule, module?.properties);
    const updatedModule = cloneDeep(module);
    updatedModule.properties = updatedProperties;
    updatedModule.next_node_type = {
      ...(updatedModule.next_node_type || {}),
      [parentBranch]: 'goto',
    };
    editedWorkflow.modules[parentModuleIndex] = updatedModule;
  } else {
    const parentModuleIndex = workflow.modules.findIndex((module) => module.id === parentNodeId);
    oldPath = editedWorkflow.modules[parentModuleIndex].nextStep;
    editedWorkflow.modules[parentModuleIndex] = {
      ...editedWorkflow.modules[parentModuleIndex],
      next_node_type: { default: 'goto' },
      nextStep: updatedGotoModule,
    };
  }
  logUpdateGoto({
    id: parentNodeId,
    parentBranch,
    oldGotoStep: oldPath,
    newGotoStep: updatedGotoModule,
  });
  return editedWorkflow;
};

export const convertOldNextNodeTypeKeysToNew = (workflow) => {
  const updatedWorkflow = cloneDeep(workflow);
  // This function transforms array
  updatedWorkflow.modules.forEach((module) => {
    const { type, next_node_type: existingNextNodeType = {} } = module;
    const newNextNodeType = { ...existingNextNodeType };
    if (type === 'dynamicForm' && existingNextNodeType) {
      Object.keys(existingNextNodeType).forEach((key) => {
        const value = existingNextNodeType[key];
        const pathArray = getPathArray(key);
        if (pathArray.length) {
          const { id: componentId } = getSelectedComponent(module, pathArray, 'components');
          newNextNodeType[`${componentId}.onClick.nextStep`] = value;
          delete newNextNodeType[key];
        }
      });
    } else {
      // Will ensure that default is always present
      newNextNodeType.default = existingNextNodeType?.default || '';
    }
    module.next_node_type = newNextNodeType;
  });
  return updatedWorkflow;
};
