import '../../../containers/Container.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import useShowErrorAlert, { useShowCustomAlert } from '../../../utils/customHooks';
import AlertCode from '../../../constants/alertCodes';
import useApiHooks from '../../../utils/useApiHooks';
import compile from '../../../compilerDecompiler/compiler';
import { resetIsEditedWorkflow, selectVersionedModules } from '../../../reducers/workflow';
import { compileUiConfig } from '../../../containers/uiConfigOperations';
import { selectCustomTextConfig, selectCustomUIConfig, selectSelectedLanguage } from '../../../reducers/editBranding';
import { formComponentList } from '../../../constants/dynamicFormComponents';

function PublishWorkflow() {
  const [isUploadingWorkflow, setIsUploadingWorkflow] = useState(false);
  const { search } = useLocation();
  const dispatch = useDispatch();
  const {
    publishWorkflow, publishUiConfig,
    publishTextConfig,
  } = useApiHooks();
  const currentAppId = useSelector((state) => state.user.currentAppId);
  const workflowId = new URLSearchParams(search).get('id');
  const showErrorAlert = useShowErrorAlert();
  const selectedWorkflow = useSelector((state) => state.workflow.selectedWorkflow);
  const versionedModules = useSelector(selectVersionedModules);
  const customUiConfig = useSelector(selectCustomUIConfig);
  const textConfig = useSelector(selectCustomTextConfig);
  const selectedLanguage = useSelector(selectSelectedLanguage);

  const showCustomAlert = useShowCustomAlert();

  const comileWorkflow = (rawWorkflow) => {
    const compiledWorkflow = compile(rawWorkflow, versionedModules, formComponentList);
    return compiledWorkflow;
  };

  const updateWorkflow = async () => {
    try {
      const compiledWorkflow = comileWorkflow(selectedWorkflow);
      setIsUploadingWorkflow(true);
      const publishWorkflowPromise = publishWorkflow(currentAppId, workflowId, compiledWorkflow);
      let publishTextConfigPromise = null;

      // TODO: Check if its safe to check for such conditions here
      if (Object.keys(textConfig).length > 0) {
        publishTextConfigPromise = publishTextConfig(workflowId, textConfig, selectedLanguage || 'en');
      }

      let publishUiConfigPromise = null;
      if (Object.keys(customUiConfig).length > 0) {
        const compiledUiConfig = compileUiConfig(customUiConfig, compiledWorkflow);
        publishUiConfigPromise = publishUiConfig(workflowId, compiledUiConfig);
      }

      const [
        publishWorkflowResponse,
        publishTextConfigResponse,
        publishUiConfigResponse,
      ] = await Promise.all([
        publishWorkflowPromise,
        publishTextConfigPromise,
        publishUiConfigPromise,
      ]);

      setIsUploadingWorkflow(false);

      if (publishWorkflowResponse && !publishWorkflowResponse?.isSuccessful) {
        showErrorAlert({
          error: publishWorkflowResponse.error,
          message: AlertCode.error.errorUploadingWorkflow,
        });
      } else if (publishTextConfigResponse && !publishTextConfigResponse?.isSuccessful) {
        showErrorAlert({
          error: publishTextConfigResponse.error,
          message: AlertCode.error.errorUploadingTextConfig,
        });
      } else if (publishUiConfigResponse && !publishUiConfigResponse?.isSuccessful) {
        showErrorAlert({
          error: publishUiConfigResponse.error,
          message: AlertCode.error.errorUploadingUiConfig,
        });
      } else {
        dispatch(resetIsEditedWorkflow());
        showCustomAlert({
          message: workflowId,
          alertSeverity: 'success',
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert('Failed to save!');
    }
  };

  return (
    <Button variant="outlined" id="view_workflow__publish_button" onClick={updateWorkflow} disabled={isUploadingWorkflow}>
      {isUploadingWorkflow ? (
        <>
          <CircularProgress size="1rem" sx={{ color: '#ffffff' }} />
          Saving
        </>
      ) : 'Save'}
    </Button>
  );
}

export default PublishWorkflow;
