/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
// import updateGotoTagsInWorkflow from '../utils/updateGotoTags';
// import updateModuleNamesInWorkflow from '../utils/updateModuleNamesInWorkflow';

export const workflowSlice = createSlice({
  name: 'workflow',
  initialState: {
    globalConfig: [],
    countryDocMapping: {},
    countryIdNameMapping: {},
    versionedModules: {},
    selectedWorkflow: {},
    originalSelectedWorkflow: {},
    previousSelectedWorkflow: {},
    orderOfNodes: [],
    selectedNode: {},
    isEdited: false,
    currentModuleUIConfig: {},
    selectedDocuments: [],
    selectedCountries: [],
    documentOverrideOptions: {
      front: [
        {
          value: 'Allow front Capture',
          action: 'capture',
        },
        {
          value: 'Disable OCR',
          action: 'disableOCR',
        },
        {
          value: 'Read barcode',
          action: 'readBarCode',
        },
      ],
      back: [
        {
          value: 'Allow back Capture',
          action: 'capture',
        },
        {
          value: 'Disable OCR',
          action: 'disableOCR',
        },
        {
          value: 'Read barcode',
          action: 'readBarCode',
        },
      ],
    },
  },
  reducers: {
    updateGlobalConfig: (state, action) => {
      const { globalConfig, countryDocMapping, countryIdNameMapping } = action.payload;
      state.globalConfig = globalConfig;
      state.countryDocMapping = countryDocMapping;
      state.countryIdNameMapping = countryIdNameMapping;
    },
    updateVersionedModules: (state, action) => {
      const { modules } = action.payload;
      Object.assign(state.versionedModules, modules);
    },
    updateSelectedNode: (state, action) => {
      const { selectedNode } = action.payload;
      state.selectedNode = selectedNode;
    },
    updateSelectedWorkflow: (state, action) => {
      const { workflow, isEdited } = action.payload;
      state.previousSelectedWorkflow = state.selectedWorkflow;
      state.selectedWorkflow = workflow;
      state.isEdited = isEdited || state.isEdited;
    },
    resetSelectedWorkflow: (state) => {
      state.selectedWorkflow = {};
    },
    undoSelectedWorkflow: (state) => {
      state.selectedWorkflow = state.previousSelectedWorkflow;
    },
    updateOriginalSelectedWorkflow: (state, action) => {
      const { workflow } = action.payload;
      state.originalSelectedWorkflow = workflow;
    },
    updateOrderOfNodes: (state, action) => {
      const { nodes } = action.payload;
      state.orderOfNodes = nodes;
    },
    setCurrentModuleUIConfig: (state, action) => {
      const { uiConfig } = action.payload;
      state.currentModuleUIConfig = uiConfig;
    },
    updateSelectedDocuments: (state, action) => {
      const { documents } = action.payload;
      state.selectedDocuments = documents;
    },
    updateSelectedCountries: (state, action) => {
      const { countries } = action.payload;
      state.selectedCountries = countries;
    },
    resetIsEditedWorkflow: (state) => {
      state.isEdited = false;
    },
  },
});

export const selectCountryDocMapping = (state) => state.workflow.countryDocMapping;
export const selectGlobalConfig = (state) => state.workflow.globalConfig;
export const selectCountryIdNameMapping = (state) => state.workflow.countryIdNameMapping;
export const selectSelectedWorkflow = (state) => state.workflow.selectedWorkflow;
export const selectOriginalSelectedWorkflow = (state) => state.workflow.originalSelectedWorkflow;
export const selectPreviousSelectedWorkflow = (state) => state.workflow.previousSelectedWorkflow;
export const selectOrderOfNodes = (state) => state.workflow.orderOfNodes;
export const selectSelectedNode = (state) => state.workflow.selectedNode;
export const selectCurrentModuleUIConfig = (state) => state.workflow.currentModuleUIConfig;
export const selectSelectedDocuments = (state) => state.workflow.selectedDocuments;
export const selectSelectedCountries = (state) => state.workflow.selectedCountries;
export const selectIsWorkflowEdited = (state) => state.workflow.isEdited;
export const selectVersionedModules = (state) => state.workflow.versionedModules;

// Action creators are generated for each case reducer function
export const {
  updateGlobalConfig, updateSelectedWorkflow,
  updateOriginalSelectedWorkflow, updateOrderOfNodes,
  updateSelectedNode, setCurrentModuleUIConfig, updateSelectedDocuments,
  updateSelectedCountries, resetIsEditedWorkflow, undoSelectedWorkflow,
  resetSelectedWorkflow, updateVersionedModules,
} = workflowSlice.actions;

export default workflowSlice.reducer;
