import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import conditionDeleteIcon from '../../assests/icons/conditionDeleteIcon.svg';
import DropDown from './v2/DropDown';

function WorkflowOutputVariable({
  id,
  variable,
  value,
  setVariableName,
  setOutputValue,
  deleteVariable,
  sdkResponse,
  menuOptions,
}) {
  const [variableError, setVariableError] = useState('');

  const onVariableNameChange = (newVariable) => {
    if (newVariable === '') {
      setVariableError('Variable name cannot be empty!');
    } else if (newVariable.includes(' ')) {
      setVariableError('Spaces are not allowed!');
    } else if (sdkResponse.find((output) => output.variable === newVariable)) {
      setVariableError('Variable already exists!');
    } else {
      setVariableError('');
    }
    setVariableName(id, newVariable);
  };

  return (
    <div key={id} className="workflow_output_field">
      <TextField
        label="variable name"
        variant="outlined"
        value={variable}
        error={variableError !== ''}
        onChange={(event) => onVariableNameChange(event.target.value)}
        helperText={variableError}
        size="small"
        className="workflow_output_field_textfield"
      />
      <div className="workflow_output_field__menu">

        <DropDown
          allowCustomInput
          textBoxPlaceholder="value"
          defaultValue={value}
          onChange={(outputValue) => setOutputValue(id, outputValue.value)}
          items={menuOptions}
          noSelectLabel="Select a value"
          maxLength={45}
        />
      </div>
      <button type="button" onClick={() => deleteVariable(id)} className="workflow_output_field_deleteButton">
        <img
          src={conditionDeleteIcon}
          alt="delete"
        />
      </button>
    </div>
  );
}

WorkflowOutputVariable.propTypes = {
  id: PropTypes.string.isRequired,
  variable: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  setVariableName: PropTypes.func.isRequired,
  setOutputValue: PropTypes.func.isRequired,
  deleteVariable: PropTypes.func.isRequired,
  sdkResponse: PropTypes.array.isRequired,
  menuOptions: PropTypes.object.isRequired,
};

export default WorkflowOutputVariable;
