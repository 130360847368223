import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';
import useApiHooks from '../../../utils/useApiHooks';
import { getImageFileName } from '../utils';

function UploadImage({
  defaultValue, onChange, label, selectedWorkflowId,
}) {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const { uploadImageFile } = useApiHooks();

  const inputRef = useRef();

  const handleFileUpload = async (file) => {
    setIsUploading(true);
    const fileName = getImageFileName();
    const res = await uploadImageFile(selectedWorkflowId, file, fileName);
    setIsUploading(false);
    setUploadedFile(res.fileUrl);
    onChange(res.fileUrl);
  };

  const handleUploadButtonClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    if (defaultValue) {
      setUploadedFile(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className="edit-properties-div__text-input-container">
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={4}>
          <label htmlFor="upload" className="edit-properties-div__label">
            {label}
          </label>
        </Grid>
        <Grid container item xs={8} gap={1} alignItems="center">
          <Grid item xs={7}>
            <input className="edit-properties-div__text-input" id="upload" type="text" value={uploadedFile || ''} readOnly />
          </Grid>
          <Grid item xs={4} className="edit-properties-div__upload_container">
            <input
              type="file"
              accept="image/jpeg, image/png, image/jpg"
              onChange={(event) => {
                const selectedFile = event.target.files[0];
                if (selectedFile) {
                  handleFileUpload(selectedFile);
                }
              }}
              className="edit-properties-div__upload_input"
              ref={inputRef}
              data-testid="imageUploadInput"
            />
            <button
              type="button"
              className="edit-properties-div__upload"
              onClick={handleUploadButtonClick}
              disabled={isUploading}
            >
              {isUploading ? (
                <>
                  <CircularProgress size="0.5rem" />
                  Loading
                </>
              ) : 'Upload'}
            </button>
          </Grid>
        </Grid>

      </Grid>
    </div>
  );
}

UploadImage.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  selectedWorkflowId: PropTypes.string.isRequired,
};

export default UploadImage;
